<template>
  <modal
    width="55%"
    height="auto"
    :classes="responsive ? 'v--modal mobile' : 'v--modal'"
    :name="modalName"
    @opened="afterOpen"
    @before-open="beforeOpen"
    @before-close="beforeClose"
    @closed="closed"
  >
    <button class="absolute top-0 right-0 my-6 mx-8" @click="$modal.hide(modalName)">
        <font-awesome-icon class="text-2xl text-blue-light" icon="times"></font-awesome-icon>
    </button>
    <div class="text-blue-dark h-full p-8">
      <p class="text-center text-2xl md:text-4xl font-bold border-b mb-6">
        Resources Share Feature
      </p>
      <div v-if="instructions" class="flex flex-wrap overflow-auto">
        <div class="w-full md:w-1/2 md:px-6 mb-6">
          <div
            class="mb-6"
            v-if="instructions[0].heading || instructions[0].body"
          >
            <p
              v-if="instructions[0].heading"
              class="text-orange-dark text-xl font-bold mb-1"
            >
              {{ instructions[0].heading }}
            </p>
            <div
              v-if="instructions[0].body"
              class="text-gray-muted"
              v-html="instructions[0].body"
            ></div>
          </div>
          <div v-if="instructions[1].heading || instructions[1].body">
            <p
              v-if="instructions[1].heading"
              class="text-orange-dark text-xl font-bold mb-1"
            >
              {{ instructions[1].heading }}
            </p>
            <div
              v-if="instructions[1].body"
              class="text-gray-muted"
              v-html="instructions[1].body"
            ></div>
          </div>
        </div>
        <div class="w-full md:w-1/2 md:px-6 mb-6">
          <div v-if="instructions[2].heading || instructions[2].body">
            <p
              v-if="instructions[2].heading"
              class="text-orange-dark text-xl font-bold mb-1"
            >
              {{ instructions[2].heading }}
            </p>
            <div
              v-if="instructions[2].body"
              class="text-gray-muted"
              v-html="instructions[2].body"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "InstructionsModal",
  props: {
    instructions: {
      type: Array,
      default: Array,
    },
    modalName: {
      type: String,
      default: String,
    },
    showInstructions: {
      type: Boolean,
      default: Boolean,
    },
  },
  data() {
    return {
      responsive: false,
    };
  },
  watch: {
    showInstructions: {
      handler() {
        if (this.showInstructions) {
          this.$modal.show(this.modalName);
        }
      }
    },
  },
  methods: {
    // handles blocking of scrolling when a modal is open
    beforeOpen(event) {
      document
        .getElementsByTagName("html")[0]
        .classList.add("v--modal-block-scroll");
    },
    beforeClose(event) {
      window.removeEventListener("resize", this.calculateWidth);
      document
        .getElementsByTagName("html")[0]
        .classList.remove("v--modal-block-scroll");
    },
    closed(event) {
      this.$root.$emit('trigger::closeInstructions');
    },
    afterOpen(e) {
      this.calculateWidth();
      window.addEventListener("resize", this.calculateWidth);
    },
    calculateWidth(e) {
      this.responsive = window.innerWidth <= 1024;
    },
  },
};
</script>

<style></style>
