<template>
  <div>
    <ais-instant-search :search-client="algoliaClient" index-name="Events">
      <section class="section pt-6 bg-gray-light">
        <div class="container">
          <div class="flex flex-wrap md:-mx-6">
            <!-- Filters -->
            <transition name="fade" v-cloak>
              <div
                v-show="mobileFilterOpen || isDesktop"
                class="fixed inset-0 z-20 w-full pt-0 lg:block lg:relative lg:w-1/4 lg:pt-6 md:px-6 "
                :class="[mobileFilterOpen ? 'block' : 'hidden']"
              >
                <aside-filter
                  :menu-list="eventType"
                  menu-list-attr="eventType.name"
                  menu-list-label="Event Type"
                  :activeMenuFilter="activeMenuFilter"
                  :isDesktop="isDesktop"
                  @menuUpdated="handleMenuClick"
                  @listUpdated="handleListClick"
                  @filtersCleared="handleClearEvent"
                  @closeFilterMenu="handleFilterMenuClick"
                />
              </div>
            </transition>

            <!-- Results -->
            <div class="md:px-6 w-full lg:w-3/4 pt-6">
              <ul
                class="flex justify-between lg:justify-end items-end text-gray-muted mb-6"
              >
                <!-- Mobile filter button toggle -->
                <li class="inline-block" v-if="isMobile">
                  <button
                    @click="mobileFilterOpen = !mobileFilterOpen"
                    class="text-blue-dark py-1 px-5 rounded-full border border-gray-500"
                  >
                    <span>Filters {{ totalFilters }}</span>
                  </button>
                </li>
                <li>
                  <!-- Sort Dropdown -->
                  <sort-dropdown
                    :ais-sort-options="aisSortOptions"
                    :add-sort-options="addSortOptions"
                    :start-active-sort="activeSort"
                  >
                  </sort-dropdown>
                </li>
              </ul>
              <!-- Configure the results -->
              <template v-if="activeSort.label == 'Distance'">
                <!-- 
                When you use aroundLatLng with the *default precision* (or a small precision), 
                it will order the results nearest to farthest from the point entered (users lat/lng). 
                This reason is why we don't set a precision/radius.
                -->
                <ais-configure
                  :getRankingInfo="true"
                  :aroundLatLng="this.userLatLng"
                  :aroundRadius="radius"
                  :hits-per-page.camel="6"
                />
              </template>
              <template v-else>
                <!-- 
                When a query is initiated or sorting is by title_desc or title_asc
                this query is hit, which fetches ALL results - just sorted respectively
                -->
                <ais-configure
                  :getRankingInfo="true"
                  :hits-per-page.camel="60"
                />
              </template>
              <ais-infinite-hits :transform-items="getEvents">
                <template slot-scope="{ items, isLastPage, refineNext }">
                  <ul class="flex flex-wrap" v-if="items.length">
                    <li
                      class="w-full mb-6 shadow hover:shadow-2xl transition-all"
                      v-for="(entry, index) in items"
                      :key="entry.objectID"
                    >
                      <event-card :event="entry"></event-card>
                    </li>
                  </ul>
                  <div v-else>
                    <p class="text-2xl text-gray-600 text-center">
                      There are no events at this time
                    </p>
                  </div>
                </template>
              </ais-infinite-hits>
            </div>
          </div>
        </div>
      </section>
    </ais-instant-search>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/reset.css";
import AsideFilter from "./AsideFilter";
import SortDropdown from "./SortDropdown";
import EventCard from "./EventCard";
import moment from "moment";

export default {
  name: "EventsListing",
  props: ["userLatLng", "userZip"],
  data() {
    return {
      query: "",
      events: [],
      index: "",
      radius: "",
      eventType: [],
      now: moment().unix(Number),
      activeMenuFilter: "",
      activeListFilters: [],
      mobileFilterOpen: false,
      activeSort: {
        label: "Date",
        icon: "calendar-alt",
      },
      algoliaClient: algoliasearch(
        "68G5M92M1B",
        "c24543a92b3e155b20a0b69f1ca4b554"
      ),
      aisSortOptions: [
        {
          icon: "calendar-alt",
          value: "events_by_date_asc",
          label: "Date",
        },
      ],
      addSortOptions: [
        {
          icon: "map-marker-alt",
          value: "distance",
          label: "Distance",
        },
      ],
    };
  },
  mounted() {
    // set index
    this.index = this.algoliaClient.initIndex("Events");

    //const filters = date >= this.now;
    console.log(this.activeSort.label);
    console.log(this.activeMenuFilter);
    this.index
      .search({
        query: "",
        hitsPerPage: 1000,
        numericFilters: ["dateInUnix >= " + this.now],
        //filters: filters
      })
      .then((res) => {
        this.events = res.hits;
      });

    // Get the Events facets for filtering
    this.index
      .searchForFacetValues({
        facetName: "eventType.name",
        facetQuery: "",
      })
      .then((res) => {
        const types = res.facetHits;
        types.forEach((el) => {
          el.checked = false;
        });
        this.eventType = types;
      });
    // Listen for sort events and respond accordingly
    this.$root.$on("sortEvent", ($event) => {
      this.activeSort.label = $event;
    });
  },
  methods: {
    // Get only new events
    getEvents(items) {
      return items.filter(
        (item) =>
          item.startDateInUnix >= this.now || item.endDateInUnix >= this.now
      );
    },
    // Track the active professions
    handleMenuClick(item) {
      // Add item to array, if not already there
      this.query = "";
      this.activeMenuFilter =
        item.value == this.activeMenuFilter ? "" : item.value;
    },

    // Track the active certifications
    handleListClick(item) {
      // Add/remove filters from array
      this.query = "";
      const activeFilter = this.activeListFilters.indexOf(item.value);
      if (activeFilter == -1) {
        this.activeListFilters.push(item.value);
      } else if (activeFilter > -1) {
        this.activeListFilters.splice(activeFilter, 1);
      }
    },

    // Clear out filters on user click
    handleClearEvent() {
      this.activeListFilters = [];
      this.activeMenuFilter = "";
    },

    // Close the filter menu when clicked
    handleFilterMenuClick() {
      this.mobileFilterOpen = false;
    },
  },
  computed: {
    isMobile: function() {
      return this.$screen.xs && !this.$screen.lg;
    },
    isDesktop: function() {
      this.mobileFilterOpen = false;
      return this.$screen.lg;
    },
    totalFilters: function() {
      let totalFilters = [...this.activeListFilters];
      if (this.activeMenuFilter.length) {
        totalFilters.push(this.activeMenuFilter);
      }
      return totalFilters.length > 0 ? "• " + totalFilters.length : "";
    },
  },
  // Clear out all filters when a query starts firing
  watch: {
    query: {
      handler(val) {
        if (val) {
          this.activeListFilters = [];
          this.activeMenuFilter = "";
        }
      },
      deep: true, // set deep to true so Vue knows it should watch the nested data for changes.
    },
  },
};
</script>
