<template>
  <div id="dropdown" class="relative inline-block flex justify-center">
    <button
      @click="isOpen = true"
      class="bg-white inline-flex items-center pl-6 pr-2 py-2 font-semibold text-blue-dark rounded-lg focus:outline-none shadow-md hover:shadow-lg transition-all"
    >
      <span class="uppercase text-sm font-semibold leading-loose tracking-wide" v-html="buttonLabel"></span>
      <svg
        class="ml-2 h-6 w-6 text-blue-light"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
        />
      </svg>
    </button>

    <div v-show="isOpen" @click="isOpen = false" class="fixed inset-0" tabindex="-1"></div>

    <transition
      enter-class="opacity-0 scale-90"
      enter-active-class="ease-out transition-fastest"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-active-class="ease-in transition-fastest"
      leave-to-class="opacity-0 scale-90"
    >
      <div v-show="isOpen" class="z-10 mt-2 absolute left-0 origin-top-left">
        <div class="w-64 text-left bg-white rounded-lg shadow-lg">
          <ais-menu :attribute="menuAttribute">
            <ul class="bg-white border-t-2 border-gray-200 py-1 sort-list uppercase text-sm font-semibold leading-loose tracking-wide" 
                slot-scope="{items, refine }"
            >
            
                <ais-clear-refinements>
                    <div slot-scope="{ canRefine, refine, createURL }">
                        <li class="cursor-pointer text-blue-dark hover:bg-gray-lightest"
                            :class="{ 'bg-gray-lightest': activeFilter == 'All' }"
                        >
                            <span class="sort-link"
                                @click="resetFilter(refine, createURL, 'All')"
                            >
                                All
                            </span>
                        </li> 
                    </div>
                </ais-clear-refinements>
            
              <li class="cursor-pointer text-blue-dark hover:bg-gray-lightest" 
                v-for="(item, index) in items" 
                :key="item.value" 
                :value="item.value"
                :class="{ 'bg-gray-lightest': activeFilter == item.label }">
                    <span class="sort-link" 
                    :class="{ 
                        'border-t-0': index <= 0,
                        'border-t': index > 0
                    }" 
                    @click="changeFilter(refine, item)">
                        {{ item.label }}
                    </span>
                </li>
            </ul>
          </ais-menu>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "AisMenuDropdown",
  props: ["aisMenuOptions", "menuAttribute"],
  data() {
    return {
      isOpen: false,
      activeFilter: {
        label: 'All'
      }
    };
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  computed: {
    buttonLabel: function() {
      if (!this.activeFilter) {
        return this.addSortOptions[0].label;
      } else {
        return this.activeFilter.label;
      }
    }
  },
  methods: {
     changeFilter(refine, item) {
        refine(item.value);
        this.activeFilter.label = item.label;
    },
    resetFilter(refine, createUrl, item) {
        refine(item);
        createUrl;
        this.activeFilter.label = item;
    }
  }
};
</script>

<style lang="scss" scoped>
.sort-list {
  @apply p-0;
  .sort-link {
    @apply block px-6 py-2 leading-tight border-gray-200;
  }
}
.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.transition-fastest {
  transition-duration: 75ms;
}

.transition-faster {
  transition-duration: 0.1s;
}

.transition-fast {
  transition-duration: 0.15s;
}

.transition-medium {
  transition-duration: 0.2s;
}

.transition-slow {
  transition-duration: 0.3s;
}

.transition-slower {
  transition-duration: 0.5s;
}

.transition-slowest {
  transition-duration: 0.7s;
}

.transition-all {
  transition-property: all;
}

.transition-opacity {
  transition-property: opacity;
}

.transition-transform {
  transition-property: transform;
}
.scale-90 {
  transform: scale(0.9);
}

.scale-100 {
  transform: scale(1);
}
</style>
