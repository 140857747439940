<template>
  <div class="h-full lg:h-auto">
    <ais-panel
      :class-names="{
        'ais-Panel': 'w-full shadow mb-6 bg-white h-full lg:h-auto',
        'ais-Panel-header': 'lg:p-6 lg:pb-1'
      }"
    >
      <!-- Panel Header -->
      <template slot="header">
        <div class="flex justify-between items-center flex-row w-full py-3 px-6 lg:px-0 lg:pb-1 mb-3 border-b">
          <template v-if="isDesktop">
            <p class="text-blue-dark font-semibold">Filters</p>
          </template>
          <template v-else>
            <button @click="closeMobileFilterMenu" class="appearance-none leading-none">
              <font-awesome-icon
                class="text-blue-dark text-xl"
                :icon="['fa', 'times']"
              ></font-awesome-icon>
            </button>
          </template>
          <ais-clear-refinements 
          :class-names="{
            'ais-ClearRefinements': 'leading-none'
          }">
            <div slot-scope="{ canRefine, refine, createURL }">
              <a
                class="text-base lg:text-sm text-orange-bright"
                :href="createURL()"
                @click.prevent="handleClearEvent(refine)"
                v-if="canRefine"
              >
                Clear all
              </a>
            </div>
          </ais-clear-refinements>
        </div>
      </template>

      <!-- Panel Filters -->
      <template slot="default">
        <div class="px-6 pb-6">
          <!-- Professions  -->
          <p class="text-gray-600 text-base lg:text-sm mb-2 text-blue-dark font-semibold">
            Professional Type
          </p>
          <ais-menu
            attribute="professionalType.name"
            :transform-items="transformProfessions"
            :class-names="{
              'ais-Menu': 'border-b pb-3 mb-3 text-base lg:text-xs'
            }"
          >
            <ul slot-scope="{ items, refine, createURL }">
              <li
                v-for="item in items"
                :key="item.value"
                class="mb-3 text-gray-muted flex items-center checkbox-wrapper"
              >
                <input
                  type="checkbox"
                  class="opacity-0 absolute"
                  :checked="activeMenuFilter == item.label ? true : false"
                  :value="item.value"
                />
                <span
                  class="checkbox-custom"
                  @click="handleMenuClick(refine, item)"
                ></span>
                <a
                  class="checkbox-label leading-none block ml-5"
                  :href="createURL(item.value)"
                  @click.prevent="handleMenuClick(refine, item)"
                  :class="activeMenuFilter == item.label ? 'font-bold' : ''"
                >
                  {{ item.label }}
                </a>
              </li>
            </ul>
          </ais-menu>

          <!-- Certifications -->
          <p class="text-gray-600 text-base lg:text-sm mb-2 text-blue-dark font-semibold">
            Certifications
          </p>
          <ais-refinement-list
            attribute="certifications.name"
            :limit="20"
            :transform-items="transformCertifications"
          >
            <div slot-scope="{ items, refine, createURL }">
              <ul>
                <li
                  v-for="item in items"
                  :key="item.value"
                  class="mb-3 flex text-base lg:text-xs mb-1 text-gray-muted checkbox-wrapper"
                >
                  <input
                    type="checkbox"
                    class="opacity-0 absolute left-0"
                    :checked="
                      activeListFilters.includes(item.value) ? true : false
                    "
                    :value="item.value"
                  />
                  <span
                    class="checkbox-custom"
                    @click="handleListClick(refine, item)"
                  ></span>
                  <a
                    class="checkbox-label leading-none ml-5 block"
                    :href="createURL(item)"
                    :class="
                      activeListFilters.includes(item.value) ? 'font-bold' : ''
                    "
                    @click.prevent="handleListClick(refine, item)"
                  >
                    {{ item.value }}
                  </a>
                </li>
              </ul>
            </div>
          </ais-refinement-list>
          <div class="mt-8 mb-3" v-if="isDesktop">
            <button
              @click="showSuggestModal"
              class="w-full text-white py-2 px-8 rounded-full bg-blue-dark hover:bg-blue-gray transition-bg transition-500"
            >
              <font-awesome-icon
                class="text-white"
                :icon="['fas', 'edit']"
              ></font-awesome-icon>
              Suggest an Edit
            </button>
          </div>
          <div class="mt-8 mb-3" v-else>
              <ais-stats>
                <template slot-scope="{ nbHits }">
                  <button
                    @click.prevent="closeMobileFilterMenu"
                    class="w-full py-2 px-8 rounded-full transition-bg transition-500"
                    :class="[ nbHits > 0 ? 'text-white bg-orange-bright hover:bg-orange-dark' : 'bg-gray-400 cursor-not-allowed text-gray-600']">
                    View {{ nbHits }} results
                  </button>
                  <p class="text-blue-dark text-sm text-center mt-2" v-if="nbHits == 0">
                    <font-awesome-icon
                      :icon="['fas', 'info-circle']"
                    ></font-awesome-icon>
                    There are no results! Try removing some filters.
                  </p>
                </template>
              </ais-stats>
          </div>
        </div>
      </template>
    </ais-panel>
    <modal
      name="suggest-edit"
      @before-open="beforeOpen"
      @before-close="beforeClose"
    >
      <div
        class="text-center flex items-center justify-center text-blue-dark h-full"
      >
        <p class="text-xl w-4/5">
          <a class="text-orange-bright" href="mailto:info@betterbuiltnw.com"
            >Contact us</a
          >
          to suggest an edit. We will email you about the status of your edits.
        </p>
      </div>
    </modal>
  </div>
</template>

<script>
import SortDropdown from "./SortDropdown";
import orderBy from "lodash/orderBy";
import { createWidgetMixin } from "vue-instantsearch";
import { connectClearRefinements } from "instantsearch.js/es/connectors";

export default {
  name: "ProfessionalsFilter",
  props: {
    query: String,
    professions: Array,
    certifications: Array,
    includedAttributes: Array,
    activeMenuFilter: String,
    activeListFilters: Array,
    isDesktop: Boolean,
    aisSortOptions: Array,
    addSortOptions: Array
  },

  // Connect algolias connector to our instance
  mixins: [createWidgetMixin({ connector: connectClearRefinements })],

  // Set up algolias connector prop that automatically gets passed in
  computed: {
    widgetParams() {
      return {
        includedAttributes: this.includedAttributes
      };
    }
  },

  // Clear out all filters when a user begins a query
  watch: {
    query: {
      handler(val) {
        if (val) {
          this.state.refine();
        }
      },
      deep: true // set deep to true so Vue knows it should watch the nested data for changes.
    }
  },
  methods: {
    // Set items into variable to override algolias weird sorting/ranking
    transformProfessions(items) {
      const builtProfessions = this.professions.map(elem => ({
        label: elem.value,
        value: elem.value,
        isRefined: false
      }));
      return orderBy(builtProfessions, ["label"], ["asc"]);
    },

    // Set items into variable to override algolias weird sorting/ranking
    transformCertifications(items) {
      const builtCertifications = this.certifications.map(elem => ({
        label: elem.value,
        value: elem.value,
        isRefined: false
      }));
      return orderBy(builtCertifications, ["label"], ["asc"]);
    },

    // Track the active professions
    handleMenuClick(refine, item) {
      // Init the internal ais refinement method
      refine(item.value);
      this.$emit("menuUpdated", item);
    },

    // Ttrack the active certifications
    handleListClick(refine, item) {
      // Init the internal ais refinement method
      refine(item.value);
      this.$emit("listUpdated", item);
    },

    // Clear out filters on user click
    handleClearEvent(refine) {
      refine();
      this.$emit("filtersCleared");
    },

    showSuggestModal() {
      this.$modal.show("suggest-edit");
    },
    // handles blocking of scrolling when a modal is open
    beforeOpen(event) {
      document
        .getElementsByTagName("html")[0]
        .classList.add("v--modal-block-scroll");
    },
    beforeClose(event) {
      document
        .getElementsByTagName("html")[0]
        .classList.remove("v--modal-block-scroll");
    },
    closeMobileFilterMenu() {
      this.$emit('closeFilterMenu', event);
    }
  }
};
</script>
<style lang="scss" scoped>
.checkbox-wrapper {
  display: block;
  position: relative;
  cursor: pointer;
  clear: both;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkbox-custom {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: transparent;
    border-radius: 2px;
    transition: all 0.3s ease-out;
    background: #e9eff4;
    @screen lg {
      top: -0.1rem;
    }
    &:after {
      position: absolute;
      content: "";
    }
  }
  input:checked ~ .checkbox-custom {
    display: inline-block;
    @apply bg-orange-bright;
    height: 15px;
    width: 15px;
    border-radius: 2px;
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
  input:checked ~ .checkbox-custom:after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 5px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
  }
}
</style>
