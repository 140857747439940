<template>
  <div>
      <div id="dropdown" class="flex inline-block justify-center md:hidden ">
        <button
          @click="isOpen = true"
          class="inline-flex items-center py-2 focus:outline-none transition-all bg-white shadow hover:shadow-lg  border-b border-gray-200 pl-6 pr-3 text-blue-dark font-semibold w-full justify-center"
        >
          <span
            class="text-sm uppercase font-semibold leading-loose tracking-wide"
            v-html="buttonLabel"
          >
          </span>
          <svg
            class=" h-6 w-6 text-blue-light"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"/>
          </svg>
        </button>

        <div
          v-show="isOpen"
          @click="isOpen = false"
          class="fixed inset-0"
          tabindex="-1"
        ></div>

        <transition
          enter-class="opacity-0 scale-90"
          enter-active-class="ease-out transition-fastest"
          enter-to-class="opacity-100 scale-100"
          leave-class="opacity-100 scale-100"
          leave-active-class="ease-in transition-fastest"
          leave-to-class="opacity-0 scale-90"
        >
          <div v-show="isOpen" class="z-10 dropdown jumpnav-dropdown origin-top-right" :style="dropdownPositioning">
            <div class="w-full text-left bg-white rounded-lg shadow-lg ">
              <ul class="bg-white py-1 sort-list uppercase text-sm font-semibold leading-loose tracking-wide text-center"> 
                <template v-if="options">
                  <li
                    class="sort-link cursor-pointer border-t text-blue-dark hover:bg-gray-lightest"
                    v-for="option in options"
                    :key="option.title"
                    :class="{ 
                      'bg-gray-lightest': anchor == option.uri,
                    }"
                    @click="$root.jumpNavValue = option.uri"
                  >
                    {{ option.title }}
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </transition>
      </div>
      <div class="hidden md:flex">
        <ul class="w-full text-center bg-white shadow border-b border-gray-200 text-blue-dark">
            <template v-if="options">
              <li
                class="inline-block mx-4 my-2"
                v-for="option in options"
                :key="option.title"
                :class="{ 
                  'text-orange-bright': anchor == option.uri,
                }"
                @click="$root.jumpNavValue = option.uri"
              >
                {{ option.title }}
              </li>
            </template>
            
        </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Jumpnav",
  props: ["options"],
  mounted() {
    if (window.location.hash) {
        let item = (this.options.find(obj => obj.uri === window.location.hash) || {})
        this.buttonLabel = item.title
        this.anchor = item.uri
    } else {
        let firstItem = this.options.shift()
        this.buttonLabel = firstItem.title
    }
  },
  data() {
    return {
      isOpen: false,
      anchor: window.location.hash,
      test: "",
      buttonLabel: ""
    };
  },
  filters: {
    truncate: function (value, limit) {
      if (value.length > limit) {
          value = value.substring(0, (limit - 3)) + '...';
      }
      return value
    }
  },
  computed: {
    dropdownPositioning: function() {
      return "";
    }
  },
  watch: {
    '$root.jumpNavValue': function(value) {
        let item = (this.options.find(obj => obj.uri === value) || {})
        this.buttonLabel = item.title;
        window.location = value;
        this.anchor = value;
        this.isOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.sort-list {
  @apply p-0;
  .sort-link {
    @apply block px-6 py-3 leading-tight border-gray-200;
  }
}
.dropdown {
    position: absolute;
    width: 100%;
    margin-top: 2.78rem;
}
.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.transition-fastest {
  transition-duration: 75ms;
}

.transition-faster {
  transition-duration: 0.1s;
}

.transition-fast {
  transition-duration: 0.15s;
}

.transition-medium {
  transition-duration: 0.2s;
}

.transition-slow {
  transition-duration: 0.3s;
}

.transition-slower {
  transition-duration: 0.5s;
}

.transition-slowest {
  transition-duration: 0.7s;
}

.transition-all {
  transition-property: all;
}

.transition-opacity {
  transition-property: opacity;
}

.transition-transform {
  transition-property: transform;
}
.scale-90 {
  transform: scale(0.9);
}

.scale-100 {
  transform: scale(1);
}
</style>
