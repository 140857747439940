<template>
  <div class="resource-slider">
    <vue-glide
      :options="options"
      ref="slider"
      data-aos-anchor-placement="top-bottom"
      data-aos="fade-left"
    >
      <vue-glide-slide v-for="entry in data" :key="entry.objectId">
        <resource-card :resource="entry"></resource-card>
      </vue-glide-slide>
      <template slot="control">
        <button data-glide-dir="<">
          <font-awesome-icon
            class="self-center text-copper-300"
            icon="chevron-left"
          ></font-awesome-icon>
        </button>
        <button data-glide-dir=">">
          <font-awesome-icon
            class="self-center text-copper-300"
            icon="chevron-right"
          ></font-awesome-icon>
        </button>
      </template>
    </vue-glide>
  </div>
</template>

<script>
import { Glide, GlideSlide } from "vue-glide-js";
import ResourceCard from "./ResourceCard";

export default {
  name: "Slider",
  props: ["data"],
  data() {
    return {
      options: {
        type: "carousel",
        perView: 4,
        gap: 30,
        peek: {
          before: 10,
          after: 10,
        },
        breakpoints: {
          576: {
            perView: 1,
            peek: {
              before: 10,
              after: 10,
            },
          },
          992: {
            perView: 2,
            peek: {
              before: 10,
              after: 10,
            },
          },
          1199: {
            perView: 3,
            peek: {
              before: 10,
              after: 10,
            },
          },
        },
      },
    };
  },
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
};
</script>

<style scoped lang="scss">
.resource-slider ::v-deep {
  .glide {
    @apply relative;
  }
  .glide * {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  .glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -ms-touch-action: pan-Y;
    touch-action: pan-Y;
    padding: 0;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    will-change: transform;
  }
  .glide__slide,
  .glide__slides--dragging {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .glide__slide {
    width: 100%;
    height: auto;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    white-space: normal;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }
  .glide__slide a {
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .glide__arrows,
  .glide__bullets {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .glide--rtl {
    direction: rtl;
  }
  .glide__track {
    @apply pb-12 pt-3;
    overflow: hidden;
  }
  div[data-glide-el="controls"] {
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;
  }
  button[data-glide-dir="<"] {
    left: -30px;
  }
  button[data-glide-dir=">"] {
    right: -30px;
  }
  button[data-glide-dir="<"],
  button[data-glide-dir=">"] {
    position: absolute;
    border: 0;
    outline: 0;
    padding: 10px;
    opacity: 0.3;
    color: #000;
    cursor: pointer;
  }
}
</style>
