<template>
  <div>
    <!-- <program-card :card="{{ cardData|json_encode }}" style=" color: {{ cardData.textColor}}"></program-card> -->
    <!--  style=" color: {{ cardData.textColor}}" -->
    <h1 v-if="card.heading" class="text-center text-2xl pb-5">
      {{ card.heading }}
    </h1>
    <div class="flex flex-wrap h-full">
      <div class="flex gap-3 w-full md:w-1/2 md:border-r-2 border-white pr-10">
        <!-- <div v-for="img in card.logo" :key="img"> -->
        <div v-if="card.logo1">
          img goes here
          <!-- <img class="w-full h-full" src="{{ card.logo1[0].url }}" alt="" /> -->
        </div>
        <div>
          <h1 class="text-base">
            {{ card.programTitle1 }}
          </h1>
          <p v-html="card.programDescription1"></p>
        </div>
      </div>
      <div class="w-full md:w-1/2 pt-10 md:pt-0 md:pl-10">
        <h1 class="text-base">{{ card.programTitle2 }}</h1>
        <p v-html="card.programDescription2"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgramCard",
  props: ["card"],
};
</script>

<style scoped></style>
