<template>
  <div class="flex h-full items-center">
    <gmap-map
      ref="gmap"
      :center="center"
      :zoom="zoom"
      :options="mapOptions"
      class="w-full h-64 sm:h-85 md:h-full"
    >
      <gmap-info-window
        v-if="includeInfoBox"
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
      </gmap-info-window>
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        @click="toggleInfoWindow(m, index)"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "GoogleMap",
  props: {
    address: {
      type: String,
      default: "",
    },
    lat: {
      type: String,
      default: "",
    },
    lng: {
      type: String,
      default: "",
    },
    locations: {
      type: Array,
      default: [],
    },
    mapOptions: {
      type: Object,
      default: {},
    },
    includeInfoBox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      map: null,
      markers: [],
      zoom: 15,
      center: {
        lat: 0,
        lng: 0,
      },
      currentPlace: null,
      currentMidx: null,
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },

  mounted() {
    // Wait for the map to render in order set markers
    this.$refs.gmap.$mapPromise.then((map) => {
      this.map = map;
      if (this.locations.length > 0) {
        // Adding multiple markers
        this.addMarkersToMap(this.locations, map);
      } else {
        // Adding a single marker
        this.addMarker(map);
      }
    });
  },

  // Keep track of reactive locations and place markers on map
  watch: {
    locations: debounce(function(activeLocations) {
      this.$refs.gmap.$mapPromise.then((map) => {
        this.addMarkersToMap(activeLocations, map);
      });
    }, 100),
  },

  methods: {
    // For a single location
    addMarker(map) {
      if (this.address && this.locations.length == 0) {
        this.center = { lat: this.lat, lng: this.lng };
        let lat = parseFloat(this.lat);
        let lng = parseFloat(this.lng);
        const marker = {
          lat: lat,
          lng: lng,
        };
        this.markers.push({ position: marker });
        this.center = marker;
      } else {
        console.error("Pass in a valid location as a string or as an array");
      }
    },
    // For a multiple locations
    addMarkersToMap(markers, map) {
      // Reset markers to avoid duplicated results
      this.markers = [];
      // Create bounds google object
      let bounds = new google.maps.LatLngBounds();
      // Add markers to map
      markers.forEach((coor) => {
        let lat = parseFloat(coor.lat);
        let lng = parseFloat(coor.lng);

        let latLngObj = new google.maps.LatLng(lat, lng);
        bounds.extend(latLngObj);

        const marker = {
          lat: lat,
          lng: lng,
        };

        this.markers.push({
          content: `
              <div class="p-1 overflow-hidden">
                <p class='text-gray-900 font-bold mb-2'>${coor.title}</p>
                <ul class="flex flex-row flex-wrap -mx-2">
                  ${
                    coor.phone
                      ? `<li class='text-xs font-medium px-2 mb-2'>
                      <a href='tel:'${coor.phone}'>${coor.phone}</a>
                    </li>`
                      : ``
                  }
                  ${
                    coor.website
                      ? `<li class='text-xs font-medium border-l border-gray-500 px-2 mb-2'>
                      <a href='${
                        coor.website
                      }' target='_blank'>Website</a></li>`
                      : ``
                  }
                  <li class="w-full px-2 font-medium">
                    ${
                      typeof coor.address === "object" && coor.address !== null
                        ? coor.address.street1 +
                          `, <br/> ` +
                          coor.address.city +
                          `, ` +
                          coor.address.state +
                          coor.address.zip
                        : coor.address
                    }
                  </li>
                </ul>
              </div>
            `,
          position: marker,
        });
      });

      // Center map around markers
      map.fitBounds(bounds);
    },
    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.content;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
  },
};
</script>

<style lang="css">
.vue-map-container {
  position: relative;
}

.vue-map-container .vue-map {
  left: 0; right: 0; top: 0; bottom: 0;
  position: absolute;
}
.vue-map-hidden {
  display: none;
}
</style>
