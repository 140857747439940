<template>
  <div class="section md:pt-4 bg-white">
    <div class="container">
      <div class="flex justify-center items-center ">
        <div class="w-full md:w-4/5 xl:w-1/2 text-center">
          <h1 v-if="pageTitle">{{ pageTitle }}</h1>
          <!-- Main search field -->
          <ais-search-box placeholder="Enter a zip code or name">
            <div class="ais-SearchBox flex flex-row justify-center">
              <form
                action=""
                role="search"
                novalidate="novalidate"
                class="ais-SearchBox-form flex flex-row w-full sm:w-1/2"
              >
                <input
                  v-model="query"
                  type="search"
                  autocorrect="off"
                  autocapitalize="off"
                  autocomplete="off"
                  spellcheck="false"
                  required="required"
                  maxlength="512"
                  aria-label="Search"
                  placeholder="Enter a zip code or name"
                  class="bg-gray-light appearance-none rounded-full w-full py-3 px-6 text-gray-700 leading-tight ais-SearchBox-input placeholder-blue-light"
                />
                <div
                  title="Search"
                  class="ais-SearchBox-submit flex items-center cursor-default -mx-8 text-blue-dark outline-none"
                >
                  <svg
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 40 40"
                    class="ais-SearchBox-submitIcon w-4 h-4 fill-current"
                  >
                    <path
                      d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </form>
            </div>
          </ais-search-box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { createWidgetMixin } from "vue-instantsearch";
import { connectInfiniteHits } from "instantsearch.js/es/connectors";
export default {
  props: {
    pageTitle: {
      type: String,
      default: "Default Page Title",
    },
  },
  data() {
    return {
      query: "",
    };
  },
  mixins: [createWidgetMixin({ connector: connectInfiniteHits })],
  watch: {
    query: debounce(function(val) {
      this.$children[0].state.refine(this.query);
    }, 500),
  },
};
</script>
