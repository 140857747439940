<template>
  <div
    class="dropdown"
    :class="{ 'is-active': isDropdownActive }"
    @click="isDropdownActive = !isDropdownActive"
    v-on-clickaway="away"
  >
    <div class="dropdown-menu cursor-pointer" role="menu">
      <a
        class="dropdown-trigger"
        aria-haspopup="true"
        aria-controls="{ 'dropdown-menu'}"
      >
        {{ navigationNodes.parent }}
        <template v-if="navigationNodes.children">
          <svg
            class=" h-5 w-5 text-blue-light"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
            />
          </svg>
        </template>
      </a>
      <transition
        v-if="navigationNodes.children"
        enter-class="opacity-0 scale-90"
        enter-active-class="ease-out transition-fastest"
        enter-to-class="opacity-100 scale-100"
        leave-class="opacity-100 scale-100"
        leave-active-class="ease-in transition-fastest"
        leave-to-class="opacity-0 scale-90"
      >
        <ul v-show="isDropdownActive" class="dropdown-content">
          <li
            class="dropdown-item"
            v-for="child in navigationNodes.children"
            :key="child.id"
            v-html="child.link"
          ></li>
          <template v-if="isMobile && navigationNodes.parent == 'Menu'">
            <li class="dropdown-item">
                <a href="/search">Search</a>
            </li>
          </template>
        </ul>
      </transition>
    </div>
  </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
import NewsletterModal from "./NewsletterModal";
export default {
  props: ["navigationNodes", "embed"],
  mixins: [clickaway],
  data() {
    return {
      isDropdownActive: false
    };
  },
  computed: {
    isMobile: function() {
      return !this.$screen.lg && !this.$screen.xl;
    }
  },
  methods: {
    away() {
      this.isDropdownActive = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.dropdown {
    @apply relative;
    @screen lg {
        @apply static;
    }
}
::v-deep {
  .dropdown-trigger {
    @apply flex flex-row items-center font-bold text-blue-gray;
  }
  .dropdown-content {
    min-width: 10%;
    top: 4.5rem;
    @apply absolute shadow-nav bg-white;
    &:last-child {
        top: 2rem;
        right: 0;
        @screen lg {
            top: 4.5rem;
            right: auto;
        }
    }
    .dropdown-item {
      @apply m-0 border-t;
      &:first-child {
        @apply border-t-0;
      }
      a {
        @apply text-gray-muted block p-4 leading-none transition-500;
        &:hover {
          @apply bg-gray-lightest;
        }
      }
    }
  }
  .ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .transition-fastest {
    transition-duration: 75ms;
  }

  .transition-faster {
    transition-duration: 0.1s;
  }

  .transition-fast {
    transition-duration: 0.15s;
  }

  .transition-medium {
    transition-duration: 0.2s;
  }

  .transition-slow {
    transition-duration: 0.3s;
  }

  .transition-slower {
    transition-duration: 0.5s;
  }

  .transition-slowest {
    transition-duration: 0.7s;
  }

  .transition-all {
    transition-property: all;
  }

  .transition-opacity {
    transition-property: opacity;
  }

  .transition-transform {
    transition-property: transform;
  }
  .scale-90 {
    transform: scale(0.9);
  }

  .scale-100 {
    transform: scale(1);
  }
}
</style>
