<template>
    <div>
        <section class="pt-10 bg-gray-100 section">
            <div class="container">
                <div class="flex mb-8 md:-mx-6">
                    <div class="w-full md:px-6">
                        <h1 class="text-2xl"><span class="font-medium">Your</span> Collection</h1>
                        <hr class="border-blue-light">
                    </div>
                </div>
                <div class="flex flex-wrap md:-mx-6">
                    <div class="md:w-1/4 md:px-6">
                        <p class="mb-12 text-sm font-bold text-blue-light">{{ selectedResources.length }} Resources Selected</p>
                        <template v-if="selectedResources.length > 0">
                            <share-widget :current-url="currentUrl"/>
                            <div class="mb-6">
                                <button type="button" 
                                    @click="downloadCollection()"
                                    class="font-medium button-primary"><font-awesome-icon class="self-center text-white" icon="download"></font-awesome-icon> Download All</button>
                            </div>
                            <div v-if="instructions">
                                <p v-if="instructions.heading" class="mb-2 text-lg font-bold text-blue-dark">{{ instructions.heading }}</p>
                                <div v-if="instructions.body" v-html="instructions.body" class="text-sm"></div>
                            </div>
                        </template>
                    </div>
                    <div class="md:w-3/4 md:px-6">
                        <ul class="flex flex-wrap md:-mx-6" v-if="selectedResources.length">
                            <li class="w-full mb-12 md:w-1/3 md:px-6" v-for="resource in selectedResources" :key="resource.objectID">
                                <resource-card :resource="resource"></resource-card>
                            </li>
                        </ul>
                        <div v-else>
                            <p>Looks like you don't have any resources in your collection. To add resources, visit the <a href="/resources">resources page</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import algoliasearch from "algoliasearch/lite";
    import ShareWidget from './ShareWidget';
    var JSZip = require("jszip");
    var JSZipUtils = require("jszip-utils");
    export default {
        name: "ShareResources",
        props: {
            instructions: {
                type: Object,
                default: Object
            }
        },
        data() {
            return {
                index: "",
                urlQueryString: '',
                collection: this.$root.collection,
                currentUrl: window.location.href,
                localCollection: [],
                selectedResources: [],
                searchClient: algoliasearch(
                    "68G5M92M1B",
                    "0f41898cd8301d5ed3eb6a82ed602098"
                ),
            }
        },
        mounted() {
            
            // Set the algolia index
            this.index = this.searchClient.initIndex('Resources');

            // Listen for removal of colleciton to remove
            // items from localCollection
            this.$root.$on('getCollectionSize', $event => {
                this.buildCollection();
                if (this.localCollection.length > 0) {
                    this.getResources();
                } else {
                    this.selectedResources = [];
                }
            });
            
            // Determine the state of the URL:
            // If there is an ID present
            console.log(this.$root.collection.length)
            if (this.currentUrl.indexOf('?id=') != -1) {
                this.buildCollectionFromUrl(this.currentUrl);
            } 
            else {
                // If there is no 'id' param present, 
                // query the users collection and build
                // URL from that
                if (this.collection.length > 0) {
                    this.buildCollection();   
                }
            }
        },
        computed: {
            getIdsFromUrl: function() {
                // Create new URL interface and set to local data var
                let url = (new URL(this.currentUrl)).searchParams;
                this.urlQueryString = url;
                
                // Map over ID's and update localCollection
                let ids = url.get("id").split(',');

                return ids;
            },
            rootCollectionListener: function() {
                return this.$root.collection;
            }
        },
        methods: {

            removeURLParameter(url, parameter) {
                //prefer to use l.search if you have a location/link object
                var urlparts = url.split('?');   
                if (urlparts.length >= 2) {

                    var prefix = encodeURIComponent(parameter) + '=';
                    var pars = urlparts[1].split(/[&;]/g);

                    //reverse iteration as may be destructive
                    for (var i = pars.length; i-- > 0;) {    
                        //idiom for string.startsWith
                        if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
                            pars.splice(i, 1);
                        }
                    }

                    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
                }
                return url;
            },

            // Get ID's from URL and add to localCollection for outputting in render
            buildCollectionFromUrl() {
                
                // Get IDs from URL and add to localCollection
                let ids = this.getIdsFromUrl;
                let idsFromUrl = ids.map(id => {
                    return 'objectID:' + id;
                });
                this.localCollection = idsFromUrl;
                this.getResources();           
            },
            
            // Get params from URL and add them to the root collection
            addToRootCollection() {
                let parsedResources = this.selectedResources.map(item => {
                    return {
                        id: item.objectID,
                        title: item.title,
                        reference: item.reference,
                        selected: true
                    }
                });
                this.$root.collection = parsedResources;
                localStorage.setItem('collection', JSON.stringify(this.$root.collection));
            },
            
            // Gets resources that are added to localCollection
            getResources() {
                this.index.search({
                    query: '',
                    facetFilters: [this.localCollection],
                    hitsPerPage: 1000,
                }).then(res => {
                    
                    this.selectedResources = res.hits;
                    
                    // Add the fetched IDs to the collection
                    this.addToRootCollection();
                });
            },
            
            // Queries the collection and adds resource ID's to URL
            buildCollection() {
                
                // Query users collection and get resource IDs
                let idsFromCollection = this.$root.collection.map(resource => {
                    return 'objectID:' + resource.id;
                });
                this.localCollection = idsFromCollection;

                if (this.localCollection.length > 0) {
                    this.getResources();   
                }
                
                // Only append ID param if localStorage has value
                this.createUrl();
            },

            // Create the URL based off of resources in root collection
            createUrl() {
                
                // Get Url
                this.urlQueryString = new URL(window.location.href.split('?')[0]);
                
                // Grab all IDs from array
                let queryString = this.$root.collection.map(resource => {
                    return resource.id;
                });

                // Create URL param with id
                this.urlQueryString.searchParams.append('id', queryString);

                // Add the newly created query string to the URL
                window.history.replaceState({}, document.title, this.urlQueryString);

                // Set current URL to adjusted URL
                this.currentUrl = this.urlQueryString;
            },
            // Download Collection
            downloadCollection: function(files) {
                var _this = this;
                var zip = new JSZip();
                var count = 0;
                var name = "collection.zip";
                var urls = [];
                // Check if collection exists
                if (this.$root.collection.length) {
                    // if collection exists, loop through to see if resource already exists in collection
                    for (var i = 0; i < this.$root.collection.length; i++) {
                        if (this.$root.collection[i].selected == true && this.$root.collection[i].reference.type == 'asset') {
                            console.log(this.$root.collection[i].reference.url)
                            urls.push(this.$root.collection[i].reference.url);
                        };
                        // Track Download
                        this.$gtm.trackEvent({
                            event: 'download-collection',
                            category: 'Resource Action',
                            action: 'Download Collection',
                            label: this.$root.collection[i].title,
                            title: this.$root.collection[i].title,
                            value: 1,
                            noninteraction: false // Optional
                        });
                    }
                    urls.join(", ")
                }

                var Promise = window.Promise;
                if (!Promise) {
                    Promise = JSZip.external.Promise;
                }
                /**
                 * Fetch the content and return the associated promise.
                 * @param {String} url the url of the content to fetch.
                 * @return {Promise} the promise containing the data.
                 */
                function urlToPromise(url) {
                    return new Promise(function(resolve, reject) {
                        JSZipUtils.getBinaryContent(url, function (err, data) {
                            if(err) {
                                reject(err);
                            } else {
                                resolve(data);
                            }
                        });
                    });
                }
                urls.forEach(function(url){
                    zip.file(url, urlToPromise(url), {binary:true});
                    count++;
                    if (count == urls.length) {
                        // when everything has been downloaded, we can trigger the dl
                        zip.generateAsync({type:"blob"}, function updateCallback(metadata) {
                           
                        })
                        .then(function callback(blob) {

                            // see FileSaver.js
                            saveAs(blob, "collection.zip");

                        }, function (e) {
                            //showError(e);
                            console.log(e);
                        });
                    }
                    if(!JSZip.support.blob) {
                        showError("This feature only works with updated browsers!");
                        return;
                    }
                });

            }
        }
    }   
</script>