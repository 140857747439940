// import devtools from '@vue/devtools';
import Vue from "vue";

import Components from "./components";
import Collection from "./components/Collection";
import "./scss/app.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faEnvelope,
  faPhoneAlt,
  faMapMarkerAlt,
  faCheck,
  faCheckCircle,
  faFilter,
  faPlay,
  faTimesCircle,
  faTimes,
  faSortAmountDownAlt,
  faSortAmountUpAlt,
  faEdit,
  faInfoCircle,
  faPlus,
  faMinus,
  faDownload,
  faExternalLinkAlt,
  faShare,
  faHome,
  faCalendarAlt,
  faTicketAlt,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebookF,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "isomorphic-unfetch";

// Third Party
import AOS from "aos";
import InstantSearch from "vue-instantsearch";
import VModal from "vue-js-modal";
import VueScreen from "vue-screen";
import { saveAs } from "file-saver";
import ProgressBar from "vue-simple-progress";
import VueGlide from "vue-glide-js";
import SocialSharing from "vue-social-sharing";
import { Tabs, Tab } from "vue-tabs-component";
import { BadgerAccordion, BadgerAccordionItem } from "vue-badger-accordion";
import VueClipboard from "vue-clipboard2";
import VueNoty from "vuejs-noty";
import VueMatchHeights from "vue-match-heights";
import VueScrollTo from "vue-scrollto";
import debounce from "lodash/debounce";
import Affix from "vue-affix";
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, { siteKey: '6LeD7dQaAAAAAGwvc53hXCcnGQ2Rha9WpQEXeEOj' })

// Google Maps
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAP_KEY,
  },
});

// Vue Google Tag Manager
import VueGtm from '@gtm-support/vue2-gtm';
import VueRouter from 'vue-router';
//const router = new VueRouter({ routes, mode, linkActiveClass });
Vue.use(VueGtm, {
  id: 'GTM-P8654F3', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  // queryParams: {
  //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
  //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview: 'env-4',
  //   gtm_cookies_win: 'x'
  // },
  //defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  //compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  //nonce: '2726c7f26c', // Will add `nonce` to the script tag
  //enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  //debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  //vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  //ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
  //trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

// Image dependencies
import lazySizes from "lazysizes";
import "lazysizes/plugins/bgset/ls.bgset.js";
import "lazysizes/plugins/parent-fit/ls.parent-fit.js";
import "lazysizes/plugins/respimg/ls.respimg.js";
import picturefill from "picturefill";

// Auto install thrid party plugins
Vue.use(InstantSearch);
Vue.use(VueScreen, {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
});
Vue.component("tabs", Tabs);
Vue.component("tab", Tab);
Vue.use(VueGlide);
Vue.use(SocialSharing);
Vue.component("BadgerAccordion", BadgerAccordion);
Vue.component("BadgerAccordionItem", BadgerAccordionItem);
Vue.use(VueClipboard);
Vue.use(VueMatchHeights);
Vue.use(VueScrollTo);

// Init Modal
Vue.use(VModal);

// Pass in default options to vue-scrollto component
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

// Vue affix
Vue.use(Affix);

// Init and add fontawesome icons
library.add(
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faEnvelope,
  faPhoneAlt,
  faMapMarkerAlt,
  faTwitter,
  faFacebookF,
  faLinkedinIn,
  faCheck,
  faCheckCircle,
  faFilter,
  faPlay,
  faTimesCircle,
  faTimes,
  faSortAmountUpAlt,
  faSortAmountDownAlt,
  faEdit,
  faInfoCircle,
  faPlus,
  faMinus,
  faDownload,
  faExternalLinkAlt,
  faHome,
  faCalendarAlt,
  faTicketAlt,
  faArrowLeft,
  faShare
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

/* 
  Used with ngrok to forward https to http when using Vue devtools for safari
    -> https://github.com/vuejs/vue-devtools/tree/master/shells/electron

  You will need to replace the 'host' with whatever host ngrok outputs
    -> format: devtools.connect('host', port)
*/

// if (process.env.NODE_ENV === 'development') {
//   devtools.connect('bb602e1c.ngrok.io', null)
// }

Vue.config.productionTip = false;

/* 
  Custom directive that tracks scrolling
  This should be added to the <main> tag
  ex: <main v-scroll="myCustomMethod"></main>
*/
Vue.directive("scroll", {
  inserted: function(el, binding) {
    let f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  },
});

Vue.use(VueNoty, {
  theme: "metroui",
  layout: "topRight",
  timeout: 10000,
});

const app = () => {
  new Vue({
    el: "#app",
    delimiters: ["${", "}"],
    data() {
      return {
        stickyNavHeight: 40,
        menuLinkClosed: true,
        mobileNavActive: false,
        resourceCardHover: false,
        jumpNavValue: '',
        collection: [],
        collectionSize: "",
        isIE10andBelow: false,
        showInstructions: false,
        overlayImageModalContent: null,
        formEl: null,
        formBtnDisabled: false,
        recaptchaToken: null
      };
    },
    components: {
      ProgressBar,
    },
    created() {
      // Get collection from local storage and save to 'collection' data prop, remove if not there
      if (localStorage.getItem("collection")) {
        try {
          this.collection = JSON.parse(localStorage.getItem("collection"));
        } catch (e) {
          localStorage.removeItem("collection");
        }
      }

      // Set the collection size once mounted
      this.collectionSize = this.$root.collection.length;
    },
    mounted() {
      this.$gtm.enable(true);
      // Only show IE error message for versions <= IE10
      if (navigator.userAgent.indexOf("MSIE") >= 0) {
        this.isIE10andBelow = true;
      }

      // Helps to enable scrolling when a hash is in the URL
      let hash = document.location.hash;
      if (hash != "") {
        console.log(hash);
        setTimeout(function() {
          var hash = window.location.hash;
          window.location.hash = "";
          window.location.hash = hash;
        }, 300);
      }

      // Handles 'focus' state on elements if a user begins navigating via keyboard/mouse:
      // https://medium.com/hackernoon/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
      // • If user starts navigating using the mouse, focus styles will be disable
      // • If user starts navigating page with keyboard, focus styes are applied
      window.addEventListener("keydown", this.handleFirstTab);

      picturefill();
      lazySizes.init();

      // You can also pass an optional settings object
      // below listed default settings
      AOS.init({
        // Global settings:
        disable: !window["MutationObserver"] ? true : false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
        initClassName: "aos-init", // class applied after initialization
        animatedClassName: "aos-animate", // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 120, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 500, // values from 0 to 3000, with step 50ms
        easing: "ease", // default easing for AOS animations
        once: true, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
      });

      // Navigation
      window.addEventListener("resize", this.closeAllMenuItems);
      document.getElementsByTagName("body")[0].classList.remove("display_menu");
      // window.addEventListener("resize", function(event) {
      //     this.closeAllMenuItems();
      //     document.getElementsByTagName("body")[0].classList.remove("display_menu");
      // });
      // var last_scroll = 0;
      // window.onscroll = function() {
      //   if(!document.getElementById("loader")){
      //     this.closeAllMenuItems();
      //     var header = document.getElementsByTagName("header")[0];
      //     if(Math.abs(last_scroll - this.scrollY) <= 5) return;
      //     (this.scrollY < last_scroll) ? header.style.top = "0" : header.style.top = "-" + header.clientHeight + "px" ;
      //     last_scroll = this.scrollY;
      //   }
      // }

      // JumpNav
      let scrollpos = window.scrollY
      const header = document.getElementById("jumpNav")
      const header_height = null
      if (header != null) {
        header_height = header.offsetHeight
      
      
    
          const add_class_on_scroll = () => header.classList.add("fixed-nav")
          const remove_class_on_scroll = () => header.classList.remove("fixed-nav")
        
          window.addEventListener('scroll', function() { 
            scrollpos = window.scrollY;
        
            if (scrollpos >= header_height) { add_class_on_scroll() }
            else { remove_class_on_scroll() }

          })
      }

      // Get Collection
      if (localStorage.getItem("collection")) {
        try {
          this.collection = JSON.parse(localStorage.getItem("collection"));
        } catch (e) {
          localStorage.removeItem("collection");
        }
      }

      // Listen for event to get the current state collection size
      this.$root.$on("getCollectionSize", ($event) => {
        this.collectionSize = this.$root.collection.length;
      });

      // Listen for event to update the current collection size
      this.$root.$on("collection-cleared", ($event) => {
        this.collectionSize = 0;
      });

      // Listen for event to trigger instructions modal
      this.$root.$on("trigger::instructions", ($event) => {
        this.showInstructions = true;
      });

      // Listen for event to close instructions modal
      this.$root.$on("trigger::closeInstructions", ($event) => {
        this.showInstructions = false;
      });

      // Branding... because that's what we do 🚀⚛️
      if (navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
        var t = [
          "\n %c Made with ♥ by Rockit Science Agency %c %c %c https://www.rockitscienceagency.com/ %c %c🚀⚛️ \n\n",
          "color: #7ec156; background: #202c31; padding:5px 0;",
          "background: #494949; padding:5px 0;",
          "background: #494949; padding:5px 0;",
          "color: #fff; background: #1c1c1c; padding:5px 0;",
          "padding:5px 0;",
          "color: #b0976d; padding:5px 0;",
        ];
        window.console.log.apply(console, t);
      } else {
        window.console &&
          window.console.log(
            "Made with love ♥ Rockit Science Agency - https://www.rockitscienceagency.com/ 🚀⚛️"
          );
      }

      this.formEl = this.$refs.form;
      if (this.formEl) {
        this.$recaptchaLoaded().then(() => {
          console.log("recaptcha loaded");
          this.$recaptcha("login").then((token) => {
            this.recaptchaToken = token;
            // console.log(token); // Will print the token
          });
        });
      }
      // this.formEl.addEventListener('submit', this.onSubmit, false);

    },
    computed: {
      isMobile: function() {
        return this.$screen.xs && !this.$screen.md;
      },
      isDesktop: function() {
        return this.$screen.md;
      },
      isLargeScreen: function() {
        return this.$screen.lg;
      },
      isXLScreen: function() {
        return this.$screen.xl;
      },
      headerHeight: function() {
        return this.$refs.header.clientHeight;
      },
    },
    watch: {
      todos: {
        handler() {
          localStorage.setItem("collection", this.collection);
        },
        deep: true,
      },
    },
    methods: {
      // Open nav menu
      display_menu: function() {
        var body = document.getElementsByTagName("body")[0];
        !body.classList.contains("display_menu")
          ? body.classList.add("display_menu")
          : body.classList.remove("display_menu");
      },
      viewImageInModal(image) {
        if (!this.isMobile) {
          this.$modal.show("view-image-modal", { image: image });
        }
      },
      closeImageInModal() {
        this.$modal.hide("view-image-modal");
      },
      beforeImageModalOpen(event) {
        this.overlayImageModalContent = event.params.image;
      },
      display_drop_menu: function() {
        var drop_menu = event.target.parentElement.getElementsByClassName(
          "drop_menu"
        )[0];
        var drop_menus = document.getElementsByClassName("drop_menu");

        Array.from(drop_menus).forEach(function(e) {
          if (e != drop_menu) {
            e.classList.remove("display");
          }
        });
        var lis = document.getElementById("menu").getElementsByTagName("li");
        Array.from(lis).forEach(function(e) {
          e.style.marginTop = 0;
        });
        !drop_menu.classList.contains("display")
          ? drop_menu.classList.add("display")
          : drop_menu.classList.remove("display");
        if (drop_menu.classList.contains("display")) {
          event.target.parentElement.nextSibling.nextSibling.style.marginTop =
            drop_menu.clientHeight + "px";
        }
      },
      loaded: function() {
        document.getElementsByTagName("body")[0].style.overflowY = "hidden";
        console.log("t");
        this.load ? (this.load = false) : (this.load = true);
      },
      closeAllMenuItems: function() {
        document
          .getElementsByTagName("body")[0]
          .classList.remove("display_menu");
        var lis = document.getElementById("menu").getElementsByTagName("li");
        Array.from(lis).forEach(function(e) {
          e.style.marginTop = 0;
        });
        var drop_menus = document.getElementsByClassName("drop_menu");
        Array.from(drop_menus).forEach(function(e) {
          e.classList.remove("display");
        });
      },

      // Apply focus states if user is navigating via keyboard
      handleFirstTab(e) {
        if (e.keyCode === 9) {
          // the "I am a keyboard user" key
          document.body.classList.add("user-is-tabbing");
          window.removeEventListener("keydown", this.handleFirstTab);
          window.addEventListener("mousedown", this.handleMouseDownOnce);
        }
      },

      // Remove focus state when mouse is used for navigating
      handleMouseDownOnce() {
        document.body.classList.remove("user-is-tabbing");

        window.removeEventListener("mousedown", this.handleMouseDownOnce);
        window.addEventListener("keydown", this.handleFirstTab);
      },

      handleScroll: debounce(function(evt, el) {
        if (window.scrollY > this.headerHeight + 150) {
          this.$emit("sticky::affix");
        } else {
          this.$emit("sticky::noaffix");
        }
      }, 50),
      showModal(modalName) {
        this.$modal.show(modalName);
      },
      beforeOpen(event) {
        document
          .getElementsByTagName("html")[0]
          .classList.add("v--modal-block-scroll");
      },
      beforeClose(event) {
        document
          .getElementsByTagName("html")[0]
          .classList.remove("v--modal-block-scroll");
      },
      onSubmit(e) {
        e.preventDefault();
        this.formBtnDisabled = true;
        let form = e.target
        this.$recaptchaLoaded().then(() => {
          console.log("recaptcha loaded");
          this.$recaptcha("login").then((token) => {
            this.recaptchaToken = token;
            console.log(form)
            form.submit();
            // console.log(token); // Will print the token
          });
        });
      }

    },
  });
};

// Loads JS asynchronously
if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", app); // Document still loading so DomContentLoaded can still fire
} else {
  app();
}
// Only show IE error message for versions <= IE10
if (navigator.userAgent.indexOf("MSIE") >= 0) {
  var element = document.getElementById("ie-notice");
  element.classList.add("display-notice");
}
