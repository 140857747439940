<template>
  <div class="flex flex-wrap">
    <template v-if="audience">
      <div class="w-full checkbox-container">
        <h4 class="font-bold text-blue-gray mb-2 text-lg">Audience</h4>
        <checkbox-list 
            attr="audience" 
            :object="audience"
            :active-list-filters="activeListFilters"
        />
      </div>
    </template>
    <template v-if="resourceType">
        <div class="w-full checkbox-container">
            <h4 class="font-bold text-blue-gray mb-2 text-lg">Resource Type</h4>
            <checkbox-list 
                attr="resourceType" 
                :object="resourceType"
                :active-list-filters="activeListFilters"
            />
        </div>
    </template>
    <template v-if="technology">
        <div class="w-full checkbox-container">
            <h4 class="font-bold text-blue-gray mb-2 text-lg">Technology</h4>
            <checkbox-list 
                attr="technology" 
                :object="technology"
                :active-list-filters="activeListFilters"
            />
        </div>
    </template>
    <div class="w-full checkbox-container">
        <template v-if="homeType">
            <h4 class="font-bold text-blue-gray text-lg">Home Type</h4>
            <ais-menu-select attribute="homeType" :transform-items="getHomeType">
                <select
                    slot-scope="{ items, canRefine, refine }"
                    :disabled="!canRefine"
                    @change="handleSelectClick(refine, $event.currentTarget.value)"
                >
                    <option value="">All</option>
                    <option
                        v-for="item in items"
                        :key="item.value"
                        :value="item.value"
                        :selected="item.isRefined"
                    >
                    {{ item.label }}
                    </option>
                </select>
            </ais-menu-select>
        </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResourceSecondaryFilters",
  props: {
    activeListFilters: Array,
    searchClient: Object
  },
  data() {
    return {
      audience: [],
      resourceType: [],
      technology: [],
      homeType: []
    };
  },
  created() {
    this.index = this.searchClient.initIndex("Resources");
    // Get Audience Facet
    this.index
      .searchForFacetValues({
        facetName: "audience",
        facetQuery: "",
        maxValuesPerFacet: 100
      })
      .then(facetHits => {
        this.audience = facetHits.facetHits;
      });
    // Get Resource Type Facet
    this.index
      .searchForFacetValues({
        facetName: "resourceType",
        facetQuery: "",
        maxValuesPerFacet: 100
      })
      .then(facetHits => {
        this.resourceType = facetHits.facetHits;
      });
    // Get Technology Facet
    this.index
      .searchForFacetValues({
        facetName: "technology",
        facetQuery: "",
        maxValuesPerFacet: 100
      })
      .then(facetHits => {
        this.technology = facetHits.facetHits;
      });
    // Get Home Type Facet
    this.index
      .searchForFacetValues({
        facetName: "homeType",
        facetQuery: "",
        maxValuesPerFacet: 100
      })
      .then(facetHits => {
        this.homeType = facetHits.facetHits;
      });
  },
  methods: {
    // Sending static values for Audience to Algolia widget
    getAudience(items) {
      // Need to pull values dynamically
      const audience = this.audience.map(elem => ({
        label: elem.value,
        value: elem.value,
        count: elem.count,
        isRefined: false,
        highlighted: elem.highlighted
      }));
      return audience;
    },
    // Sending static values for Resource Type to Algolia widget
    getResourceType(items) {
      // Need to pull values dynamically
      const resourceType = this.resourceType.map(elem => ({
        label: elem.value,
        value: elem.value,
        count: elem.count,
        isRefined: false,
        highlighted: elem.highlighted
      }));
      return resourceType;
    },
    // Sending static values for Technology to Algolia widget
    getTechnology(items) {
      // Need to pull values dynamically
      const technology = this.technology.map(elem => ({
        label: elem.value,
        value: elem.value,
        count: elem.count,
        isRefined: false,
        highlighted: elem.highlighted
      }));
      return technology;
    },
    // Sending static values for Home Type to Algolia widget
    getHomeType(items) {
      // Need to pull values dynamically
      const homeType = this.homeType.map(elem => ({
        label: elem.value,
        value: elem.value,
        count: elem.count,
        isRefined: false,
        data: null
      }));
      return homeType;
    },
    closeResourceFilter() {
     this.$emit('closeModal');
    },
    handleSelectClick(refine, item) {
        refine(item);
        this.$root.$emit('activeSelectFilterChanged', item);
    }
  }
};
</script>

<style lang="scss">
.checkbox-container {
    @apply border-t py-4 pb-3;
    &:first-child {
        @apply border-t-0 pt-0;
    }
} 
</style>