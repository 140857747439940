<template>
  <div>
    <div class="flex justify-between mb-2">
      <h4 class="text-blue-dark font-bold">Share</h4>
      <button
        type="button"
        class="flex items-center text-sm text-gray-500"
        :class="{
          'text-green-success': copiedStatus,
        }"
        v-clipboard:copy="currentUrl"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        <span v-if="copiedStatus">
          <font-awesome-icon
            class="mr-1"
            icon="check-circle"
          ></font-awesome-icon>
        </span>
        <span>{{ copyLabel }}</span>
      </button>
    </div>
    <div
      class="cursor-pointer p-6 mb-6 text-xs text-gray-500 hover:text-orange-dark transition-250"
      :class="{
        'bg-gray-light': darkenBg,
        'bg-white': !darkenBg,
      }"
      v-if="currentUrl"
      v-clipboard:copy="currentUrl"
      v-clipboard:success="onCopy"
      v-clipboard:error="onError"
    >
      <p class="break-words">
        {{ currentUrl }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShareWidget",
  props: ["currentUrl", "darkenBg"],
  data() {
    return {
      copyLabel: "Copy Link",
      copiedStatus: false,
    };
  },
  methods: {
    onCopy: function(e) {
      this.copiedStatus = true;
      this.copyLabel = "Link Copied";
    },
    onError: function(e) {
      alert("There was an error copying this.");
    },
  },
};
</script>
