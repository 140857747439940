<template>
    <span>
        <button @click="openNewsLetterModal" class="button-primary font-bold text-sm">{{ getButtonLabel }}</button>
        <modal :name="getModalName" :adaptive="true" :scrollable="true" height="auto">
            <span class="block text-center md:text-right cursor-pointer text-blue-dark" @click="closeNewsLetterModal">
                <font-awesome-icon
                    :icon="['fa', 'times-circle']"
                ></font-awesome-icon>
                Close
            </span>
            <div v-html="embed"></div>
        </modal>
    </span>
</template>

<script>
export default {
    name: "NewsletterModal",
    props:['buttonLabel', 'modalName', 'embed'],
    data() {
        return {
            defaultModalName: 'newsletter-body-modal'
        }
    },
    computed: {
        getModalName: function() {
            return this.modalName ? this.modalName : this.defaultModalName;
        },
        getButtonLabel: function() {
            return this.buttonLabel ? this.buttonLabel : 'Sign Up';
        }
    },
    methods: {
        openNewsLetterModal() {
            this.$modal.show(this.getModalName);
        },
        closeNewsLetterModal() {
            this.$modal.hide(this.getModalName);
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v--modal {
            @apply p-6;
        }
    }
</style>