<template>
  <div class="relative">
    <header
      ref="stickyNav"
      class="sticky-nav z-10 hef-primary-gradient-center hef-shadow"
      :style="{ 'height': this.stickyNavHeight + 'px' }"
      :class="{ 'is-fixed come-in': affixStickyNav }"
    >
      <div class="container flex items-center justify-center">
        <nav>
          <ul class="flex items-center justify-center">
            <li
              v-for="item in modifiedNavItems"
              :key="item.label"
              class="md:mx-6 tracking-widest text-xs"
            >
              <button
                class="text-white uppercase"
                v-scroll-to="{ el: '#' + scrollId(item) }"
              >
                {{ item.label }}
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "StickyNav",
  props: {
    navItems: Array
  },
  data() {
    return {
      affixStickyNav: false
    };
  },
  mounted() {
    // These two events are emitted from the root instance
    this.$root.$on("sticky::affix", $event => {
      if (!this.affixStickyNav) {
        this.affixStickyNav = true;
      }
    });
    this.$root.$on("sticky::noaffix", $event => {
      this.affixStickyNav = false;
    });
  },
  computed: {
    // Only return items that are set to 'show'
    modifiedNavItems() {
      return this.navItems.filter(item => item.show);
    },
    stickyNavHeight() {
      return this.$root.stickyNavHeight;
    }
  },
  methods: {
    // Returns the items id or label for keying
    scrollId(item) {
      return item.id ? item.id : item.label;
    }
  }
};
</script>

<style lang="scss">
.sticky-nav {
  @apply absolute;
  &.is-fixed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
  }
  &.come-in {
    transform: translateY(-150px);
    animation: come-in 0.3s ease forwards;
  }
}
@keyframes come-in {
  to {
    transform: translateY(0);
  }
}
</style>
