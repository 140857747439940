var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ais-menu',{attrs:{"attribute":"resourceGroup","transform-items":_vm.getResourceGroup},scopedSlots:_vm._u([{key:"default",fn:function({ items, refine, createURL }){return _c('ul',{staticClass:"flex flex-wrap lg:flex-no-wrap lg:justify-center"},_vm._l((items),function(item,index){return _c('li',{key:item.value,staticClass:"inline-block w-auto lg:px-4",class:{
        'lg:pl-0': index == 0,
        'lg:pr-0': index === items.length,
      }},[_c('a',{staticClass:"block px-2 text-sm text-center border-b-4 border-transparent text-blue-dark hover:text-blue-gray lg:px-4 lg:pb-2 transition-border transition-500",class:[
          _vm.convertToClass(item.label, 'hover:border-'),
          item.label == _vm.activeMenuFilter
            ? _vm.convertToClass(item.label, 'border-')
            : '',
        ],attrs:{"href":createURL(item.value)},on:{"click":function($event){$event.preventDefault();return _vm.handleTypeClick(refine, item)}}},[_vm._v("\n        "+_vm._s(item.label)+"\n      ")])])}),0)}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }