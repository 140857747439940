<template>
    <div>
        <div  class="flex flex-wrap md:-mx-6" v-if="first">
            <div class="md:w-2/3 md:px-6" v-if="caseStudy.caseStudyThumbnail">
                <a :href="'/' + caseStudy.url">
                    <picture>
                        <source :data-srcset="caseStudy.caseStudyThumbnail.webp"
                            data-sizes="100vw"
                            type="image/webp" />
                        <img class="lazyload"
                            :src="caseStudy.caseStudyThumbnail.src"
                            :data-srcset="caseStudy.caseStudyThumbnail.srcset"
                            sizes="100vw" />
                    </picture>
                </a>
            </div>
            <div :class="caseStudy.caseStudyThumbnail ? 'md:w-1/3 md:px-6' : 'md:w-full md:px-6'">
                <p class="text-sm text-blue-light font-medium mt-4">
                    <template v-if="caseStudy.caseStudyLocation">
                        {{ caseStudy.caseStudyLocation }} • 
                    </template>
                    {{ caseStudy.postDate | postDate }}</p>
                <h3 class="leading-none"><a class="text-blue-gray text-xl" :href="caseStudy.url">{{ caseStudy.title }}</a></h3>
                <div class="text-gray-muted" v-html="caseStudy.caseStudyExcerpt"></div>
            </div>
        </div>
        <div  class="flex flex-wrap md:-mx-6" v-else>
            <div class="md:w-full md:px-6">
                <div v-if="caseStudy.caseStudyThumbnail">
                    <a :href="'/' + caseStudy.url">
                        <picture>
                            <source :data-srcset="caseStudy.caseStudyThumbnail.webp"
                                data-sizes="100vw"
                                type="image/webp" />
                            <img class="lazyload"
                                :src="caseStudy.caseStudyThumbnail.src"
                                :data-srcset="caseStudy.caseStudyThumbnail.srcset"
                                sizes="100vw" />
                        </picture>
                    </a>
                </div>
                <p class="text-sm text-blue-light font-medium mt-4">{{ caseStudy.caseStudyLocation }} • {{ caseStudy.postDate | postDate }}</p>
                <h3 class="leading-none"><a class="text-blue-gray text-xl" :href="caseStudy.url">{{ caseStudy.title }}</a></h3>
                <div class="text-gray-muted" v-html="caseStudy.caseStudyExcerpt"></div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: "CaseStudyCard",
    props: ['caseStudy', 'first'],
    data() {
        return {
        }
    },
    filters: {
        postDate: function(data) {
            return moment(data).format('DD MMMM, YYYY');
        }
    },
    methods: {
        
    }
}
</script>