<template>
  <div>

    <!-- Stats -->
    <ais-stats
      :class-names="{
        'ais-Stats-text': 'text-gray-muted'
      }"
    >
      <p
        class="text-blue-dark"
        slot-scope="{
          hitsPerPage,
          nbPages,
          nbHits,
          page,
          processingTimeMS,
          query
        }"
      >
        <template v-if="!query && activeSort.label != 'Distance'">
          All results in <strong>{{ activeSort.label }}</strong>
        </template>
        <template v-else-if="!query && !activeMenuFilter && !activeListFilters.length > 0">
          <strong>{{ nbHits }}</strong> results around
          <em>{{ userZip }}</em>
        </template>
        <template v-else-if="activeMenuFilter || activeListFilters.length > 0">
          <strong>{{ nbHits }}</strong> results found
        </template>
        <template v-else>
          {{ nbHits }} results found for <strong>{{ query }}</strong>
        </template>
      </p>
    </ais-stats>
  </div>
</template>

<script>
export default {
    name: "ProfessionalsStats",
    props: ["query", "activeSort", "activeMenuFilter", "activeListFilters", "userZip"],
}
</script>
