<template>
  <div>
    <ais-instant-search :search-client="algoliaClient" index-name="Utilities">
      <div class="section md:pt-4 bg-white">
        <div class="container">
          <div class="flex justify-center items-center ">
            <div class="w-full md:w-4/5 xl:w-1/2 text-center">
              <h1 v-if="pageTitle">{{ pageTitle }}</h1>
              <!-- Main search field -->
              <ais-search-box
                v-model="query"
                placeholder="Enter a zip code or name"
                :class-names="{
                  'ais-SearchBox': 'flex flex-row justify-center',
                  'ais-SearchBox-form': 'flex flex-row w-full sm:w-1/2',
                  'ais-SearchBox-submit':
                    query.length > 0
                      ? 'hidden -mx-8 text-blue-dark outline-none'
                      : '-mx-8 text-blue-dark outline-none',
                  'ais-SearchBox-reset': 'text-blue-dark outline-none -mx-8',
                  'ais-SearchBox-submitIcon': 'w-4 h-4 fill-current',
                  'ais-SearchBox-resetIcon': 'w-3 h-3 fill-current'
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <section class="py-8">
        <div class="utilities-container">
          <div class="flex flex-wrap xl:-mx-6">
            <!-- Results -->
            <div class="md:px-3 xl:px-6 w-full border-t pt-6">
              
              <!-- Configure the results -->
              <template v-if="query && isNumber">
                <ais-configure 
                  :hits-per-page.camel="10" 
                  :facet-filters.camel="[
                      'serviceAreas:' + query
                  ]"
                />
              </template>
                <template v-else>
                  <ais-configure 
                  :hits-per-page.camel="10" 
                />
              </template>
              
              <!-- Stats -->
              <div class="flex flex-row justify-end -mx-4">
                <div class="w-full md:w-3/5 px-4">
                  <ul class="w-full flex justify-between items-end text-gray-muted mb-6">
                    <li class="inline-block">
                      <ais-stats
                        :class-names="{
                          'ais-Stats-text': 'text-gray-muted'
                        }"
                      >
                        <p
                          class="text-blue-dark"
                          slot-scope="{
                            hitsPerPage,
                            nbPages,
                            nbHits,
                            page,
                            processingTimeMS,
                            query
                          }"
                        >
                          <template v-if="!query">
                            <strong>{{ nbHits }}</strong> total utilities
                          </template>
                          <template v-else-if="query && isNumber">
                            <strong>{{ nbHits }}</strong> utilities around
                            <em>{{ query }}</em>
                          </template>
                          <template v-else>
                            <strong>{{ nbHits }}</strong> utilities for
                            <em>{{ query }}</em>
                          </template>
                        </p>
                      </ais-stats>
                    </li>
                  </ul>
                </div>
              </div>
              
              <ais-infinite-hits>
                <div v-if="items.length > 0" 
                    slot-scope="{ items, isLastPage, refineNext }"
                    class="flex flex-row flex-wrap -mx-4">
                  <div ref="mapContainer" class="w-full mb-8 md:w-2/5 px-4">
                    <affix class="mx-auto lg:mx-0" relative-element-selector="#utility-content" :enabled="affixShouldBeEnabled" :style="affixWidth">
                      <div class="md:h-56 lg:h-85">
                        <google-map 
                          :locations="utilityLocations(items)"
                          :include-info-box="true"
                          :map-options="{
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false
                          }"/>  
                      </div>
                    </affix>
                  </div>
                  <div class="w-full md:w-3/5 px-4" id="utility-content">
                    <ul
                      class="flex flex-wrap"
                    >
                      <li
                        data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom"
                        class="w-full mb-8"
                        v-for="entry in items"
                        :key="entry.objectID"
                      >
                        <div
                          class="flex flex-wrap w-full p-8 bg-white shadow-md border-b-4 border-blue-light"
                        >
                          <div class="w-full md:w-3/5 border-b pb-6 mb-6 md:mb-0 md:pb-0 md:pr-6 md:border-b-0 md:border-r border-gray-100">
                            <h3 class="text-xl font-bold text-blue-gray mb-3 lg:mb-4 leading-tight">
                              {{ entry.title }}
                            </h3>
                            <address v-if="entry.utilityAddress">
                                <span v-if="entry.utilityAddress[0].street1" v-html="entry.utilityAddress[0].street1"></span>
                                <span v-if="entry.utilityAddress[0].street2" v-html="entry.utilityAddress[0].street2"></span></br>
                                <span v-if="entry.utilityAddress[0].city" v-html="entry.utilityAddress[0].city"></span>,&nbsp; 
                                <span v-if="entry.utilityAddress[0].state" v-html="entry.utilityAddress[0].state"></span>&nbsp;
                                <span v-if="entry.utilityAddress[0].zip" v-html="entry.utilityAddress[0].zip"></span>
                            </address>
                          </div>
                          <div class="w-full flex md:justify-center md:w-2/5 pb-6 mb-6 md:mb-0 md:pb-0 border-gray-100"
                            :class="!entry.email || !entry.phone ? 'md:px-6' : ''"
                          >
                            <div>
                              <p class="mb-3 text-sm text-blue-gray font-bold">
                                Information
                              </p>
                              <ul class="flex flex-col">
                                <li
                                  class="text-sm inline-block align-middle mb-2 whitespace-no-wrap"
                                >
                                  <a
                                    :href="
                                      entry.email ? `mailto:${entry.email}` : null
                                    "
                                    :class="{
                                      'cursor-not-allowed text-gray-muted italic': !entry.email,
                                      'text-orange-bright': entry.email
                                    }"
                                  >
                                    <font-awesome-icon
                                      :icon="['fas', 'envelope']"
                                      class="text-blue-light"
                                    ></font-awesome-icon
                                    >&nbsp;
                                    {{ entry.email ? "Email" : "Not Available" }}
                                  </a>
                                </li>
                                <li
                                  class="text-sm inline-block align-middle whitespace-no-wrap mb-3 lg:mb-6"
                                >
                                  <font-awesome-icon
                                    :icon="['fas', 'phone-alt']"
                                    class="text-blue-light"
                                  ></font-awesome-icon
                                  >&nbsp;
                                  <a
                                    v-if="entry.phone && entry.phone != '--'"
                                    :href="`tel:${entry.phone}`"
                                    v-html="entry.phone"
                                    class="text-gray-muted"
                                  ></a>
                                  <p class="text-gray-muted italic inline" v-else>
                                    Not Available
                                  </p>
                                </li>
                                <li>
                                  <a
                                    
                                    @click="gtmTracking(entry.title)"
                                    :href="entry.website"
                                    target="_blank"
                                    class="text-white text-xs py-2 px-3 rounded-full"
                                    :class="{
                                      'button-primary':
                                        entry.website,
                                      'bg-gray-400 cursor-not-allowed': !entry.website
                                    }"
                                  >
                                    Visit Website
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>

                      <!-- Load more button -->
                      <li class="w-full text-center py-8" v-if="!isLastPage">
                        <button
                          @click="refineNext"
                          class="button-primary"
                        >
                          Show more
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Response for 0 results -->
                <div v-else>
                  <p class="mt-16 text-2xl text-gray-muted text-center">
                    <template v-if="query">
                      There are no utilities found for
                      <q>{{ query }}</q>
                    </template>
                    <template v-else>
                      No utilities found.
                    </template>
                  </p>
                </div>
              </ais-infinite-hits>
            </div>
          </div>
        </div>
      </section>
    </ais-instant-search>
  </div>
</template>

<script>
import ProfessionalsStats from "./ProfessionalsStats";
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/reset.css";

export default {
  name: "UtilitiesListing",
  props: ["pageTitle"],
  props: {
    pageTitle: {
      type: String,
      default: ''
    },
    pageDesc: {
      type: String,
      default: ''
    },
    userLatLng: {
        type: String,
        default: '',
    },
    userZip: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      query: "",
      index: "",
      radius: "",
      affixWidth: null,
      algoliaClient: algoliasearch(
        "68G5M92M1B",
        "c24543a92b3e155b20a0b69f1ca4b554"
      )
    };
  },
  mounted() {
    this.calculateAffixWidth();
    window.addEventListener("resize", this.calculateAffixWidth);
  },
  computed: {
    isNumber: function() {
      // '.test' method tests for a pattern in a string (numbers in this case)
      // syntax: RegExpObject.test(string)
      return /^\d+$/.test(this.query);
    },
    affixShouldBeEnabled() {
      return !this.$root.isMobile ? true : false;
    },
  },
  methods: {
    gtmTracking: function(label) {
      this.$gtm.trackEvent({
        event: 'utility-referral', // Event type [default = 'interaction'] (Optional)
        category: 'Utility Referral',
        action: 'Referral',
        label: label,
        title: label,
        value: 1,
        noninteraction: false // Optional
      });
    },
    utilityLocations(utilities) {
      let locations = []
      utilities.forEach(utility => {
        let latLng = utility._geoloc;
        locations.push({
          title: utility.title,
          phone: utility.phone,
          website: utility.website,
          address: utility.utilityAddress[0],
          lat: latLng.lat,
          lng: latLng.lng
        })
      });
      return locations;
    },
  calculateAffixWidth() {
    const initialAffixWidth = 0.335 * window.innerWidth;
    this.affixWidth = 'width:' + (initialAffixWidth <= 420 ? initialAffixWidth : 465.65) + 'px';
    if (this.$root.isMobile) {
      this.affixWidth = 'width: 100%';
    }
  }
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  [v-cloak] {
    display: none;
  }

  .utilities-container {
    @apply px-8;
    @screen lg {
      @apply max-w-7xl mx-auto;
    }
  }
  .ais-SearchBox-input {
    @apply bg-gray-light appearance-none rounded-full w-full py-3 px-6 text-gray-700 leading-tight;
    &::placeholder {
      @apply text-blue-light;
    }
  }
}
</style>
