<template>
  <div class="text-center text-3xl lg:text-4xl xl:text-5xl">
    <div class="flex flex-wrap justify-center justify-around">
      <div class="content-wrapper">
        <p class="digit">{{ days | two_digits }}</p>
        <p class="text">Days</p>
      </div>
      <div class="content-wrapper">
        <p class="digit">{{ hours | two_digits }}</p>
        <p class="text">Hrs</p>
      </div>
      <div class="content-wrapper">
        <p class="digit">{{ minutes | two_digits }}</p>
        <p class="text">Min</p>
      </div>
      <div class="content-wrapper">
        <p class="digit">{{ seconds | two_digits }}</p>
        <p class="text">Sec</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CountdownTimer",
  props: ["date"],
  filters: {
    /*
      When the computed values are less than 10, 
      we don't want to see single digit values in the output.
    */
    two_digits(value) {
      if (value.toString().length <= 1) {
        return "0" + value.toString();
      }
      return value.toString();
    }
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000)
    };
  },
  mounted() {
    /*
      The now property of data gets updated every second
      with new value of the current date(in seconds).
     */
    window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    });
  },
  computed: {

    modifiedDate() {
      return Math.trunc(Date.parse(this.date) / 1000);
    },

    seconds() {
      return (this.modifiedDate - this.now) % 60;
    },

    minutes() {
      return Math.trunc((this.modifiedDate - this.now) / 60) % 60;
    },

    hours() {
      return Math.trunc((this.modifiedDate - this.now) / 60 / 60) % 24;
    },

    days() {
      return Math.trunc((this.modifiedDate - this.now) / 60 / 60 / 24);
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
::v-deep {
  .content-wrapper {
    @apply leading-tight relative flex-grow;
    &:after {
      @apply absolute bg-white block opacity-50;
      content: "";
      height: 1rem;
      width: 1px;
      right: 0;
      top: 25%;
    }
    &:last-child {
      &:after {
        height: 0;
        width: 0;
      }
    }
    .text {
      @apply m-0 block font-normal tracking-widest uppercase text-xs;
      @screen lg {
        @apply text-sm;
      }
    }
  }
}
</style>
