<template>
    <div>
        <ais-instant-search :search-client="algoliaClient" index-name="CaseStudies">
            <div class="pt-6 bg-white">
                <div class="container">
                    <div class="hidden mb-8 md:block">
                        <ais-menu attribute="caseStudyLocation">
                            
                            <ul
                                class="flex flex-row flex-wrap justify-center w-full mx-auto"
                                slot-scope="{
                                items,
                                canToggleShowMore,
                                isShowingMore,
                                toggleShowMore,
                                refine,
                                createURL,
                                }">
                                <li class="mx-1 mb-6 lg:mx-3">
                                    <ais-clear-refinements>
                                        <div slot-scope="{ canRefine, refine, createURL }">
                                            <button
                                                class="px-5 py-3 text-sm tracking-wider uppercase transition-all rounded-full text-blue-dark transition-500 hover:text-blue-dark hover:shadow-button"
                                                :class="canRefine ? 'bg-gray-lightest' : 'bg-white shadow-button'"
                                                :href="createURL()"
                                                @click.prevent="refine"
                                                >
                                                All
                                            </button>
                                        </div>
                                    </ais-clear-refinements>
                                </li> 
                                <li class="mx-1 mb-6 lg:mx-3" v-for="item in items" :key="item.value">
                                    <button
                                        class="px-5 py-3 text-sm tracking-wider uppercase transition-all rounded-full text-blue-dark transition-500 hover:text-blue-dark hover:shadow-button"
                                        :class="item.isRefined ? 'bg-white shadow-button' : 'bg-gray-lightest'"
                                        :href="createURL(item.value)"
                                        @click.prevent="refine(item.value)"
                                    >
                                        {{item.label}}
                                    </button>
                                </li>
                            </ul>
                        </ais-menu>
                    </div>
                    <div class="block mb-16 md:hidden">
                        <p class="mb-3 text-xl font-bold text-center text-blue-dark">Choose a Location</p>
                        <ais-menu-dropdown 
                            :aisMenuOptions="locations"
                            menuAttribute="caseStudyLocation">
                        </ais-menu-dropdown>
                    </div>
                    <ais-configure
                        :hits-per-page.camel="6"
                    />
                    <ais-infinite-hits>
                        <template slot-scope="{ items, isLastPage, refineNext }">
                            <ul class="flex flex-wrap md:-mx-6" v-if="items.length" >
                                <li 
                                    class="w-full mb-12 md:px-6"
                                    :class="index  === 0 ? 'w-full' : 'md:w-1/2'"
                                    v-for="(entry, index) in items"
                                    :key="entry.objectID"
                                >
                                   
                                    <!-- <resource-card :resource="entry"></resource-card> -->
                                    <case-study-card :first="index === 0 ? true : false" :caseStudy="entry"></case-study-card>
                                </li>
                            </ul>
                            <div v-else>
                                <p class="text-2xl text-center text-gray-600">There are no case studies at this time</p>
                            </div>
                            <!-- <div class="pagination"><ais-pagination /></div> -->
                            <!-- Load more button -->
                            <div class="flex items-center justify-center py-8">
                                <button
                                    v-if="!isLastPage"
                                    @click="refineNext"
                                    class="px-8 py-2 text-white rounded-full bg-orange-bright hover:bg-orange-dark transition-bg transition-500"
                                >
                                    Show more
                                </button>
                            </div>
                        </template>
                    </ais-infinite-hits>
                </div>
            </div>
        </ais-instant-search>
    </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/reset.css";
import AisMenuDropdown from "./AisMenuDropdown";
import CaseStudyCard from "./CaseStudyCard";

export default {
    name: "CaseStudiesListing",
    data() {
        return {
            index: "",
            locations: [],
            class: 'w-full',
            count: 0,
            caseStudyLength: '',
            algoliaClient: algoliasearch(
                "68G5M92M1B",
                "c24543a92b3e155b20a0b69f1ca4b554"
            )
        };
    },
    mounted() {
         // set index
        this.index = this.algoliaClient.initIndex('CaseStudies');

        this.index.search({
            query: '',
             hitsPerPage: 1000,
        }).then(res => {
            this.caseStudyLength = res.hits;
        });

        // Get Location Facet
        this.index.searchForFacetValues({
            facetName: 'caseStudyLocation',
            facetQuery: ''
        }).then(facetHits=>{
            this.locations = facetHits.facetHits;
        });
    }
};
</script>