<template>
  <div>
    <ais-refinement-list :attribute="attr" :transform-items="transformItems">
      <div slot-scope="{ items, refine, createURL }">
        <ul class="flex flex-wrap">
          <li
            v-for="item in items"
            :key="item.value"
            class="text-sm mb-4 w-full sm:w-1/2 checkbox-wrapper"
          >
            <input
              type="checkbox"
              class="opacity-0 absolute left-0"
              :checked="activeListFilters.includes(item.value) ? true : false"
              :value="item.value"
            />
            <span
              class="checkbox-custom"
              @click="handleListClick(refine, item)"
            ></span>
            <a
              class="checkbox-label leading-none ml-6 sm:ml-8 block text-gray-muted"
              :href="createURL(item)"
              @click.prevent="handleListClick(refine, item)"
              :class="activeListFilters.includes(item.value) ? 'font-bold' : ''"
            >
              {{ item.value }}
            </a>
          </li>
        </ul>
      </div>
    </ais-refinement-list>
  </div>
</template>

<script>
export default {
  name: "CheckboxList",
  props: ["attr", "object", "activeListFilters"],
  methods: {
    transformItems(items) {
      // Need to pull values dynamically
      const data = this.object.map(elem => ({
        label: elem.value,
        value: elem.value,
        count: elem.count,
        isRefined: false,
        highlighted: elem.highlighted
      }));
      return data;
    },
    // Track the active certifications
    handleListClick(refine, item) {
      // Init the internal ais refinement method
      refine(item.value);

      this.$root.$emit("activeHandleListChanged", item);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .checkbox-wrapper {
    display: block;
    position: relative;
    cursor: pointer;
    clear: both;
    .checkbox-label {
      @screen sm {
        @apply text-lg;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .checkbox-custom {
      position: absolute;
      top: 0;
      left: 0;
      background-color: transparent;
      border-radius: 2px;
      transition: all 0.3s ease-out;
      background: #e9eff4;
      height: 15px;
      width: 15px;
      @screen sm {
        height: 20px;
        width: 20px;
      }
    top: -0.1rem;
      &:after {
        position: absolute;
        content: "";
      }
    }
    input:checked ~ .checkbox-custom {
      display: inline-block;
      @apply bg-orange-bright;
      height: 15px;
      width: 15px;
      @screen sm {
        height: 20px;
        width: 20px;
      }
      border-radius: 2px;
      transform: rotate(0deg) scale(1);
      opacity: 1;
    }
    input:checked ~ .checkbox-custom:after {
      -webkit-transform: rotate(45deg) scale(1);
      -ms-transform: rotate(45deg) scale(1);
      transform: rotate(45deg) scale(1);
      opacity: 1;
      left: 6px;
      top: 2px;
      width: 5px;
      height: 8px;
      @screen sm {
        width: 8px;
        height: 12px;
      }
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      background-color: transparent;
      border-radius: 0;
    }
  }
}
</style>
