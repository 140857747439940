<template>
  <div>
    <ais-instant-search :search-client="algoliaClient" index-name="News">
      <div class="pb-8 md:pb-16 bg-white">
        <div class="container">
          <div class="flex justify-center items-center ">
            <div class="w-full md:w-4/5 xl:w-1/2 text-center">
              <h1 v-if="pageTitle">{{ pageTitle }}</h1>
              <p
                class="text-xl text-gray-muted text-center"
                v-html="pageDesc"
              ></p>
            </div>
          </div>
        </div>
      </div>
      <section class="py-8">
        <div class="professionals-container">
          <div class="flex flex-wrap xl:-mx-6">
            <!-- Filters -->
            <transition name="fade" v-cloak>
              <div
                v-show="mobileFilterOpen || isDesktop"
                class="fixed inset-0 z-50 w-full lg:block lg:relative lg:w-1/4 md:px-6 lg:border-t"
                :class="[mobileFilterOpen ? 'block' : 'hidden']"
              >
                <aside-filter
                  :menu-list="newsTypes"
                  menu-list-attr="newsType"
                  menu-list-label="Types"
                  :refinement-list="professions"
                  refinement-list-attr="professionalType.name"
                  refinement-list-label="Profession"
                  :activeMenuFilter="activeMenuFilter"
                  :activeListFilters="activeListFilters"
                  :isDesktop="isDesktop"
                  @menuUpdated="handleMenuClick"
                  @listUpdated="handleListClick"
                  @filtersCleared="handleClearEvent"
                  @closeFilterMenu="handleFilterMenuClick"
                />
              </div>
            </transition>

            <!-- Results -->
            <div class="md:px-6 w-full lg:w-3/4 border-t">
              <ul
                class="flex justify-between items-end text-gray-muted mb-6"
              >
                <!-- Mobile filter button toggle -->
                <li class="inline-block" v-if="isMobile">
                  <button
                    @click="mobileFilterOpen = !mobileFilterOpen"
                    class="text-blue-dark py-1 px-5 rounded-full border border-gray-500"
                  >
                    <span>Filters {{ totalFilters }}</span>
                  </button>
                </li>
              </ul>

              <!-- Configure the results -->
              <ais-configure :hits-per-page.camel="10" />
              <h2 class="px-3 text-2xl hidden md:block">Latest News</h2>
              <!-- Output the results -->
              <ais-infinite-hits>
                <ul
                  v-if="items.length > 0"
                  slot-scope="{ items, isLastPage, refineNext }"
                  class="flex flex-row flex-wrap"
                >
                  <li
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                    class="w-full md:px-3 mb-6"
                    v-for="(entry, index) in items"
                    :class="[index % 3 === 0 ? 'w-full' : 'md:w-1/2']"
                    :key="entry.objectID"
                  >
                    <a
                      :href="'/' + entry.path"
                      class="card block bg-white h-full shadow-md w-full mb-6 md:mb-0 p-8 lg:p-10 border-b-4 border-orange-dark"
                    >
                      <div :class="{ 'mb-16': entry.newsType }">
                        <ul v-if="entry.professionalType">
                          <li
                            class="text-xs font-bold leading uppercase text-blue-light tracking-widest"
                          >
                            <template
                              v-for="(profession, index) in entry.professions"
                            >
                              <span v-if="index != 0" :key="'comma ' + index"
                                >,&nbsp;</span
                              >
                              <span :key="index">{{ profession.name }}</span>
                            </template>
                          </li>
                        </ul>
                        <p class="text-blue-light text-sm font-bold">
                          {{ entry.postDate | format }}
                        </p>
                        <h3 class="text-xl font-bold text-blue-gray mb-3 lg:mb-4 leading-tight">
                          {{ entry.title }}
                        </h3>
                        <p class="text-gray-muted" v-if="entry.description">
                          {{ entry.description | truncate(200, "…") }}
                        </p>
                      </div>
                      <p v-if="entry.newsType" class="absolute bottom-0 left-0 px-10 mb-10 uppercase text-orange-dark font-bold text-sm tracking-widest">
                        {{ entry.newsType }}
                      </p>
                    </a>
                  </li>
                  
                  <!-- Load more button -->
                  <li class="w-full text-center" v-if="!isLastPage">
                    <button @click="refineNext" class="button-primary">
                      Show more
                    </button>
                  </li>

                </ul>

                <!-- Response for 0 results -->
                <div v-else>
                  <p class="mt-16 text-2xl text-gray-muted text-center">
                    <template>
                      No articles found. Please remove some filters for better
                      results.
                    </template>
                  </p>
                </div>
              </ais-infinite-hits>
            </div>
          </div>
        </div>
      </section>
    </ais-instant-search>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/reset.css";
import moment from "moment";

export default {
  name: "NewsListing",
  props: ["pageTitle", "pageDesc"],
  data() {
    return {
      index: "",
      activeMenuFilter: "",
      activeListFilters: [],
      professions: [],
      newsTypes: [],
      mobileFilterOpen: false,
      isOpen: false,
      orderEventFired: false,
      algoliaClient: algoliasearch(
        "68G5M92M1B",
        "c24543a92b3e155b20a0b69f1ca4b554"
      ),
      baseURL: '/api'
    };
  },
  mounted() {
    // Init the index to search algolia
    this.index = this.algoliaClient.initIndex("News");

    // Get the professionals facets for filtering
    this.index
      .searchForFacetValues({
        facetName: "professionalType.name",
        facetQuery: ""
      })
      .then(res => {
        const professions = res.facetHits;
        professions.forEach(el => {
          el.checked = false;
        });
        this.professions = professions;
      });

    this.index.searchForFacetValues({
        facetName: "newsType",
        facetQuery: ""
      })
      .then(res => {
        const newsTypes = res.facetHits;
        newsTypes.forEach(el => {
          el.checked = false;
        });
        this.newsTypes = newsTypes;
      });
  },
  filters: {
    truncate: function(text, length, clamp) {
      clamp = clamp || "...";
      var node = document.createElement("div");
      node.innerHTML = text;
      var content = node.textContent;
      return content.length > length
        ? content.slice(0, length) + clamp
        : content;
    },
    format: function(data) {
      return moment(data).format("DD MMMM, YYYY");
    }
  },
  // Handle some state
  computed: {
    filterButtonState: function() {
      return this.activeMenuFilter || this.activeListFilters.length > 0 ? true : false
    },
    isMobile: function() {
      return this.$screen.xs && !this.$screen.lg;
    },
    isDesktop: function() {
      this.mobileFilterOpen = false;
      return this.$screen.lg;
    },
    totalFilters: function() {
      let totalFilters = [...this.activeListFilters];
      if (this.activeMenuFilter.length) {
        totalFilters.push(this.activeMenuFilters);
      }
      return totalFilters.length > 0 ? "• " + totalFilters.length : "";
    }
  },

  methods: {
    // Track the active professions
    handleMenuClick(item) {
      // Add item to array, if not already there
      this.query = "";
      this.activeMenuFilter = item.value == this.activeMenuFilters ? "" : item.value;
    },

      // Track the active certifications
    handleListClick(item) {
      // Add/remove filters from array
      this.query = "";
      const activeFilter = this.activeListFilters.indexOf(item.value);
      if (activeFilter == -1) {
        this.activeListFilters.push(item.value);
      } else if (activeFilter > -1) {
        this.activeListFilters.splice(activeFilter, 1);
      }
    },

    // Clear out filters on user click
    handleClearEvent() {
      this.activeListFilters = [];
      this.activeMenuFilter = "";
    },

    // Close the filter menu when clicked
    handleFilterMenuClick() {
      this.mobileFilterOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  [v-cloak] {
    display: none;
  }

  .card {
    @apply relative transition-500;
    &:hover {
      @apply shadow-xl;
    }
  }

  .professionals-container {
    @apply px-8;
    @screen lg {
      @apply max-w-7xl mx-auto;
    }
  }

  .ais-SearchBox-input {
    @apply bg-gray-light appearance-none rounded-full w-full py-3 px-6 text-gray-700 leading-tight;
    &::placeholder {
      @apply text-blue-light;
    }
  }
}
</style>
