var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ais-stats',{attrs:{"class-names":{
      'ais-Stats-text': 'text-gray-muted'
    }},scopedSlots:_vm._u([{key:"default",fn:function({
        hitsPerPage,
        nbPages,
        nbHits,
        page,
        processingTimeMS,
        query
      }){return _c('p',{staticClass:"text-blue-dark"},[(!query && _vm.activeSort.label != 'Distance')?[_vm._v("\n        All results in "),_c('strong',[_vm._v(_vm._s(_vm.activeSort.label))])]:(!query && !_vm.activeMenuFilter && !_vm.activeListFilters.length > 0)?[_c('strong',[_vm._v(_vm._s(nbHits))]),_vm._v(" results around\n        "),_c('em',[_vm._v(_vm._s(_vm.userZip))])]:(_vm.activeMenuFilter || _vm.activeListFilters.length > 0)?[_c('strong',[_vm._v(_vm._s(nbHits))]),_vm._v(" results found\n      ")]:[_vm._v("\n        "+_vm._s(nbHits)+" results found for "),_c('strong',[_vm._v(_vm._s(query))])]],2)}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }