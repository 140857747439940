<template>
  <div>
    <ais-instant-search
      :search-client="algoliaClient"
      index-name="Professionals"
    >
      <ProfessionalsSearchBox :page-title="pageTitle" />
      <section class="py-8">
        <div class="professionals-container">
          <!-- Configure the results -->
          <template v-if="!query && activeSort.label == 'Distance'">
            <!-- 
                When you use aroundLatLng with the *default precision* (or a small precision), 
                it will order the results nearest to farthest from the point entered (users lat/lng). 
                This reason is why we don't set a precision/radius.
              -->
            <ais-configure
              :get-ranking-info.camel="true"
              :around-lat-lng.camel="this.userLatLng"
              :around-radius.camel="radius"
              :hits-per-page.camel="1000"
            />
          </template>
          <template v-else>
            <!-- 
                When a query is initiated or sorting is by title_desc or title_asc
                this query is hit, which fetches ALL results - just sorted respectively
              -->
            <ais-configure :getRankingInfo="true" :hits-per-page.camel="10" />
          </template>

          <div
            v-show="modalIsOpen"
            class="fixed inset-0 z-20 lg:block lg:relative"
          >
            <transition name="modal" key="state-transition" tag="div">
              <!-- The modal overlay that appears when modal is open -->
              <div class="modal-backdrop" v-show="modalIsOpen">
                <!-- 
                  This is the secondary filter modal 
                  which turns into mobile filter UI for
                  all filters.
                -->
                <div
                  class="modal modal-container"
                  v-on-clickaway="away"
                  style="positionModal"
                >
                  <aside-filter
                    :query="query"
                    :menu-list="professions"
                    menu-list-attr="professionalType.name"
                    menu-list-label="Professional Type"
                    :refinement-list="certifications"
                    refinement-list-attr="certifications.name"
                    refinement-list-label="Programs"
                    :activeMenuFilter="activeMenuFilter"
                    :activeListFilters="activeListFilters"
                    :isDesktop="isDesktop"
                    :show-suggest-edit-button="false"
                    @menuUpdated="handleMenuClick"
                    @listUpdated="handleListClick"
                    @filtersCleared="handleClearEvent"
                    @closeFilterMenu="handleFilterMenuClick"
                  />
                </div>
              </div>
            </transition>
          </div>

          <!-- <ProfessionalsItems :query="query" :active-sort="activeSort" /> -->
          <ais-infinite-hits :transform-items="transformItems">
            <div
              class="flex flex-wrap xl:-mx-6"
              v-if="items.length > 0"
              slot-scope="{ items, isLastPage, refineNext }"
            >
              <div class="w-full lg:px-3 xl:px-6">
                <ul
                  class="flex justify-between items-end text-gray-muted pb-6 border-gray-400 border-b"
                >
                  <!-- Stats -->
                  <li class="inline-block">
                    <div class="flex -mx-3 items-center">
                      <div
                        class="px-3 px-3 border-gray-500 border-r"
                        v-if="isDesktop"
                      >
                        <professionals-stats
                          :query="query"
                          :activeSort="activeSort"
                          :activeMenuFilter="activeMenuFilter"
                          :activeListFilters="activeListFilters"
                          :userZip="userZip"
                        />
                      </div>
                      <div class="px-3">
                        <button
                          @click="modalIsOpen = !modalIsOpen"
                          class="bg-white inline-flex items-center px-6 py-2 font-semibold text-blue-gray rounded-lg focus:outline-none shadow-md hover:shadow-lg transition-all"
                        >
                          <span
                            class="flex flex-no-wrap items-center leading-loose uppercase text-sm font-semibold tracking-wide"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'filter']"
                              class="mr-1 text-blue-light"
                            ></font-awesome-icon>
                            Filters {{ totalFilters }}
                          </span>
                        </button>
                      </div>
                    </div>
                  </li>

                  <!-- Mobile filter button toggle -->
                  <li class="flex">
                    <!-- Sort Dropdown -->
                    <sort-dropdown
                      :ais-sort-options="aisSortOptions"
                      :add-sort-options="addSortOptions"
                      :start-active-sort="activeSort"
                      :hide-label-on-mobile="true"
                    >
                    </sort-dropdown>
                  </li>
                </ul>
              </div>
              <transition name="fade" v-cloak>
                <div
                  class="w-full pt-0 md:w-2/5 lg:pt-6 lg:px-3 xl:px-6 lg:border-t"
                >
                  <div ref="mapContainer">
                    <affix
                      class="mx-auto lg:mx-0"
                      relative-element-selector="#professionals-content"
                      :enabled="affixShouldBeEnabled"
                      :style="affixWidth"
                    >
                      <div class="md:h-56 lg:h-85">
                        <google-map
                          v-if="activeHits.length > 0"
                          :locations="placeHitsOnMap(items)"
                          :include-info-box="true"
                          :map-options="{
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                          }"
                        />
                      </div>
                    </affix>
                  </div>
                </div>
              </transition>
              <div
                class="md:px-3 xl:px-6 w-full md:w-3/5 border-t pt-6"
                id="professionals-content"
              >
                <ul>
                  <li
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                    class="w-full p-8 lg:p-10 mb-6 bg-white shadow-md flex flex-wrap border-b-4 border-blue-light"
                    v-for="entry in items"
                    :key="entry.objectID"
                  >
                    <div
                      class="w-full md:w-3/5 border-b pb-6 mb-6 md:mb-0 md:pb-0 md:pr-6 md:border-b-0 md:border-r border-gray-100"
                    >
                      <div class="mb-2">
                        <ul v-if="entry.professionalType">
                          <li
                            class="text-xs font-bold leading uppercase text-blue-light tracking-widest"
                          >
                            <template
                              v-for="(profession,
                              index) in entry.professionalType"
                            >
                              <span v-if="index != 0" :key="'comma ' + index"
                                >,&nbsp;</span
                              >
                              <span :key="index">{{ profession.name }}</span>
                            </template>
                          </li>
                        </ul>
                        <h3
                          class="text-xl font-bold text-blue-gray mb-3 lg:mb-4 leading-tight"
                        >
                          {{ entry.title }}
                        </h3>
                        <template
                          v-if="!query && entry._rankingInfo.matchedGeoLocation"
                        >
                          <p
                            class="text-sm inline-block align-middle font-bold text-gray-700"
                          >
                            <font-awesome-icon
                              class="text-blue-light text-base"
                              :icon="['fas', 'map-marker-alt']"
                            ></font-awesome-icon>
                            <span>
                              {{
                                distanceFromUser(
                                  entry._rankingInfo.matchedGeoLocation.distance
                                )
                              }}
                            </span>
                          </p>
                        </template>
                        <template v-if="entry.address">
                          <span class="flex">
                            <font-awesome-icon
                              v-if="!entry._rankingInfo.matchedGeoLocation"
                              class="text-blue-light"
                              :icon="['fas', 'map-marker-alt']"
                            ></font-awesome-icon>
                            <p
                              class="text-sm text-gray-muted italic leading-snug"
                              :class="
                                activeSort.label == 'Distance' && !query
                                  ? 'ml-4'
                                  : 'ml-1'
                              "
                              v-html="entry.address"
                            ></p>
                          </span>
                        </template>
                      </div>
                      <div
                        class="w-full flex border-b pb-6 mb-6 md:mb-0 md:pb-0 md:border-b-0 border-gray-100"
                      >
                        <div>
                          <ul class="flex flex-col">
                            <li
                              class="text-sm inline-block align-middle mb-2 whitespace-no-wrap"
                            >
                              <a
                                :href="
                                  entry.email ? `mailto:${entry.email}` : null
                                "
                                :class="{
                                  'cursor-not-allowed text-gray-muted italic': !entry.email,
                                  'text-orange-dark': entry.email,
                                }"
                              >
                                <font-awesome-icon
                                  :icon="['fas', 'envelope']"
                                  class="text-blue-light"
                                ></font-awesome-icon
                                >&nbsp;
                                {{ entry.email ? "Email" : "Not Available" }}
                              </a>
                            </li>
                            <li
                              class="text-sm inline-block align-middle whitespace-no-wrap mb-3 lg:mb-6"
                            >
                              <font-awesome-icon
                                :icon="['fas', 'phone-alt']"
                                class="text-blue-light"
                              ></font-awesome-icon
                              >&nbsp;
                              <a
                                v-if="entry.phone"
                                :href="`tel:${entry.phone}`"
                                v-html="entry.phone"
                                class="text-gray-muted"
                              ></a>
                              <p class="text-gray-muted italic inline" v-else>
                                Not Available
                              </p>
                            </li>
                            <li>
                              <a
                                @click="gtmTracking(entry.title)"
                                :href="entry.website"
                                target="_blank"
                                class="button-primary button-sm"
                                :class="{
                                  disabled: !entry.website,
                                }"
                              >
                                Visit Website
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="w-full md:w-2/5 md:pl-6">
                      <p class="mb-3 text-sm text-blue-gray font-bold">
                        Certifications
                      </p>
                      <ul class="flex flex-col">
                        <template v-if="entry.certifications.length != 0">
                          <li
                            class="relative text-xs text-gray-muted leading-none mb-3"
                            v-for="(cert, index) in entry.certifications"
                            :key="index"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'check']"
                              class="text-orange-bright absolute left-0"
                            ></font-awesome-icon>
                            <span class="block ml-4">{{ cert.name }}</span>
                          </li>
                        </template>
                        <template v-else>
                          <li class="text-xs text-gray-muted italic">
                            This professional doesn't have any certifications
                            listed.
                          </li>
                        </template>
                      </ul>
                    </div>
                  </li>

                  <!-- Load more button -->
                  <li
                    class="text-center"
                    v-if="activeSort.label != 'Distance' && !isLastPage"
                  >
                    <button @click="refineNext" class="button-primary">
                      Show more
                    </button>
                  </li>
                  <li
                    class="text-center"
                    v-if="
                      activeSort.label == 'Distance' &&
                        activeHits.length < allItems.length
                    "
                  >
                    <button @click="fakePaginate(items)" class="button-primary">
                      Show more
                    </button>
                  </li>
                </ul>

                <!-- Response for 0 results -->
                <div v-if="items.length == 0">
                  <p class="mt-16 text-2xl text-gray-muted text-center">
                    <template v-if="query">
                      There are no professionals found for
                      <q>{{ query ? query : userZip }}</q>
                    </template>
                    <template v-else>
                      No professionals found. Please remove some filters for
                      better results.
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </ais-infinite-hits>
        </div>
      </section>
    </ais-instant-search>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/reset.css";
import SortDropdown from "./SortDropdown";
import ProfessionalsStats from "./ProfessionalsStats";
import ProfessionalsItems from "./ProfessionalsItems";
import ProfessionalsSearchBox from "./ProfessionalsSearchBox";
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "ProfessionalsListing",
  props: {
    pageTitle: {
      type: String,
      default: "",
    },
    userLat: {
      type: String,
      default: "",
    },
    userLng: {
      type: String,
      default: "",
    },
    userZip: {
      type: String,
      default: "",
    },
  },
  mixins: [clickaway],
  data() {
    return {
      query: "",
      index: "",
      radius: "all",
      activeMenuFilter: "",
      activeListFilters: [],
      professions: [],
      certifications: [],
      allItems: [],
      fakePaginateIndex: 10,
      activeHits: [],
      affixWidth: null,
      userLatLng: null,
      isOpen: false,
      orderEventFired: false,
      modalIsOpen: false,
      activeSort: {
        label: "Distance",
        icon: "map-marker-alt",
      },
      algoliaClient: algoliasearch(
        "68G5M92M1B",
        "c24543a92b3e155b20a0b69f1ca4b554"
      ),
      aisSortOptions: [
        {
          icon: "sort-amount-up-alt",
          value: "professionals_by_title_asc",
          label: "A-Z",
        },
        {
          icon: "sort-amount-down-alt",
          value: "professionals_by_title_desc",
          label: "Z-A",
        },
      ],
      addSortOptions: [
        {
          icon: "map-marker-alt",
          value: "distance",
          label: "Distance",
        },
      ],
    };
  },
  created() {
    // Create the users lat/lng type
    this.userLatLng =
      parseFloat(this.userLat) + ", " + parseFloat(this.userLng);
  },
  mounted() {
    // Calculate the width of the affix element
    this.calculateAffixWidth();
    window.addEventListener("resize", this.calculateAffixWidth);

    // Init the index to search algolia
    this.index = this.algoliaClient.initIndex("Professionals");

    // Get the professionals facets for filtering
    this.index
      .searchForFacetValues({
        facetName: "professionalType.name",
        facetQuery: "",
      })
      .then((res) => {
        const professions = res.facetHits;
        professions.forEach((el) => {
          el.checked = false;
        });
        this.professions = professions;
      });

    // Get the certification facets for filtering
    this.index
      .searchForFacetValues({
        facetName: "certifications.name",
        maxFacetHits: 50,
        facetQuery: "",
      })
      .then((res) => {
        const certifications = res.facetHits;
        certifications.forEach((el) => {
          el.checked = false;
        });
        this.certifications = res.facetHits;
      });

    // Listen for sort events and respond accordingly
    this.$root.$on("sortEvent", ($event) => {
      this.activeSort.label = $event;
    });
  },

  // Clear out all filters when a query starts firing
  watch: {
    query: {
      handler(val) {
        if (val) {
          this.activeListFilters = [];
          this.activeMenuFilter = "";
        }
      },
      deep: true, // set deep to true so Vue knows it should watch the nested data for changes.
    },
    modalIsOpen() {
      if (this.modalIsOpen) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
  },

  // Handle some state
  computed: {
    filterButtonState: function() {
      return this.activeMenuFilter || this.activeListFilters.length > 0
        ? true
        : false;
    },
    isMobile: function() {
      return this.$screen.xs && !this.$screen.lg;
    },
    isDesktop: function() {
      this.modalIsOpen = false;
      return this.$screen.lg;
    },
    totalFilters: function() {
      let totalFilters = [...this.activeListFilters];
      if (this.activeMenuFilter.length) {
        totalFilters.push(this.activeMenuFilter);
      }
      return totalFilters.length > 0 ? "• " + totalFilters.length : "";
    },
    affixShouldBeEnabled() {
      return !this.$root.isMobile ? true : false;
    },
  },

  methods: {
    gtmTracking(label) {
      this.$gtm.trackEvent({
        event: 'professional-referral', // Event type [default = 'interaction'] (Optional)
        category: 'Professional Referral',
        action: 'Professionals',
        label: label,
        title: label,
        value: 1,
        noninteraction: false // Optional
      });
    },
    transformItems(items) {
      if (!this.query) {
        let sortedItems = items.sort(function(a, b) {
          const fieldA = a._rankingInfo.geoDistance;
          const fieldB = b._rankingInfo.geoDistance;
          if (fieldA > 0 && fieldB > 0) {
            let comparison = 0;
            if (fieldA > fieldB) {
              comparison = 1;
            } else if (fieldA < fieldB) {
              comparison = -1;
            }
            return comparison;
          }
        });
        this.allItems = sortedItems;
        let sortedItemsToReturn = sortedItems.slice(0, 10);
        this.activeHits = sortedItemsToReturn;
        return sortedItemsToReturn;
      } else {
        this.fakePaginateIndex = 10;
        return items;
      }
    },
    fakePaginate(items) {
      // This is us accessing the AisInfiniteHits component data... I know -__- ...
      const aisHits = this.$children[0].$children[3].state.hits;
      let itemsToReturn = this.allItems.slice(
        this.fakePaginateIndex,
        this.fakePaginateIndex + 10
      );
      this.fakePaginateIndex += 10;
      aisHits.push(...itemsToReturn);
      this.activeHits = aisHits;
    },
    // Algolia return distance in meters, so lets convert it to miles.
    distanceFromUser(distance) {
      if (distance === 0) {
        return "Nearest you";
      } else {
        let converted = distance * 0.00062137;
        return converted.toFixed(2) + " miles from you";
      }
    },
    away(e) {
      if (e.target.classList.contains("modal-backdrop")) {
        this.modalIsOpen = false;
      }
    },
    // Track the active professions
    handleMenuClick(item) {
      // Add item to array, if not already there
      this.query = "";
      this.activeMenuFilter =
        item.value == this.activeMenuFilter ? "" : item.value;
    },
    calculateAffixWidth() {
      const initialAffixWidth = 0.335 * window.innerWidth;
      this.affixWidth =
        "width:" +
        (initialAffixWidth <= 420 ? initialAffixWidth : 465.65) +
        "px";
      if (this.$root.isMobile) {
        this.affixWidth = "width: 100%";
      }
    },
    placeHitsOnMap() {
      let locations = this.activeHits.map((hit) => {
        return {
          title: hit.title,
          phone: hit.phone,
          website: hit.website,
          address: hit.address,
          lat: hit._geoloc.lat,
          lng: hit._geoloc.lng,
        };
      });
      return locations;
    },
    // Track the active certifications
    handleListClick(item) {
      // Add/remove filters from array
      this.query = "";
      const activeFilter = this.activeListFilters.indexOf(item.value);
      if (activeFilter == -1) {
        this.activeListFilters.push(item.value);
      } else if (activeFilter > -1) {
        this.activeListFilters.splice(activeFilter, 1);
      }
    },

    // Clear out filters on user click
    handleClearEvent() {
      this.activeListFilters = [];
      this.activeMenuFilter = "";
    },

    // Close the filter menu when clicked
    handleFilterMenuClick() {
      this.modalIsOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  [v-cloak] {
    display: none;
  }

  .modal-backdrop {
    @screen md {
      @apply p-10;
    }
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(33, 33, 33, 0.5);
    transition: opacity 0.3s ease;
  }
  .modal {
    @apply relative bg-white shadow-xl z-1;
    width: 100vw;
    height: 100vh;
    z-index: 90;
    @screen md {
      @apply rounded-lg;
      width: 100vw - 15;
      height: 100vh - 15;
    }
    @screen lg {
      width: 700px;
    }
    @media (min-width: 1024px) and (min-height: 768px) {
      height: auto;
    }
    @screen md {
      @apply absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-height: 100%;
    }
    .modal-content {
      @media screen and (max-height: 767px) {
        @apply py-6;
        padding-bottom: 75px;
        padding-top: 75px;
      }
    }
    .modal-top-toolbar {
      @apply rounded-tl-lg rounded-tr-lg static shadow-none text-center border-b py-5 mb-3 text-base block text-blue-gray font-bold;
      height: 65px;
      @media (max-height: 767px) {
        @apply absolute bg-white top-0 mb-8;
      }
    }
    .modal-bottom-toolbar {
      height: 65px;
      @apply mt-3 rounded-bl-lg rounded-br-lg;
      @media screen and (max-height: 767px), (max-width: 1024px) {
        @apply absolute bg-white bottom-0 mt-8;
      }
    }
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .professionals-container {
    @apply px-8;
    @screen lg {
      @apply max-w-7xl mx-auto;
    }
  }
  // .ais-SearchBox-input {
  //   @apply bg-gray-light appearance-none rounded-full w-full py-3 px-6 text-gray-700 leading-tight;
  //   &::placeholder {
  //     @apply text-blue-light;
  //   }
  // }
}
</style>
