<template>
  <div>
    <div
      class="text-lg flex justify-between flex-row cursor-pointer items-center"
      @click="toggleItem(indexOf)"
    >
      <h4 v-if="accordion.question" class="font-bold text-base">
        {{ accordion.question }}
      </h4>
      <p v-text="showIndex === indexOf ? '-' : '+'" class="my-auto"></p>
    </div>
    <transition name="fade">
      <div v-if="showIndex === indexOf" class="pt-3">
        <h4 class="text-base" v-html="accordion.answer"></h4>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  props: ["accordion"],
  data() {
    return {
      showIndex: null, // Initialize showIndex
    };
  },
  methods: {
    toggleItem(indexOf) {
      if (this.showIndex === indexOf) {
        this.showIndex = null; // Close the current open item
      } else {
        this.showIndex = indexOf; // Open the clicked item
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s ease-in-out, height 0.9s ease-in-out; /* Adjust timing function */
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
