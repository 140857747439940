<template>
  <ul class="flex flex-wrap items-center">
    <li
      v-if="resource.reference"
      class="flex items-center justify-center mb-3 mr-3"
    >
      <a
        @click="gtmTracking('resource-downloaded', 'Download', resource.title)"
        v-if="resource.reference.type == 'asset'"
        v-bind:href="resource.reference.url"
        class="button-primary rounded-full px-3 py-1 text-white hover:text-white"
      >
        <font-awesome-icon
          class="text-white self-center"
          icon="download"
        ></font-awesome-icon>
        <span class="ml-1">Download</span>
      </a>
      <a
        @click="gtmTracking('resource-viewed', 'URL', resource.title)"
        v-else-if="resource.reference.url"
        v-bind:href="resource.reference.url"
        target="_blank"
        class="button-primary rounded-full px-3 py-1 text-white hover:text-white"
      >
        <font-awesome-icon
          class="text-blue-300 self-center"
          icon="external-link-alt"
        ></font-awesome-icon>
        <span class="ml-1">Open Resource</span>
      </a>
    </li>
    <li
      class="bg-gray-300 mb-3 rounded-full px-3 py-1 flex items-center justify-center"
    >
      <button
        v-on:click="addToCollection(resource)"
        class="hover:text-orange-bright transition-500"
      >
        <font-awesome-icon
          class="text-orange-300 self-center text-sm"
          icon="minus"
          v-if="
            this.$root.collection.find(({ id }) => id === resource.objectID)
          "
        ></font-awesome-icon>
        <font-awesome-icon
          class="text-blue-300 self-center"
          icon="plus"
          v-else
        ></font-awesome-icon>
        <span class="ml-1">
          <template
            v-if="
              this.$root.collection.find(({ id }) => id === resource.objectID)
            "
          >
            Remove from Collection
          </template>
          <template v-else>
            Add to Collection
          </template>
        </span>
      </button>
    </li>
    <!-- <li>
            <button type="button" 
                class="button-primary"
                v-clipboard:copy="currentUrl"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError">Share</button>
        </li> -->
  </ul>
</template>

<script>
export default {
  name: "ResourceControls",
  props: ["resource"],
  data() {
    return {
      currentUrl: window.location.href,
    };
  },
  methods: {
     gtmTracking: function(eventName, act, lab) {
      this.$gtm.trackEvent({
        event: eventName, // Event type [default = 'interaction'] (Optional)
        category: 'Resource Action',
        action: act,
        label: lab,
        title: lab,
        value: 1,
        noninteraction: false // Optional
      });
    },
    addToCollection: function(resource) {
      var found = false;
      // Check if collection exists
      if (this.$root.collection.length) {
        // if collection exists, loop through to see if resource already exists in collection
        for (var i = 0; i < this.$root.collection.length; i++) {
          if (this.$root.collection[i].id === resource.objectID) {
            found = true;
          }
        }
      }
      // if resource is not in the collection, add it
      if (!found) {
        const parsed = {
          id: resource.objectID,
          title: resource.title,
          reference: resource.reference,
          selected: true,
        };
        this.$root.collection.push(parsed);
        localStorage.setItem(
          "collection",
          JSON.stringify(this.$root.collection)
        );
      } else {
        // Remove resource from collections
        var location = this.$root.collection
          .map(function(x) {
            return x.id;
          })
          .indexOf(resource.objectID);
        this.$root.collection.splice(location, 1);
        localStorage.setItem(
          "collection",
          JSON.stringify(this.$root.collection)
        );
      }
      // Check for collection size
      this.$root.$emit("getCollectionSize");
      // GTM Tracking
      this.$gtm.trackEvent({
        event: 'add-to-collection', // Event type [default = 'interaction'] (Optional)
        category: 'Resource Action',
        action: 'Add To Collection',
        label: resource.title,
        title: resource.title,
        value: 1,
        noninteraction: false // Optional
      });
    },
    onCopy: function(e) {
      alert("You just copied: " + e.text);
    },
    onError: function(e) {
      alert("There was an error sharing this.");
    },
  },
};
</script>
