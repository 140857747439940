<template>
  <div>
    <transition name="modal" key="state-transition" tag="div">
      <!-- The modal overlay that appears when modal is open -->
      <div class="modal-backdrop" v-show="modalIsOpen">
        <!-- 
        This is the secondary filter modal 
        which turns into mobile filter UI for
        all filters.
      -->
        <div
          class="modal modal-container"
          v-on-clickaway="away"
          style="positionModal"
        >
          <div class="flex">
              <div class="w-full">
                <header aria-label="More Filters" class="modal-top-toolbar">
                  More Filters
                </header>
              </div>
            </div>
          <div class="modal-content block relative h-full overflow-auto">
            <div class="flex flex-wrap px-8">
              <div class="block border-b py-4 lg:hidden w-full mb-6 lg:mb-0">
                <h4 class="font-bold text-blue-gray mb-2 text-lg">Category</h4>
                <resource-primary-filters
                  :filters="resourceGroup"
                  :active-menu-filter="activeMenuFilter"
                  @handleTypeClick="handleMenuClick"
                />
              </div>
              <resource-secondary-filters
                :active-list-filters="activeListFilters"
                :search-client="searchClient"
                @closeModal="closeResourceFilter"
              />
            </div>
          </div>
          <div class="w-full flex justify-between items-center px-6 py-3 border-t modal-bottom-toolbar">
            <ais-clear-refinements
              :class-names="{
                'ais-ClearRefinements': 'leading-none'
              }"
            >
              <div slot-scope="{ canRefine, refine, createURL }">
                <a
                  class="text-base text-orange-bright"
                  :href="createURL()"
                  @click.prevent="handleClearEvent(refine)"
                  v-if="canRefine"
                >
                  Clear all
                </a>
              </div>
            </ais-clear-refinements>
            <ais-stats>
              <button
                slot-scope="{ nbHits }"
                @click="closeResourceFilter"
                class="button-primary"
              >
                Show <strong>{{ nbHits }}</strong> results
              </button>
            </ais-stats>
          </div>
        </div>
      </div>
    </transition>

    <!-- This is the main resource type filters -->
    <div class="flex flex-row lg:justify-center">
      <resource-primary-filters
        class="hidden lg:block"
        :filters="resourceGroup"
        :active-menu-filter="activeMenuFilter"
        @handleTypeClick="handleMenuClick"
      />

      <!-- This toggles the secondary filters modal -->
      <button
        class="more-filters"
        @click="openResourceFilters"
      >
        <slot name="filterIcon"></slot>
        <span class="ml-2 text-blue-gray">More Filters <span class="font-bold">{{ totalFilters }}</span></span>
      </button>
    </div>
  </div>
</template>

<script>
import { createWidgetMixin } from "vue-instantsearch";
import { connectClearRefinements } from "instantsearch.js/es/connectors";
import { mixin as clickaway } from "vue-clickaway";
export default {
  name: "ResourceFilters",
  mixins: [
    createWidgetMixin({ connector: connectClearRefinements }),
    clickaway
  ],
  props: {
    query: String,
    activeListFilters: Array,
    activeMenuFilter: String,
    activeMenuSelectFilter: String,
    includedAttributes: Array,
    resourceGroup: Array,
    searchClient: Object
  },

  data() {
    return {
      currentUrl: window.location.href,
      modalIsOpen: false
    };
  },

  mounted() {

    // Determine the state of the URL if there is an param present
    if (this.currentUrl.indexOf("?refine=") != -1) {
      this.filterResourcesFromUrl();
    }
  },

  // Set up algolias connector prop that automatically gets passed in
  computed: {
    widgetParams() {
      return {
        includedAttributes: this.includedAttributes
      };
    },
    getRefinementsFromUrl: function() {
      // Create new URL interface and set to local data var
      let url = new URL(this.currentUrl).searchParams;
      this.urlQueryString = url;

      // Map over ID's and update localCollection
      let filterValue = url.get("refine").split(",");

      return filterValue;
    },
    totalFilters: function() {
      let totalFilters = [...this.activeListFilters];
      if (this.activeMenuFilter.length) {
        totalFilters.push(this.activeMenuFilter); 
      }
      if (this.activeMenuSelectFilter.length) {
        totalFilters.push(this.activeMenuSelectFilter); 
      }
      return totalFilters.length > 0 ? '(' + totalFilters.length + ')' : '';
    }
  },
  watch: {
    // Clear out all filters when a query starts firing
    query: {
      handler(val) {
        if (val) {
          this.state.refine();
        }
      },
      deep: true // set deep to true so Vue knows it should watch the nested data for changes.
    },
    modalIsOpen() {
      if (this.modalIsOpen) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    }
  },
  methods: {
    filterResourcesFromUrl() {
      // Get refinements from URL and filter results
      let refinement = {
        value: this.getRefinementsFromUrl
      };

      this.$emit("filterResourcesByUrl", refinement);
    },
    openResourceFilters() {
      this.modalIsOpen = true;
      // this.$modal.show("resource-filters");
    },
    closeResourceFilter() {
      this.modalIsOpen = false;
      // this.$modal.hide("resource-filters");
    },
    away(e) {
      if (e.target.classList.contains("modal-backdrop")) {
        this.modalIsOpen = false;
      }
    },
    // Track the active professions
    handleMenuClick(refine, item) {
      // Init the internal ais refinement method
      refine(item.value);

      // Add item to array, if not already there
      this.$root.$emit("activeMenuChanged", item);
    },
    // Clear out filters on user click
    handleClearEvent(refine) {
      refine();
      this.$emit("filtersCleared");
    },
  }
};
</script>
<style lang="scss" scoped>
.modal-backdrop {
  @screen md {
    @apply p-10;
  }
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.5);
  transition: opacity 0.3s ease;
}
.modal {
  @apply relative bg-white shadow-xl z-1;
  width: 100vw;
  height: 100vh;
  z-index: 90;
  @screen md {
    @apply rounded-lg;
    width: 100vw - 15;
    height: 100vh - 15;
  }
  @screen lg {
    width: 700px;
  }
  @media (min-width: 1024px) and (min-height: 768px) { 
    height: auto;
  }
  @screen md {
    @apply absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
  }
  .modal-content {
    @media screen and (max-height: 767px) {
      @apply py-6;
      padding-bottom: 75px;
      padding-top: 75px;
    }
  }
  .modal-top-toolbar {
    @apply rounded-tl-lg rounded-tr-lg static shadow-none text-center border-b py-5 mb-3 text-base block text-blue-gray font-bold;
    height: 65px;
    @media (max-height: 767px) {
      @apply absolute bg-white top-0 mb-8;
    }
  }
  .modal-bottom-toolbar {
    height: 65px;
    @apply mt-3 rounded-bl-lg rounded-br-lg;
    @media screen and (max-height: 767px), (max-width: 1024px) {
      @apply absolute bg-white bottom-0 mt-8;
    }
  }
}
.filters {
  .filter-panel {
    @apply border-t pt-3 mt-5;
    &:first-child {
      @apply border-0 pt-0 mt-3;
    }
    &:last-child {
      @apply border-0;
    }
  }
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
.more-filters {
  @apply flex flex-no-wrap items-center text-blue-light px-4 pb-2 border-b-4 border-transparent transition-500;
  svg {
    path {
      transition: all 500ms ease;
      fill: #96B9CF;
      stroke: #96B9CF;
    }
  }
  &:hover {
    @apply border-orange-dark;
    svg {
      path {
        fill: #cc6632;
        stroke: #cc6632;
      }
    }
  }
}
</style>
