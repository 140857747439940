<template>
    <div>
        <button class="hef-modal-button rounded-full h-12 w-12 flex items-center justify-center hef-secondary-link absolute" @click="openResourceFilters">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'plus' }" class="text-white"></font-awesome-icon>
        </button>
        <modal :name="modalId" height="auto">
            <div class="p-10 relative">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'times' }" class="text-orange-bright absolute right-20 top-20" @click="closeResourceFilter"></font-awesome-icon>
                <h3 class="hef-modal-title mb-0">{{ speaker }}</h3>
                <p>{{ speakerTitle }}</p>
                <hr class="border-orange-bright border-t-2 w-8 mb-6">
                <div v-if="bio" v-html="bio" class="mb-8"></div>
                <h4 v-if="sessions" class="hef-modal-session-title font-bold mb-2 uppercase tracking-widest">Sessions to be presented</h4>
                <ul v-if="sessions" class="hef-modal-session-name">
                    <li v-for="name in sessions" class="mb-4 pl-2">
                        {{ name.title }}
                    </li>
                </ul>
            </div>
        </modal>
    </div>
</template>

<script>
    export default {
        name: "SpeakerModal",
        props: {
            speaker: String,
            speakerTitle: String,
            modalId: String,
            bio: String,
            sessions: Array
        },
        data() {
            return {
            }
        },
        methods: {
            openResourceFilters() {
                this.$modal.show(this.modalId);
            },
            closeResourceFilter () {
                this.$modal.hide(this.modalId);
            }
        }
    }
</script>