<template>
  <div>
    <ais-instant-search :search-client="searchClient" index-name="Resources">
      <section class="section md:pt-4">
        <div class="container">
          <div class="flex flex-wrap items-center justify-center">
            <div class="w-full mb-2 md:w-1/3">
              <h1 class="text-center">Resources</h1>
              <ais-search-box
                v-model="query"
                placeholder="Find a Resource"
                :class-names="{
                  'ais-SearchBox-form': 'flex flex-row relative',
                  'ais-SearchBox-input':
                    'outline-none rounded-full bg-gray-lightest px-5 py-3 shadow-none border-0',
                  'ais-SearchBox-submit': '-mx-8 text-gray-600 outline-none',
                  'ais-SearchBox-submitIcon': 'w-4 h-4 fill-current',
                }"
              />
            </div>
            <div class="flex items-center justify-center w-full">
              <button
                class="flex items-center justify-center text-orange-dark"
                @click="triggerInstructionsModal"
              >
                <slot name="infoOutline"></slot>
                <span class="ml-1">Instructions</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container">
          <div class="flex">
            <div class="w-full">
              <resource-filters
                :search-client="searchClient"
                :resource-group="resourceGroup"
                :query="query"
                :active-list-filters="activeListFilters"
                :active-menu-filter="activeMenuFilter"
                :active-menu-select-filter="activeMenuSelectFilter"
                @filterResourcesByUrl="filterResourcesByUrl"
                @filtersCleared="handleClearEvent"
              >
                <slot name="filterIcon" slot="filterIcon"></slot>
              </resource-filters>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-16 bg-gray-100 section">
        <div class="container">
          <div class="flex">
            <div class="w-full">
              <template
                v-if="
                  !query &&
                  !activeMenuFilter &&
                  !activeListFilters.length > 0 &&
                  !activeMenuSelectFilter
                "
              >
                <ais-hits>
                  <div slot-scope="{ items }">
                    <slider
                      v-if="featuredResources.length > 0"
                      :resources="featuredResources"
                    ></slider>
                    <slider
                      v-if="highPerformanceWindows.length > 0"
                      :resources="highPerformanceWindows"
                      title="'High-Performance Windows'"
                    ></slider>
                    <slider
                      v-if="marketing.length > 0"
                      :resources="marketing"
                      title="'Marketing'"
                    ></slider>
                    <slider
                      v-if="performancePath.length > 0"
                      :resources="performancePath"
                      title="'Performance Path'"
                    ></slider>
                    <slider
                      v-if="pilotMaterials.length > 0"
                      :resources="pilotMaterials"
                      title="'Pilot Materials'"
                    ></slider>
                    <slider
                      v-if="tools.length > 0"
                      :resources="tools"
                      title="'Tools'"
                    ></slider>
                    <slider
                      v-if="training.length > 0"
                      :resources="training"
                      title="'Training'"
                    ></slider>
                    <slider
                      v-if="ductlessHeatPumps.length > 0"
                      :resources="ductlessHeatPumps"
                      title="'Ductless Heat Pumps'"
                    ></slider>
                  </div>
                </ais-hits>
              </template>
              <!-- If the user searches or selects a filter -->
              <template v-else>
                <template v-if="valueFromUrl">
                  <ais-configure
                    :facet-filters.camel="['resourceGroup:' + valueFromUrl]"
                    :hits-per-page.camel="6"
                  />
                </template>
                <template v-else>
                  <ais-configure :hits-per-page.camel="6" />
                </template>
                <ais-infinite-hits>
                  <template slot-scope="{ items, isLastPage, refineNext }">
                    <ul
                      class="flex flex-wrap justify-center md:-mx-6"
                      v-if="items.length"
                    >
                      <li
                        class="w-full mb-12 md:w-1/3 md:px-6"
                        v-for="entry in items"
                        :key="entry.objectID"
                      >
                        <resource-card :resource="entry"></resource-card>
                      </li>
                      <!-- Load more button -->
                      <li class="text-center" v-if="!isLastPage">
                        <button @click="refineNext" class="button-primary">
                          Show more
                        </button>
                      </li>
                    </ul>
                    <div v-else>
                      <p class="text-2xl text-center text-gray-600">
                        There are no resources found for <q>{{ query }}</q>
                      </p>
                    </div>
                    <!-- <div class="pagination"><ais-pagination /></div> -->
                  </template>
                </ais-infinite-hits>
              </template>
            </div>
          </div>
        </div>
      </section>
    </ais-instant-search>
  </div>
</template>

<script>
import axios from "axios";
import algoliasearch from "algoliasearch/lite";
import ResourceFilters from "./ResourceFilters";
import ResourceCard from "./ResourceCard";
import moment from "moment";
import JSZip from "jszip";

export default {
  name: "ResourceListing",
  data() {
    return {
      isOpen: false,
      query: "",
      index: "",
      activeMenuFilter: "",
      activeMenuSelectFilter: "",
      allResources: [],
      resourceGroup: [],
      audience: [],
      resourceType: [],
      technology: [],
      homeType: [],
      queries: [],
      featuredResources: [],
      marketing: [],
      highPerformanceWindows: [],
      performancePath: [],
      pilotMaterials: [],
      tools: [],
      training: [],
      ductlessHeatPumps: [],
      activeListFilters: [],
      valueFromUrl: "",
      instance: {
        baseURL: "api/featured-resources.json",
      },
      searchClient: algoliasearch(
        "68G5M92M1B",
        "0f41898cd8301d5ed3eb6a82ed602098"
      ),
      ResourceFiltersOpen: false,
    };
  },
  created() {
    // set index
    this.index = this.searchClient.initIndex("Resources");

    // Get Resource Group Facet
    this.index
      .searchForFacetValues({
        facetName: "resourceGroup",
        facetQuery: "",
        maxValuesPerFacet: 100,
      })
      .then((facetHits) => {
        this.resourceGroup = facetHits.facetHits;
      });

    // Get featured resources
    axios
      .get(this.instance.baseURL)
      .then((response) => {
        this.featuredResources = response.data.data[0];
        this.loadOtherResources();
      })
      .catch((error) => {
        console.log(error);
        this.loadOtherResources();
      });
  },
  computed: {
    last() {
      return Object.keys(this.instructions).length - 1;
    },
  },
  mounted() {
    this.$root.$on("activeMenuChanged", ($event) => {
      this.activeMenuFilter =
        $event.value == this.activeMenuFilter ? "" : $event.value;
    });
    this.$root.$on("activeSelectFilterChanged", ($event) => {
      this.activeMenuSelectFilter =
        $event == this.activeMenuSelectFilter ? "" : $event;
    });
    this.$root.$on("activeHandleListChanged", ($event) => {
      // Add/remove filters from array
      const activeFilter = this.activeListFilters.indexOf($event.value);
      if (activeFilter == -1) {
        this.activeListFilters.push($event.value);
      } else if (activeFilter > -1) {
        this.activeListFilters.splice(activeFilter, 1);
      }
    });
  },
  filters: {
    postDate: function (data) {
      return moment(data).format("DD MMMM, YYYY");
    },
  },
  watch: {
    query: {
      handler(val) {
        if (val) {
          this.activeListFilters = [];
          this.activeMenuFilter = "";
        }
      },
      deep: true, // set deep to true so Vue knows it should watch the nested data for changes.
    },
  },
  methods: {
    loadOtherResources() {
      this.index
        .search({
          query: "",
          hitsPerPage: 1000,
        })
        .then((res) => {
          this.allResources = res.hits;
        });

      // Get High-Performance Windows facet
      this.index
        .search({
          facets: "resourceGroup",
          facetFilters: ["resourceGroup:High-Performance Windows"],
          hitsPerPage: 1000,
        })
        .then((res) => {
          this.highPerformanceWindows = res.hits;
        });
      // Get Marketing facet
      this.index
        .search({
          facets: "resourceGroup",
          facetFilters: ["resourceGroup:Marketing"],
          hitsPerPage: 1000,
        })
        .then((res) => {
          this.marketing = res.hits;
        });
      // Get Performance Path facet
      this.index
        .search({
          facets: "resourceGroup",
          facetFilters: ["resourceGroup:Performance Path"],
          hitsPerPage: 1000,
        })
        .then((res) => {
          this.performancePath = res.hits;
        });
      // Get Pilot Materials facet
      this.index
        .search({
          facets: "resourceGroup",
          facetFilters: ["resourceGroup:Pilot Materials"],
          hitsPerPage: 1000,
        })
        .then((res) => {
          this.pilotMaterials = res.hits;
        });
      // Get Tools facet
      this.index
        .search({
          facets: "resourceGroup",
          facetFilters: ["resourceGroup:Tools"],
          hitsPerPage: 1000,
        })
        .then((res) => {
          this.tools = res.hits;
        });
      // Get Training facet
      this.index
        .search({
          facets: "resourceGroup",
          facetFilters: ["resourceGroup:Training"],
          hitsPerPage: 1000,
        })
        .then((res) => {
          this.training = res.hits;
        });
      // Get Ductless Heat Pumps
      this.index
        .search({
          facets: "resourceGroup",
          facetFilters: ["resourceGroup:Ductless Heat Pumps"],
          hitsPerPage: 1000,
        })
        .then((res) => {
          this.ductlessHeatPumps = res.hits;
        });
    },
    filterResourcesByUrl(filter) {
      this.index = this.searchClient.initIndex("Resources");
      this.activeMenuFilter =
        filter.value == this.activeMenuFilter ? "" : filter.value[0];
      this.valueFromUrl = filter.value[0];
    },
    // Clear out filters on user click
    handleClearEvent() {
      this.activeListFilters = [];
      this.activeMenuFilter = "";
      this.activeMenuSelectFilter = "";
    },
    triggerInstructionsModal() {
      this.$root.$emit("trigger::instructions");
    },
    // handles blocking of scrolling when a modal is open
    beforeOpen(event) {
      document
        .getElementsByTagName("html")[0]
        .classList.add("v--modal-block-scroll");
    },
    beforeClose(event) {
      window.removeEventListener("resize", this.calculateWidth);
      document
        .getElementsByTagName("html")[0]
        .classList.remove("v--modal-block-scroll");
    },
    afterOpen(e) {
      this.calculateWidth();
      window.addEventListener("resize", this.calculateWidth);
    },
  },
};
</script>

<style lang="css">
[v-cloak] {
  display: none;
}
.ais-SearchBox-input {
  @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;
  &:focus {
    @apply outline-none shadow-outline;
  }
}
.pagination {
  margin: 2rem auto;
  text-align: center;
}
.mobile {
  width: 95% !important;
  left: 2.5% !important;
}
</style>
