<template>
    <div>
        <ais-instant-search :search-client="searchClient" index-name="Search">
            <section class="py-12">
                <div class="container">
                    <div class="flex justify-center items-center">
                        <div class="w-2/3 md:w-1/3">
                            <h1 class="text-center">Search</h1>
                            <ais-search-box
                                v-model="query"
                                placeholder="Search BetterBuiltNW"
                                :class-names="{
                                  'ais-SearchBox-form': 'flex flex-row relative',
                                  'ais-SearchBox-input': 'outline-none rounded-full bg-gray-lightest px-5 py-3 shadow-none border-0',
                                  'ais-SearchBox-submit': '-mx-8 text-gray-600 outline-none',
                                  'ais-SearchBox-submitIcon': 'w-4 h-4 fill-current'
                                }"/>
                        </div>
                    </div>
                </div>
            </section>
            <section class="py-12 bg-gray-100">
                <div class="container">
                    <div class="flex flex-wrap xl:-mx-6">
                        <transition name="fade" v-cloak>
                            <div
                                v-show="mobileFilterOpen || isDesktop"
                                class="fixed inset-0 z-50 w-full pt-0 lg:block lg:relative lg:w-1/4 lg:pt-6 md:px-3"
                                :class="[mobileFilterOpen ? 'block' : 'hidden']"
                            >
                                <aside-filter
                                    :menu-list="entryType"
                                    menu-list-attr="entryType"
                                    menu-list-label="Entry Types"
                                    :activeMenuFilter="activeMenuFilter"
                                    :isDesktop="isDesktop"
                                    @menuUpdated="handleMenuClick"
                                    @listUpdated="handleListClick"
                                    @filtersCleared="handleClearEvent"
                                    @closeFilterMenu="handleFilterMenuClick"
                                />
                            </div>
                        </transition>

                        <!-- Results -->
                        <div class="md:px-3 w-full lg:w-3/4 border-t pt-6">
                            <ul
                                class="flex justify-between items-end text-gray-muted mb-6 md:mb-0"
                            >
                                <!-- Mobile filter button toggle -->
                                <li class="inline-block" v-if="isMobile">
                                    <button
                                        @click="mobileFilterOpen = !mobileFilterOpen"
                                        class="text-blue-dark py-1 px-5 rounded-full border border-gray-500"
                                    >
                                        <span>Filters {{ totalFilters }}</span>
                                    </button>
                                </li>
                            </ul>
                        
                            <ais-configure
                                :hits-per-page.camel="6"
                            />
                            <ais-infinite-hits>
                                <template slot-scope="{ items, isLastPage, refineNext }">
                                    <ul class="flex flex-row flex-wrap" v-if="items.length">
                                        <li
                                            data-aos="fade-up"
                                            data-aos-anchor-placement="top-bottom"
                                            class="w-full md:px-3 mb-6"
                                            v-for="entry in items"
                                            :key="entry.objectID"
                                        >
                                            <a
                                            :href="'/' + entry.path"
                                            class="card block bg-white h-full shadow-md w-full mb-6 md:mb-0 p-8 lg:p-10 border-b-4 border-orange-dark"
                                            >
                                                <div :class="{ 'mb-16': entry.newsType }">
                                                    <h3 class="text-xl font-bold text-blue-gray mb-3 lg:mb-4 leading-tight">
                                                        {{ entry.title }}
                                                    </h3>
                                                    <p class="text-gray-muted" v-if="entry.searchDescription" >
                                                        {{ entry.searchDescription | truncate(200, "…") }}
                                                    </p>
                                                </div>
                                                <p v-if="entry.entryType" class="mt-10 mb-0 uppercase text-orange-dark font-bold text-sm tracking-widest">
                                                    {{ entry.entryType }}
                                                </p>
                                            </a>
                                        </li>
                                        <!-- Load more button -->
                                        <li class="text-center m-auto" v-if="!isLastPage">
                                        <button
                                            @click="refineNext"
                                            class="button-primary"
                                        >
                                            Show more
                                        </button>
                                        </li>
                                    </ul>
                                    <div v-else>
                                        <p class="text-2xl text-gray-600 text-center">There are no results found for <q>{{ query }}</q></p>
                                    </div>
                                </template>
                            </ais-infinite-hits>
                        </div>
                    </div>
                </div>
            </section>
        </ais-instant-search>
    </div>
</template>

<script>
    import algoliasearch from "algoliasearch/lite";
    import "instantsearch.css/themes/reset.css";
    export default {
        name: "Search",
        data() {
            return {
                index: "",
                query: "",
                entryType: [],
                activeMenuFilter: "",
                activeListFilters: [],
                searchClient: algoliasearch(
                    "68G5M92M1B",
                    "c24543a92b3e155b20a0b69f1ca4b554"
                ),
            };
        },
        mounted() {
            // Init the index to search algolia
            this.index = this.searchClient.initIndex("Search");

            // Get the professionals facets for filtering
            this.index
            .searchForFacetValues({
                facetName: "entryType",
                facetQuery: ""
            })
            .then(res => {
                const entryType = res.facetHits;
                entryType.forEach(el => {
                    el.checked = false;
                });
                this.entryType = entryType;
            });
        },
        filters: {
            truncate: function(text, length, clamp) {
                clamp = clamp || "...";
                var node = document.createElement("div");
                node.innerHTML = text;
                var content = node.textContent;
                return content.length > length
                    ? content.slice(0, length) + clamp
                    : content;
            },
            format: function(data) {
                return moment(data).format("DD MMMM, YYYY");
            }
        },
        // Handle some state
        computed: {
            filterButtonState: function() {
                return this.activeMenuFilter || this.activeListFilters.length > 0 ? true : false
            },
            isMobile: function() {
                return this.$screen.xs && !this.$screen.lg;
            },
            isDesktop: function() {
                this.mobileFilterOpen = false;
                return this.$screen.lg;
            },
            totalFilters: function() {
                let totalFilters = [...this.activeListFilters];
                if (this.activeMenuFilter.length) {
                    totalFilters.push(this.activeMenuFilters);
                }
                return totalFilters.length > 0 ? "• " + totalFilters.length : "";
            }
        },

        methods: {
            // Track the active professions
            handleMenuClick(item) {
                // Add item to array, if not already there
                this.query = "";
                this.activeMenuFilter = item.value == this.activeMenuFilters ? "" : item.value;
            },

            // Track the active certifications
            handleListClick(item) {
                // Add/remove filters from array
                this.query = "";
                const activeFilter = this.activeListFilters.indexOf(item.value);
                if (activeFilter == -1) {
                    this.activeListFilters.push(item.value);
                } else if (activeFilter > -1) {
                    this.activeListFilters.splice(activeFilter, 1);
                }
            },

            // Clear out filters on user click
            handleClearEvent() {
                this.activeListFilters = [];
                this.activeMenuFilter = "";
            },

            // Close the filter menu when clicked
            handleFilterMenuClick() {
                this.mobileFilterOpen = false;
            }
        }
    }
</script>
<style lang="scss" scoped>
::v-deep {
  [v-cloak] {
    display: none;
  }
  .ais-SearchBox-input {
    @apply bg-gray-light appearance-none rounded-full w-full py-3 px-6 text-gray-700 leading-tight;
    &::placeholder {
      @apply text-blue-light;
    }
  }
}
</style>