var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ais-instant-search',{attrs:{"search-client":_vm.algoliaClient,"index-name":"Utilities"}},[_c('div',{staticClass:"section md:pt-4 bg-white"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex justify-center items-center"},[_c('div',{staticClass:"w-full md:w-4/5 xl:w-1/2 text-center"},[(_vm.pageTitle)?_c('h1',[_vm._v(_vm._s(_vm.pageTitle))]):_vm._e(),_c('ais-search-box',{attrs:{"placeholder":"Enter a zip code or name","class-names":{
                'ais-SearchBox': 'flex flex-row justify-center',
                'ais-SearchBox-form': 'flex flex-row w-full sm:w-1/2',
                'ais-SearchBox-submit':
                  _vm.query.length > 0
                    ? 'hidden -mx-8 text-blue-dark outline-none'
                    : '-mx-8 text-blue-dark outline-none',
                'ais-SearchBox-reset': 'text-blue-dark outline-none -mx-8',
                'ais-SearchBox-submitIcon': 'w-4 h-4 fill-current',
                'ais-SearchBox-resetIcon': 'w-3 h-3 fill-current'
              }},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)])])]),_c('section',{staticClass:"py-8"},[_c('div',{staticClass:"utilities-container"},[_c('div',{staticClass:"flex flex-wrap xl:-mx-6"},[_c('div',{staticClass:"md:px-3 xl:px-6 w-full border-t pt-6"},[(_vm.query && _vm.isNumber)?[_c('ais-configure',{attrs:{"hitsPerPage":10,"facetFilters":[
                    'serviceAreas:' + _vm.query
                ]}})]:[_c('ais-configure',{attrs:{"hitsPerPage":10}})],_c('div',{staticClass:"flex flex-row justify-end -mx-4"},[_c('div',{staticClass:"w-full md:w-3/5 px-4"},[_c('ul',{staticClass:"w-full flex justify-between items-end text-gray-muted mb-6"},[_c('li',{staticClass:"inline-block"},[_c('ais-stats',{attrs:{"class-names":{
                        'ais-Stats-text': 'text-gray-muted'
                      }},scopedSlots:_vm._u([{key:"default",fn:function({
                          hitsPerPage,
                          nbPages,
                          nbHits,
                          page,
                          processingTimeMS,
                          query
                        }){return _c('p',{staticClass:"text-blue-dark"},[(!query)?[_c('strong',[_vm._v(_vm._s(nbHits))]),_vm._v(" total utilities\n                        ")]:(query && _vm.isNumber)?[_c('strong',[_vm._v(_vm._s(nbHits))]),_vm._v(" utilities around\n                          "),_c('em',[_vm._v(_vm._s(query))])]:[_c('strong',[_vm._v(_vm._s(nbHits))]),_vm._v(" utilities for\n                          "),_c('em',[_vm._v(_vm._s(query))])]],2)}}])})],1)])])]),_c('ais-infinite-hits',{scopedSlots:_vm._u([{key:"default",fn:function({ items, isLastPage, refineNext }){return (items.length > 0)?_c('div',{staticClass:"flex flex-row flex-wrap -mx-4"},[_c('div',{ref:"mapContainer",staticClass:"w-full mb-8 md:w-2/5 px-4"},[_c('affix',{staticClass:"mx-auto lg:mx-0",style:(_vm.affixWidth),attrs:{"relative-element-selector":"#utility-content","enabled":_vm.affixShouldBeEnabled}},[_c('div',{staticClass:"md:h-56 lg:h-85"},[_c('google-map',{attrs:{"locations":_vm.utilityLocations(items),"include-info-box":true,"map-options":{
                          streetViewControl: false,
                          rotateControl: false,
                          fullscreenControl: false
                        }}})],1)])],1),_c('div',{staticClass:"w-full md:w-3/5 px-4",attrs:{"id":"utility-content"}},[_c('ul',{staticClass:"flex flex-wrap"},[_vm._l((items),function(entry){return _c('li',{key:entry.objectID,staticClass:"w-full mb-8",attrs:{"data-aos":"fade-up","data-aos-anchor-placement":"top-bottom"}},[_c('div',{staticClass:"flex flex-wrap w-full p-8 bg-white shadow-md border-b-4 border-blue-light"},[_c('div',{staticClass:"w-full md:w-3/5 border-b pb-6 mb-6 md:mb-0 md:pb-0 md:pr-6 md:border-b-0 md:border-r border-gray-100"},[_c('h3',{staticClass:"text-xl font-bold text-blue-gray mb-3 lg:mb-4 leading-tight"},[_vm._v("\n                            "+_vm._s(entry.title)+"\n                          ")]),(entry.utilityAddress)?_c('address',[(entry.utilityAddress[0].street1)?_c('span',{domProps:{"innerHTML":_vm._s(entry.utilityAddress[0].street1)}}):_vm._e(),(entry.utilityAddress[0].street2)?_c('span',{domProps:{"innerHTML":_vm._s(entry.utilityAddress[0].street2)}}):_vm._e(),_c('br'),(entry.utilityAddress[0].city)?_c('span',{domProps:{"innerHTML":_vm._s(entry.utilityAddress[0].city)}}):_vm._e(),_vm._v(",  \n                              "),(entry.utilityAddress[0].state)?_c('span',{domProps:{"innerHTML":_vm._s(entry.utilityAddress[0].state)}}):_vm._e(),_vm._v(" \n                              "),(entry.utilityAddress[0].zip)?_c('span',{domProps:{"innerHTML":_vm._s(entry.utilityAddress[0].zip)}}):_vm._e()]):_vm._e()]),_c('div',{staticClass:"w-full flex md:justify-center md:w-2/5 pb-6 mb-6 md:mb-0 md:pb-0 border-gray-100",class:!entry.email || !entry.phone ? 'md:px-6' : ''},[_c('div',[_c('p',{staticClass:"mb-3 text-sm text-blue-gray font-bold"},[_vm._v("\n                              Information\n                            ")]),_c('ul',{staticClass:"flex flex-col"},[_c('li',{staticClass:"text-sm inline-block align-middle mb-2 whitespace-no-wrap"},[_c('a',{class:{
                                    'cursor-not-allowed text-gray-muted italic': !entry.email,
                                    'text-orange-bright': entry.email
                                  },attrs:{"href":entry.email ? `mailto:${entry.email}` : null}},[_c('font-awesome-icon',{staticClass:"text-blue-light",attrs:{"icon":['fas', 'envelope']}}),_vm._v(" \n                                  "+_vm._s(entry.email ? "Email" : "Not Available")+"\n                                ")],1)]),_c('li',{staticClass:"text-sm inline-block align-middle whitespace-no-wrap mb-3 lg:mb-6"},[_c('font-awesome-icon',{staticClass:"text-blue-light",attrs:{"icon":['fas', 'phone-alt']}}),_vm._v(" \n                                "),(entry.phone && entry.phone != '--')?_c('a',{staticClass:"text-gray-muted",attrs:{"href":`tel:${entry.phone}`},domProps:{"innerHTML":_vm._s(entry.phone)}}):_c('p',{staticClass:"text-gray-muted italic inline"},[_vm._v("\n                                  Not Available\n                                ")])],1),_c('li',[_c('a',{staticClass:"text-white text-xs py-2 px-3 rounded-full",class:{
                                    'button-primary':
                                      entry.website,
                                    'bg-gray-400 cursor-not-allowed': !entry.website
                                  },attrs:{"href":entry.website,"target":"_blank"},on:{"click":function($event){return _vm.gtmTracking(entry.title)}}},[_vm._v("\n                                  Visit Website\n                                ")])])])])])])])}),(!isLastPage)?_c('li',{staticClass:"w-full text-center py-8"},[_c('button',{staticClass:"button-primary",on:{"click":refineNext}},[_vm._v("\n                        Show more\n                      ")])]):_vm._e()],2)])]):_c('div',[_c('p',{staticClass:"mt-16 text-2xl text-gray-muted text-center"},[(_vm.query)?[_vm._v("\n                    There are no utilities found for\n                    "),_c('q',[_vm._v(_vm._s(_vm.query))])]:[_vm._v("\n                    No utilities found.\n                  ")]],2)])}}],null,true)})],2)])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }