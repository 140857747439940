<template>
  <ais-menu attribute="resourceGroup" :transform-items="getResourceGroup">
    <ul
      slot-scope="{ items, refine, createURL }"
      class="flex flex-wrap lg:flex-no-wrap lg:justify-center"
    >
      <li
        v-for="(item, index) in items"
        :key="item.value"
        class="inline-block w-auto lg:px-4"
        :class="{
          'lg:pl-0': index == 0,
          'lg:pr-0': index === items.length,
        }"
      >
        <a
          class="block px-2 text-sm text-center border-b-4 border-transparent text-blue-dark hover:text-blue-gray lg:px-4 lg:pb-2 transition-border transition-500"
          :class="[
            convertToClass(item.label, 'hover:border-'),
            item.label == activeMenuFilter
              ? convertToClass(item.label, 'border-')
              : '',
          ]"
          :href="createURL(item.value)"
          @click.prevent="handleTypeClick(refine, item)"
        >
          {{ item.label }}
        </a>
      </li>
    </ul>
  </ais-menu>
</template>

<script>
export default {
  name: "ResourcePrimaryFilters",
  props: ["filters", "activeMenuFilter"],
  methods: {
    // Sending static values for Resource Group to Algolia widget
    getResourceGroup(items) {
      // Need to pull values dynamically
      const resourceGroup = this.filters.map((elem) => ({
        label: elem.value,
        value: elem.value,
        isRefined: false,
        highlighted: elem.highlighted,
      }));
      return resourceGroup;
    },
    convertToClass(string, type) {
      return (
        type +
        string
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
      );
    },
    handleTypeClick(refine, item) {
      this.$emit("handleTypeClick", refine, item);
    },
  },
};
</script>
