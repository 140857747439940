<template>
  <div>
    <ais-instant-search :search-client="algoliaClient" :index-name="indexName">
      <div class="mb-16 hidden md:block">
        <p class="text-xl text-center mb-8 font-bold text-blue-gray">
          Choose a Location
        </p>
        <ais-menu attribute="locations.title">
          <ul
            class="flex flex-row flex-wrap justify-center w-full mx-auto"
            slot-scope="{
              items,
              canToggleShowMore,
              isShowingMore,
              toggleShowMore,
              refine,
              createURL
            }"
          >
            <li class="mx-1 lg:mx-3 mb-6">
              <ais-clear-refinements>
                <div slot-scope="{ canRefine, refine, createURL }">
                  <button
                    class="text-sm tracking-wider uppercase text-blue-dark py-3 px-5 rounded-full transition-all transition-500 hover:text-blue-dark hover:shadow-button"
                    :class="canRefine ? 'bg-gray-lightest' : 'bg-white shadow-button'"
                    :href="createURL()"
                    @click.prevent="refine"
                  >
                    All
                  </button>
                </div>
              </ais-clear-refinements>
            </li>
            <li
              class="mx-1 lg:mx-3 mb-6"
              v-for="item in items"
              :key="item.value"
            >
              <button
                class="text-sm tracking-wider uppercase text-blue-dark py-3 px-5 rounded-full transition-all transition-500 hover:text-blue-dark hover:shadow-button"
                :class="item.isRefined ? 'bg-white shadow-button' : 'bg-gray-lightest'"
                :href="createURL(item.value)"
                @click.prevent="refine(item.value)"
              >
                {{ item.label }}
              </button>
            </li>
          </ul>
        </ais-menu>
      </div>

      <div class="mb-16 block md:hidden">
          <p class="text-xl text-center mb-3 font-bold text-blue-dark">Choose a Location</p>
          <ais-menu-dropdown 
              :aisMenuOptions="locations"
              menuAttribute="locations.title">
          </ais-menu-dropdown>
      </div>

      <ais-infinite-hits>
        <template slot-scope="{ items }">
          <ul class="flex flex-wrap md:-mx-6" v-if="items.length" >
            <li 
                class="w-full md:px-6 w-full"
                v-for="(entry, index) in items"
                :class="{ 
                  'pb-12': (index + 1) != items.length
                }"
                :key="entry.objectID"
            >
              <div
                class="flex flex-wrap flex-row w-full border-gray-light border-t pt-16"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <div class="md:px-8 lg:px-16 w-full md:w-1/2 mb-10 md:mb-0">
                  <div class="mb-8">
                    <h3>
                      <a class="text-blue-gray" :href="entry.url">{{
                        entry.title
                      }}</a>
                    </h3>
                    <span v-if="entry.locations">
                      <font-awesome-icon
                        class="text-blue-light text-base mr-1"
                        :icon="['fas', 'map-marker-alt']"
                      ></font-awesome-icon>
                      <div
                        class="text-gray-muted italic inline-block text-sm"
                        v-for="(val, index) in entry.locations"
                        :key="index"
                      >
                        <span v-if="index != 0" :key="'comma ' + index"
                          >,&nbsp;</span
                        >
                        <span :key="index">{{ val.title }}</span>
                      </div>
                    </span>
                  </div>
                  <div
                    v-if="entry.programLogos"
                    class="w-1/2 mx-auto"
                  >
                    <picture>
                      <source
                        :data-srcset="
                          entry.programLogos.webp
                        "
                        data-sizes="100vw"
                        type="image/webp"
                      />
                      <img
                        class="lazyload"
                        :src="entry.programLogos.src"
                        :data-srcset="entry.programLogos.srcset"
                        sizes="100vw"
                      />
                    </picture>
                  </div>
                </div>
                <div class="md:px-8 lg:px-16 w-full md:w-1/2">
                  <div
                    v-if="entry.certs"
                    class="w-full mb-8"
                  >
                    <p class="mb-3 text-sm text-blue-dark font-bold">
                      Certifications
                    </p>
                    <ul class="flex flex-col">
                      <template
                        v-if="entry.certs.length != 0"
                      >
                        <li
                          class="relative text-xs text-blue-dark leading-none mb-3"
                          v-for="(cert,
                          index) in entry.certs"
                          :key="index"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'check']"
                            class="text-orange-bright absolute left-0"
                          ></font-awesome-icon>
                          <span class="block ml-4">{{ cert.title }}</span>
                        </li>
                      </template>
                      <template v-else>
                        <li class="text-xs text-gray-muted italic">
                          This professional doesn't have any certifications
                          listed.
                        </li>
                      </template>
                    </ul>
                  </div>
                  <div
                    v-if="entry.description"
                    class="text-gray-muted mb-16"
                    v-html="entry.description"
                  ></div>
                  <ul class="flex flex-row flex-wrap items-end justify-between">
                    <li>
                      <a
                        @click="gtmTracking(entry.title)"
                        :href="entry.website.url"
                        class="button-primary"
                        :class="{ disabled: !entry.website.url }"
                      >
                        Website
                      </a>
                    </li>
                    <li v-if="entry.path">
                      <a :href="entry.path" class="link-arrow">Learn More</a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
          <div v-else>
            <div
              v-if="!initialRender"
              class="text-center text-blue-dark text-xl w-full md:w-3/5 mx-auto"
            >
              <p>
                We are experiences difficulties displaying these programs. Please
                <a :href="`mailto:${helpAddress.fromEmail}`">e-mail</a> us about
                this issue to get it resolved ASAP!
              </p>
            </div>
          </div>
        </template>
      </ais-infinite-hits>
    </ais-instant-search>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/reset.css";
import AisMenuDropdown from "./AisMenuDropdown";

export default {
  name: "ProgramToggle",
  props: ["indexName", "helpAddress"],
  data() {
    return {
      index: "",
      locations: [],
      algoliaClient: algoliasearch(
        "68G5M92M1B",
        "c24543a92b3e155b20a0b69f1ca4b554"
      )
    };
  },
  created() {
    // set index
    this.index = this.algoliaClient.initIndex(this.indexName);
    
    this.index.search({
        query: '',
          hitsPerPage: 1000,
    }).then(res => {
        this.caseStudyLength = res.hits;
    });

    // Get Location Facet
    this.index.searchForFacetValues({
        facetName: 'locations.title',
        facetQuery: ''
    }).then(facetHits=>{
        this.locations = facetHits.facetHits;
    });
  },
  methods: {
    gtmTracking(label) {
      this.$gtm.trackEvent({
        event: 'program-referral', // Event type [default = 'interaction'] (Optional)
        category: 'Program Referral',
        action: 'Programs',
        label: label,
        title: label,
        value: 1,
        noninteraction: false // Optional
      });
    },
  },
};
</script>