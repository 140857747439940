<template>
  <span>
    <modal
      :name="modalName"
      :adaptive="true"
      :scrollable="true"
      width="55%"
      height="auto"
      :classes="responsive ? 'v--modal mobile' : 'v--modal'"
      @opened="afterOpen"
      @before-open="beforeOpen"
      @before-close="beforeClose"
    >
      <div class="text-right">
        <span
          class="text-center cursor-pointer md:text-right"
          @click="closeHouseModal"
        >
          <font-awesome-icon
            class="m-4 text-4xl text-orange-bright"
            :icon="['fa', 'times']"
          ></font-awesome-icon>
        </span>
      </div>
      <div class="px-4 pb-12 md:px-12">
        <h2 class="text-4xl text-blue-dark">{{ title }}</h2>

        <div
          class="px-4 mb-10 border-l-2 border-orange-bright modal-body"
          v-html="text"
        />
        <ul v-if="links.length" class="flex content-center text-center">
          <li v-for="link in links" :key="link.id" class="flex items-center">
            <a class="mr-4" :class="`${link.style.value}`" :href="link.url">
              {{ link.text }}
            </a>
          </li>
        </ul>
      </div>
    </modal>
  </span>
</template>

<script>
export default {
  name: "SvgModal",
  props: ["targets"],
  data() {
    return {
      modalName: "homepage-house-modal",
      title: "",
      text: "",
      links: [],
      responsive: false,
    };
  },
  methods: {
    openHouseModal(data) {
      this.title = data.heading;
      this.text = data.body;
      this.$modal.show(this.modalName);
      this.links = data.links;
      // console.log(data.links);
    },
    closeHouseModal() {
      this.$modal.hide(this.modalName);
    },
    // handles blocking of scrolling when a modal is open
    beforeOpen(event) {
      document
        .getElementsByTagName("html")[0]
        .classList.add("v--modal-block-scroll");
    },
    beforeClose(event) {
      window.removeEventListener("resize", this.calculateWidth);
      document
        .getElementsByTagName("html")[0]
        .classList.remove("v--modal-block-scroll");
    },
    afterOpen(e) {
      this.calculateWidth();
      window.addEventListener("resize", this.calculateWidth);
    },
    calculateWidth(e) {
      this.responsive = window.innerWidth <= 1024;
    },
  },
  mounted() {
    // console.log(this.targets);
    const home = document.getElementById("home");
    const [_, __, width, height] = home.getAttribute("viewBox").split(" ");

    addTitle("home-illustration", home);

    this.targets.forEach((target) => {
      // const targetEl = createTarget(target, width, height, this.openHouseModal);
      const targetEl = createPlusIconSvg(
        target,
        width,
        height,
        this.openHouseModal
      );
      if (!targetEl) {
        return false;
      }
      // console.log("target!:", targetEl);
      home.appendChild(targetEl);
    });
  },
};

const addTitle = (title, el) => {
  const titleEl = document.createElement("title");
  titleEl.text = title;
  home.appendChild(titleEl);
};

/*
<g xmlns="http://www.w3.org/2000/svg" id="Layer_5" data-name="Layer 5">
  <ellipse class="cls-1" cx="12.66" cy="12.55" rx="11.4" ry="11.56"/>
  <path class="cls-2" d="M18.89,11.43H13.77V6.23a1.11,1.11,0,1,0-2.22,0v5.2H6.43a1.13,1.13,0,0,0,0,2.25h5.12v5.19a1.11,1.11,0,1,0,2.22,0V13.68h5.12a1.13,1.13,0,0,0,0-2.25Z"/>
</g>
*/
const createPlusIconSvg = (data, width, height, callback) => {
  let group = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  group.setAttribute("id", data.id);
  group.setAttribute("class", "target");
  group.setAttribute("pointer-events", "all");
  group.appendChild(grayCircleSvg());
  group.appendChild(plusSvg());
  group.setAttribute("x", (Number(data.x) / 100) * width);
  group.setAttribute("y", (Number(data.y) / 100) * width);

  group.addEventListener("click", (e) => {
    callback(data);
  });

  return group;
};

const groupSvg = (id, x, y, elems) => {
  // transform="translate(20,2.5)
  let group = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  group.setAttribute("id", id);
  group.setAttribute("class", "target");
  group.setAttribute("pointer-events", "all");
  // group.setAttribute("style", "fill: #434954");
  group.appendChild(backgroundCircle());

  elems.forEach((el) => group.appendChild(circle(el)));
  group.setAttribute("x", x);
  group.setAttribute("y", y);
  return group;
};

const plusSvg = () => {
  let path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute("class", "cls-2");
  path.setAttribute(
    "d",
    "M18.89,11.43H13.77V6.23a1.11,1.11,0,1,0-2.22,0v5.2H6.43a1.13,1.13,0,0,0,0,2.25h5.12v5.19a1.11,1.11,0,1,0,2.22,0V13.68h5.12a1.13,1.13,0,0,0,0-2.25Z"
  );

  return path;
};

const grayCircleSvg = (x, y) => {
  let circle = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "ellipse"
  );
  circle.setAttribute("class", "cls-1");
  circle.setAttribute("cx", "12.66");
  circle.setAttribute("cy", "12.55");
  circle.setAttribute("rx", "11.4");
  circle.setAttribute("ry", "11.56");

  return circle;
};

const createTarget = (data, width, height, callback) => {
  const paths = [
    "M18.89,11.43H13.77V6.23a1.11,1.11,0,1,0-2.22,0v5.2H6.43a1.13,1.13,0,0,0,0,2.25h5.12v5.19a1.11,1.11,0,1,0,2.22,0V13.68h5.12a1.13,1.13,0,0,0,0-2.25Z",
    // "M14.3,15.14a.9.9,0,1,1,.9-.9A.9.9,0,0,1,14.3,15.14Zm0-1a.1.1,0,0,0-.1.1c0,.12.21.12.21,0A.1.1,0,0,0,14.3,14.14Z",
    // "M14.3,17.42a3.18,3.18,0,0,1-3.15-2.81,3.18,3.18,0,0,1,2.79-3.52,3.17,3.17,0,1,1,.73,6.3h0Zm0-5.5H14a2.31,2.31,0,0,0-1.56.87,2.33,2.33,0,0,0,2.09,3.75h0a2.32,2.32,0,0,0-.26-4.63Z",
    // "M14.3,20.06a5.83,5.83,0,1,1,4.29-1.89,5.73,5.73,0,0,1-4,1.88H14.3Zm0-10.94h-.23a5.11,5.11,0,1,0,.44,10.22h0a5.12,5.12,0,0,0-.22-10.23Z",
    // "M14.3,22.71a8.47,8.47,0,0,1-.16-16.94h.17a8.48,8.48,0,0,1,8.46,8.31,8.47,8.47,0,0,1-8.3,8.63H14.3Zm0-16.39h-.16a7.92,7.92,0,1,0,.31,15.84h0a7.92,7.92,0,0,0-.15-15.84Z",
    // "M14.3,25.36a11.12,11.12,0,0,1-.08-22.24h.09a11.12,11.12,0,0,1,7.92,18.93,11,11,0,0,1-7.85,3.31Zm0-21.84h-.08A10.73,10.73,0,0,0,14.3,25h.08A10.72,10.72,0,0,0,21.83,6.6,10.65,10.65,0,0,0,14.31,3.52Z",
    // "M14.3,28A13.78,13.78,0,1,1,28.08,14.24,13.8,13.8,0,0,1,14.3,28ZM14.3.71A13.53,13.53,0,1,0,27.83,14.24,13.55,13.55,0,0,0,14.3.71Z",
  ];

  if (!(Number(data.x) >= 0) || !(Number(data.y) >= 0)) return false;
  let target = group(
    data.id,
    (Number(data.x) / 100) * width,
    (Number(data.y) / 100) * height,
    paths
  );
  target.addEventListener("mouseover", (e) => {
    const title = target.parentNode.querySelector("title");
    title.innerHTML = data.heading;
  });
  target.addEventListener("mouseout", (e) => {
    const title = target.parentNode.querySelector("title");
    title.innerHTML = "home-illustration";
  });
  target.addEventListener("click", (e) => {
    callback(data);
  });
  return target;
};

const svg = (id, x, y) => {
  let svgEl = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svgEl.setAttribute("id", `${id}-target`);
  svgEl.setAttribute("x", x);
  svgEl.setAttribute("y", y);
  return svgEl;
};

//<ellipse xmlns="http://www.w3.org/2000/svg" class="cls-1" cx="12.66" cy="12.55" rx="11.4" ry="11.56"/>
const group = (id, x, y, elems) => {
  // transform="translate(20,2.5)
  let group = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  group.setAttribute("id", id);
  group.setAttribute("class", "target");
  group.setAttribute("pointer-events", "all");
  // group.setAttribute("style", "fill: #434954");
  group.appendChild(backgroundCircle());

  elems.forEach((el) => group.appendChild(circle(el)));
  group.setAttribute("x", x);
  group.setAttribute("y", y);
  return group;
};

const backgroundCircle = () => {
  let circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
  circle.setAttribute("cx", "14.2");
  circle.setAttribute("cy", "14.2");
  circle.setAttribute("r", "14");
  circle.setAttribute("fill", "none");

  return circle;
};

const circle = (p) => {
  let path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute("style", "fill: #434954");
  path.setAttribute("d", p);
  return path;
};
</script>

<style>
.cls-1 {
  fill: #434954;
}
.cls-2 {
  fill: #fff;
}
/* .target:hover path {
  transform: scale(2);
  animation: pulse 1.5s infinite;
  transform-box: fill-box;
  transform-origin: center center;
  overflow: visible;
} */

.target:hover {
  /* overflow: visible; */
  cursor: pointer;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.svg .v--modal-box {
  border-radius: 4px !important;
}
.modal-body ol {
  list-style: decimal;
  margin-left: 2rem;
  margin-top: 1rem;
}
.modal-body li {
  padding-left: 10px;
}

@media screen and (max-width: 576px) {
  .mobile-svg {
    width: 95% !important;
    left: 2.5% !important;
  }
}
</style>
