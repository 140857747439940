<template>
  <div v-if="lastSegment != 'share'" class="container relative">
    <div
      class="collection-backdrop"
      :class="{
        'is-open': collectionIsOpen,
        'is-closed': !collectionIsOpen || computedCollectionSize == 0
      }"
    ></div>
    <div class="collection-container" v-on-clickaway="away">
      <div
        class="collection-drawer"
        :class="{
          'is-open': collectionIsOpen,
          'is-empty': computedCollectionSize == 0
        }"
      >
        <div class="collection-status" @click="toggleCollectionDrawer">
          <p class="text-white font-bold m-0">
            {{ collectionSize }} {{ labelTense }} Selected
          </p>
          <font-awesome-icon
            class="text-white self-center"
            :icon="drawerIcon"
          ></font-awesome-icon>
        </div>
        <form name="jsCollectionList" onsubmit="return false;">
          <div class="flex px-4 py-3 bg-gray-light">
            <p class="mb-0 mr-2 text-black">Your Selection</p>
            <button class="text-red-500 text-sm" @click="removeAllCollection()">
              Clear All
            </button>
          </div>
          <ul v-if="this.$root.collection.length">
            <li
              v-for="resource in this.$root.collection"
              :key="resource.id"
              class="collection-selected-resources px-4 py-3 border-b"
            >
              <div class="relative w-full">
                <div class="flex items-center justify-between">
                  {{ resource.title }}
                  <button
                    class="text-red-500 pl-2"
                    @click="removeFromCollection(resource)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="fill-current"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="flex p-4">
            <transition
              class="w-full"
              name="fade"
              key="state-transition"
              mode="out-in"
              tag="div"
            >
              <div
                v-if="progressBarValue == '0' && !notDownloadedCollection"
                class="w-full flex items-center"
                key="buttons"
              >
                <button
                  class="button-secondary mr-5"
                  @click="downloadCollection()"
                >
                  Download
                </button>
                <a
                  v-on:click="gtmShareTracking()" 
                  class="flex-grow" 
                  href="/resources/share">
                  <font-awesome-icon icon="share"></font-awesome-icon>
                  Share
                </a>
                <div>
                  <button
                    class="flex items-center justify-center text-orange-dark"
                    @click="triggerInstructionsModal"
                  >
                    <slot name="infoOutline"></slot>
                    <span class="ml-1">Instructions</span>
                  </button>
                </div>
              </div>
              <div
                v-else-if="progressBarValue == '100%'"
                key="progress-complete"
                class="text-center text-lg text-pilotMaterials font-bold"
              >
                <font-awesome-icon icon="check-circle"></font-awesome-icon>
                Download Complete
                <template v-if="partiallyDownloadedCollection">
                  <p
                    class="text-gray-muted text-xs mb-0 font-normal leading-tight"
                  >
                    There were some items in your collection that were not
                    downloaded because there were no assets attached to them.
                  </p>
                </template>
              </div>
              <div
                class="text-center"
                v-else-if="notDownloadedCollection"
                key="nothin-downloaded"
              >
                <p class="text-gray-muted text-sm leading-tight">
                  There are no assets available for download in your collection.
                  Please add a resource that has an attachment in order to
                  download.
                </p>
                <button
                  class="text-orange-dark"
                  @click="notDownloadedCollection = false"
                  href=""
                >
                  Go Back
                </button>
              </div>
              <div class="w-full" v-else key="in-progress">
                <progress-bar
                  class="w-full"
                  size="medium"
                  bar-color="#6DA8CF"
                  :text="progressBarValue"
                  :val="progressBarValue"
                ></progress-bar>
              </div>
            </transition>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import map from "lodash/map";
import ProgressBar from "vue-simple-progress";
import { mixin as clickaway } from "vue-clickaway";

let JSZip = require("jszip");
let JSZipUtils = require("jszip-utils");

export default {
  name: "Collection",
  props: ["collectionSize"],
  mixins: [clickaway],
  data() {
    return {
      allSelected: true,
      progressBarValue: "0",
      collectionIsOpen: false,
      partiallyDownloadedCollection: false,
      notDownloadedCollection: false
    };
  },
  components: {
    ProgressBar
  },
  computed: {
    drawerIcon() {
      return this.collectionIsOpen ? "minus" : "plus";
    },
    computedCollectionSize() {
      return this.$root.collection.length;
    },
    labelTense() {
      return this.$root.collection.length == 1 ? "Resource" : "Resources";
    },
    lastSegment() {
        return window.location.pathname.split("/").pop();
    }
  },
  watch: {
    collectionSize(newVal, oldVal) {
      if (newVal == 1 && oldVal == 0) {
        this.collectionIsOpen = true;
      }
    },
    progressBarValue() {
      if (this.progressBarValue == "100%") {
        setTimeout(() => {
          this.progressBarValue = "0";
        }, 5000);
      }
    },
    collectionIsOpen() {
      if (this.collectionIsOpen) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    }
  },
  methods: {
    gtmShareTracking() {
      console.log(this.$root);
      if (this.$root.collection.length) {
        const arr = this.$root.collection.map(item => {
          this.$gtm.trackEvent({
            event: 'share-collection', // Event type [default = 'interaction'] (Optional)
            category: 'Share',
            action: 'Shared Collection',
            label: item.title,
            title: item.title,
            value: 1,
            noninteraction: false // Optional
          });
        });
      }
    },
    toggleCollectionDrawer() {
      this.collectionIsOpen = !this.collectionIsOpen;
    },
    away(e) {
      if (e.target.classList.contains("collection-backdrop")) {
        this.closeDrawer();
      }
    },
    triggerInstructionsModal() {
      this.$root.$emit('trigger::instructions');
    },
    closeDrawer() {
      this.collectionIsOpen = false;
      document.body.classList.remove("overflow-hidden");
    },
    removeFromCollection(resource) {
      // Remove resource from collection
      this.$root.collection.splice(this.$root.collection.indexOf(resource), 1);
      localStorage.setItem("collection", JSON.stringify(this.$root.collection));
      if (this.computedCollectionSize == 0) {
        this.collectionIsOpen = false;
        this.$root.$emit("collection-cleared");
      }
    },
    removeAllCollection() {
      this.$root.collection = [];
      localStorage.setItem("collection", JSON.stringify(this.$root.collection));
      if (!this.$root.collection.length) {
        this.allSelected = true;
      }

      // Close the drawer and enable document scrolling
      this.closeDrawer();

      this.$root.$emit("collection-cleared");
    },
    // Update percentage of the progress bar
    updatePercent(percent) {
      this.progressBarValue = percent + "%";
    },
    downloadCollection() {
      var _this = this;
      var zip = new JSZip();
      var count = 0;
      var name = "collection.zip";
      var urls = [];

      // get the URL's that will need to be downloaded
      const unDownloadable = [];
      if (this.$root.collection.length) {
        this.$root.collection.map(item => {
          if (item.reference.type !== "asset") {
            unDownloadable.push(item);
          } else {
            urls.push(item.reference.url);
          }
          // Track Download
          this.$gtm.trackEvent({
            event: 'download-collection',
            category: 'Resource Action',
            action: 'Download Collection',
            label: item.title,
            title: item.title,
            value: 1,
            noninteraction: false // Optional
          });
        });
        urls.join(", ");
      }

      // Check if there were resources in the collection without assets and let the user know
      if (unDownloadable.length) {
        if (unDownloadable.length == this.$root.collection.length) {
          this.notDownloadedCollection = true;
          this.partiallyDownloadedCollection = false;
        } else {
          this.partiallyDownloadedCollection = true;
          this.notDownloadedCollection = false;
        }
      }

      var Promise = window.Promise;
      if (!Promise) {
        Promise = JSZip.external.Promise;
      }

      /**
       * Fetch the content and return the associated promise.
       * @param {String} url the url of the content to fetch.
       * @return {Promise} the promise containing the data.
       */
      function urlToPromise(url) {
        return new Promise(function(resolve, reject) {
          JSZipUtils.getBinaryContent(url, function(err, data) {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          });
        });
      }
      urls.forEach(function(url) {
        zip.file(url, urlToPromise(url), { binary: true });
        count++;
        if (count == urls.length) {
          // when everything has been downloaded, we can trigger the dl
          zip
            .generateAsync({ type: "blob" }, function updateCallback(metadata) {
              _this.updatePercent(metadata.percent.toFixed());
            })
            .then(
              function callback(blob) {
                // see FileSaver.js
                saveAs(blob, "collection.zip");
              },
              function(e) {
                //showError(e);
                console.log(e);
              }
            );
        }
        if (!JSZip.support.blob) {
          this.$noty.error(
            "This feature only works with modern browsers. Visit http://outdatedbrowser.com to update your browser."
          );
          return;
        }
      });
    }
  }
};
</script>

<style lang="scss">

// Fade in
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.collection-backdrop {
  background-color: rgba(33, 33, 33, 1);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: opacity 450ms;
  z-index: 89;
  opacity: 0;
  &.is-open {
    opacity: 0.48;
  }
  &.is-closed {
    z-index: -9999;
  }
}
.collection-container {
  @apply fixed bottom-0;
  right: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  z-index: 90;
  @screen md {
    left: auto;
    width: 500px;
    right: 60px;
  }
  .collection-drawer {
    @apply block bg-white shadow cursor-pointer;
    transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(-48px);
    &.is-open {
      transform: translateY(-100%);
    }
    &.is-empty {
      transform: translateY(0);
    }
    .collection-status {
      @apply bg-orange-dark px-4 flex justify-between items-center transition-500;
      height: 48px;
      &:hover {
        @apply bg-orange-bright;
      }
    }
  }
  .collection-selected-resources {
    @apply flex items-center relative cursor-pointer;
  }
}
.slide {
  transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(0);
}
</style>
