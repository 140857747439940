<template>
  <div>
    <a href="#header" class="backToTop w-10 h-10"></a>
    <!-- <button href="jumpNav" class="backToTop w-10 h-10"></button> -->
  </div>
</template>

<script>
export default {
  name: "BackToTop",
  props: ["data"],
};
</script>

<style scoped></style>
