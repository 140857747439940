<template>
    <a class="block" :href="'/' + event.url">
        <div class="flex flex-wrap p-4 md:p-8 shadow bg-white border-blue-light border-b-2">
            <div class="w-full sm:w-1/4 md:w-1/5 sm:px-6 border-gray-200 border-b-2 sm:border-b-0 sm:border-r-2 text-center self-center pb-2 mb-2 sm:pb-0 sm:mb-0">
                <p class="text-sm text-gray-600 text-6xl font-light text-blue-gray leading-none mb-2">{{ event.date | date }}</p>
                <p class="text-base font-medium tracking-widest uppercase text-blue-light leading-none">{{ event.date | month }}</p>
            </div>
            <div class="flex-grow sm:w-3/4 sm:px-6">
                <div class="flex content-between flex-col">
                    <div>
                        <p class="text-sm text-blue-light"><span v-if="event.startDate">{{ event.startDate.date | postDate }}</span><span v-if="event.endDate"> - {{ event.endDate.date | postDate }}</span></p>
                        <h3 class="text-xl text-blue-gray" attribute="title">{{ event.title }}</h3>
                    </div>
                    <div>
                        <p class="mb-0 text-gray-muted" v-if="event.eventAddress.city || event.eventAddress.state">
                            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'map-marker-alt' }" class="text-blue-light"></font-awesome-icon>&nbsp;
                            <span v-if="event.eventAddress.city.length">{{ event.eventAddress.city }},</span>&nbsp;&nbsp;<span v-if="event.eventAddress.state">{{ event.eventAddress.state }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </a>
</template>

<script>
    import moment from 'moment';
    export default {
        name: "EventCard",
        props: ['event'],
            data() {
                return {
                    
                }
            },
            filters: {
                postDate: function(data) {
                    return moment(data).format('MMMM DD, YYYY');
                },
                date: function(data) {
                    return moment(data).format('DD');
                },
                month: function(data) {
                    return moment(data).format('MMMM');
                }
            },
            methods: {

        }
    }
</script>