<template>
  <!-- Output the results -->
  <ais-infinite-hits :transform-items="transformItems">
    <ul v-if="items.length > 0" slot-scope="{ items, isLastPage, refineNext }">
      <li
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        class="w-full p-8 lg:p-10 mb-6 bg-white shadow-md flex flex-wrap border-b-4 border-blue-light"
        v-for="entry in items"
        :key="entry.objectID"
      >
        <div
          class="w-full md:w-3/5 border-b pb-6 mb-6 md:mb-0 md:pb-0 md:pr-6 md:border-b-0 md:border-r border-gray-100"
        >
          <div class="mb-2">
            <ul v-if="entry.professionalType">
              <li
                class="text-xs font-bold leading uppercase text-blue-light tracking-widest"
              >
                <template v-for="(profession, index) in entry.professionalType">
                  <span v-if="index != 0" :key="'comma ' + index">,&nbsp;</span>
                  <span :key="index">{{ profession.name }}</span>
                </template>
              </li>
            </ul>
            <h3
              class="text-xl font-bold text-blue-gray mb-3 lg:mb-4 leading-tight"
            >
              {{ entry.title }}
            </h3>
            <template v-if="!query && entry._rankingInfo.matchedGeoLocation">
              <p
                class="text-sm inline-block align-middle font-bold text-gray-700"
              >
                <font-awesome-icon
                  class="text-blue-light text-base"
                  :icon="['fas', 'map-marker-alt']"
                ></font-awesome-icon>
                <span>
                  {{
                    distanceFromUser(
                      entry._rankingInfo.matchedGeoLocation.distance
                    )
                  }}
                </span>
              </p>
            </template>
            <template v-if="entry.address">
              <span class="flex">
                <font-awesome-icon
                  v-if="!entry._rankingInfo.matchedGeoLocation"
                  class="text-blue-light"
                  :icon="['fas', 'map-marker-alt']"
                ></font-awesome-icon>
                <p
                  class="text-sm text-gray-muted italic leading-snug"
                  :class="
                    activeSort.label == 'Distance' && !query ? 'ml-4' : 'ml-1'
                  "
                  v-html="entry.address"
                ></p>
              </span>
            </template>
          </div>
          <div
            class="w-full flex border-b pb-6 mb-6 md:mb-0 md:pb-0 md:border-b-0 border-gray-100"
          >
            <div>
              <ul class="flex flex-col">
                <li
                  class="text-sm inline-block align-middle mb-2 whitespace-no-wrap"
                >
                  <a
                    :href="entry.email ? `mailto:${entry.email}` : null"
                    :class="{
                      'cursor-not-allowed text-gray-muted italic': !entry.email,
                      'text-orange-dark': entry.email,
                    }"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'envelope']"
                      class="text-blue-light"
                    ></font-awesome-icon
                    >&nbsp;
                    {{ entry.email ? "Email" : "Not Available" }}
                  </a>
                </li>
                <li
                  class="text-sm inline-block align-middle whitespace-no-wrap mb-3 lg:mb-6"
                >
                  <font-awesome-icon
                    :icon="['fas', 'phone-alt']"
                    class="text-blue-light"
                  ></font-awesome-icon
                  >&nbsp;
                  <a
                    v-if="entry.phone"
                    :href="`tel:${entry.phone}`"
                    v-html="entry.phone"
                    class="text-gray-muted"
                  ></a>
                  <p class="text-gray-muted italic inline" v-else>
                    Not Available
                  </p>
                </li>
                <li>
                  <a
                    @click="gtmTracking(entry.title)"
                    :href="entry.website"
                    target="_blank"
                    class="button-primary button-sm"
                    :class="{
                      disabled: !entry.website,
                    }"
                  >
                    Visit Website
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="w-full md:w-2/5 md:pl-6">
          <p class="mb-3 text-sm text-blue-gray font-bold">
            Certifications
          </p>
          <ul class="flex flex-col">
            <template v-if="entry.certifications.length != 0">
              <li
                class="relative text-xs text-gray-muted leading-none mb-3"
                v-for="(cert, index) in entry.certifications"
                :key="index"
              >
                <font-awesome-icon
                  :icon="['fas', 'check']"
                  class="text-orange-bright absolute left-0"
                ></font-awesome-icon>
                <span class="block ml-4">{{ cert.name }}</span>
              </li>
            </template>
            <template v-else>
              <li class="text-xs text-gray-muted italic">
                This professional doesn't have any certifications listed.
              </li>
            </template>
          </ul>
        </div>
      </li>

      <!-- Load more button -->
      <li
        class="text-center"
        v-if="activeSort.label != 'Distance' && !isLastPage"
      >
        <button @click="refineNext" class="button-primary">
          Show more
        </button>
      </li>
      <li
        class="text-center"
        v-if="
          activeSort.label == 'Distance' && activeHits.length < allItems.length
        "
      >
        <button @click="fakePaginate(items)" class="button-primary">
          Show more
        </button>
      </li>
    </ul>

    <!-- Response for 0 results -->
    <div v-else>
      <p class="mt-16 text-2xl text-gray-muted text-center">
        <template v-if="query">
          There are no professionals found for
          <q>{{ query ? query : userZip }}</q>
        </template>
        <template v-else>
          No professionals found. Please remove some filters for better results.
        </template>
      </p>
    </div>
  </ais-infinite-hits>
</template>

<script>
export default {
  props: {
    query: {
      type: String,
      default: "",
    },
    activeSort: {
      type: Object,
      default: function() {
        return {
          label: "Distance",
          icon: "map-marker-alt",
        };
      },
    },
  },
  data() {
    return {
      allItems: [],
      fakePaginateIndex: 10,
      activeHits: [],
    };
  },
  watch: {
    activeHits() {
      this.$root.$emit("updateActiveHits", this.activeHits);
    },
  },
  methods: {
    gtmTracking(label) {
      this.$gtm.trackEvent({
        event: 'professional-referral', // Event type [default = 'interaction'] (Optional)
        category: 'Professional Referral',
        action: 'Professionals',
        label: label,
        title: label,
        value: 1,
        noninteraction: false // Optional
      });
    },
    transformItems(items) {
      if (!this.query) {
        let sortedItems = items.sort(function(a, b) {
          const fieldA = a._rankingInfo.geoDistance;
          const fieldB = b._rankingInfo.geoDistance;
          if (fieldA > 0 && fieldB > 0) {
            let comparison = 0;
            if (fieldA > fieldB) {
              comparison = 1;
            } else if (fieldA < fieldB) {
              comparison = -1;
            }
            return comparison;
          }
        });
        this.allItems = sortedItems;
        let sortedItemsToReturn = sortedItems.slice(0, 10);
        this.activeHits = sortedItemsToReturn;
        return sortedItemsToReturn;
      } else {
        this.fakePaginateIndex = 10;
        return items;
      }
    },
    fakePaginate(items) {
      const aisHits = this.$children[0].state.hits;
      let itemsToReturn = this.allItems.slice(
        this.fakePaginateIndex,
        this.fakePaginateIndex + 10
      );
      this.fakePaginateIndex += 10;
      aisHits.push(...itemsToReturn);
      this.activeHits = aisHits;
      this.$emit("updateActiveHits", this.activeHits);
    },
    // Algolia return distance in meters, so lets convert it to miles.
    distanceFromUser(distance) {
      if (distance === 0) {
        return "Nearest you";
      } else {
        let converted = distance * 0.00062137;
        return converted.toFixed(2) + " miles from you";
      }
    },
  },
};
</script>
