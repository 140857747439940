<template>
  <div>
    <section class="shadow hover:shadow-2xl transition-250">
      <div
        class="flex justify-between card-topbar"
        :class="convertToClass(resource.resourceGroup, 'bg-')"
      >
        <img
          class="icon"
          :class="{ 'marketing-width': resource.resourceGroup == 'Marketing' }"
          :src="convertToIcon(resource.resourceGroup, '-card.svg')"
          alt
        />
        <p
          class="pl-4 mb-0 text-xs font-normal leading-tight tracking-widest text-white uppercase font-default"
        >
          {{ resource.resourceGroup }}
        </p>
      </div>
      <div
        class="flex flex-col content-end w-full h-0 p-4 bg-white shadow-md md:p-6 sm:p-4 resource-card"
      >
        <div class="flex flex-col flex-grow">
          <p class="text-sm text-blue-light font-default">
            {{ resource.postDate | postDate }}
          </p>
          <h3 class="mb-8 text-xl font-bold leading-snug" attribute="title">
            <a
              class="text-blue-gray hover:text-orange-bright"
              :href="resource.path"
              >{{ resource.title }}</a
            >
          </h3>
        </div>
        <ul class="flex flex-wrap justify-end">
          <li
            v-if="resource.reference"
            class="flex flex-wrap items-center justify-end w-full mb-2"
          >
            <a
              @click="
                gtmTracking('resource-downloaded', 'Download', resource.title)
              "
              v-if="resource.reference.type == 'asset'"
              v-bind:href="resource.reference.url"
              class="px-3 py-1 bg-gray-300 rounded-full text-gray-muted hover:text-orange-bright"
            >
              <font-awesome-icon
                class="self-center text-xs text-blue-300"
                icon="download"
              ></font-awesome-icon>
              <span class="ml-1 text-xs">Download</span>
            </a>
            <a
              @click="gtmTracking('resource-viewed', 'URL', resource.title)"
              v-else-if="resource.reference.url"
              v-bind:href="resource.reference.url"
              target="_blank"
              class="px-3 py-1 bg-gray-300 rounded-full text-gray-muted hover:text-orange-bright"
            >
              <font-awesome-icon
                class="self-center text-xs text-blue-300"
                icon="external-link-alt"
              ></font-awesome-icon>
              <span class="ml-1 text-xs">Open Resource</span>
            </a>
          </li>
          <li
            class="flex items-center justify-center px-3 py-1 bg-gray-300 rounded-full"
          >
            <button
              v-on:click="addToCollection(resource)"
              class="hover:text-orange-bright transition-500"
            >
              <font-awesome-icon
                class="self-center text-sm text-orange-300"
                icon="minus"
                v-if="
                  this.$root.collection.find(
                    ({ id }) => id === resource.objectID
                  )
                "
              ></font-awesome-icon>
              <font-awesome-icon
                class="self-center text-xs text-blue-300"
                icon="plus"
                v-else
              ></font-awesome-icon>
              <span class="ml-1 text-xs">
                <template
                  v-if="
                    this.$root.collection.find(
                      ({ id }) => id === resource.objectID
                    )
                  "
                  >Remove from Collection</template
                >
                <template v-else>Add to Collection</template>
              </span>
            </button>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "ResourceCard",
  props: ["resource"],
  data() {
    return {
      inCollection: false,
      collection: this.$root.collection,
    };
  },
  filters: {
    postDate: function (data) {
      return moment(data).format("DD MMMM, YYYY");
    },
  },
  methods: {
    gtmTracking: function (eventName, act, lab) {
      this.$gtm.trackEvent({
        event: eventName, // Event type [default = 'interaction'] (Optional)
        category: "Resource Action",
        action: act,
        label: lab,
        title: lab,
        value: 1,
        noninteraction: false, // Optional
      });
    },
    addToCollection: function (resource) {
      var found = false;
      // Check if collection exists
      if (this.$root.collection.length) {
        // if collection exists, loop through to see if resource already exists in collection
        for (var i = 0; i < this.$root.collection.length; i++) {
          if (this.$root.collection[i].id === resource.objectID) {
            found = true;
          }
        }
      }
      // if resource is not in the collection, add it
      if (!found) {
        const parsed = {
          id: resource.objectID,
          title: resource.title,
          reference: resource.reference,
          selected: true,
        };
        this.$root.collection.push(parsed);
        localStorage.setItem(
          "collection",
          JSON.stringify(this.$root.collection)
        );
      } else {
        // Remove resource from collections
        var location = this.$root.collection
          .map(function (x) {
            return x.id;
          })
          .indexOf(resource.objectID);
        this.$root.collection.splice(location, 1);
        localStorage.setItem(
          "collection",
          JSON.stringify(this.$root.collection)
        );
      }
      // Check for collection size
      this.$root.$emit("getCollectionSize");
      // GTM Tracking
      this.$gtm.trackEvent({
        event: "add-to-collection", // Event type [default = 'interaction'] (Optional)
        category: "Resource Action",
        action: "Add To Collection",
        label: resource.title,
        title: resource.title,
        value: 1,
        noninteraction: false, // Optional
      });
    },
    convertToClass(string, type) {
      return (
        type +
        string
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
      );
    },
    convertToIcon(string, type) {
      return (
        "/assets/static/" +
        string.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr) +
        type
      );
    },
  },
};
</script>
<style scoped lang="scss">
.resource-card {
  min-height: 300px;
}
.card-topbar {
  height: 48px;
  @apply px-4 flex items-center;
  .icon {
    @apply w-5 h-5;
    &.marketing-width {
      width: 1.8rem;
      // height: 1.8rem;
    }
  }
}
</style>
