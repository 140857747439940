<template>
  <div>
    <bbnw-resources
      v-if="featuredResources.length > 0"
      :resources="featuredResources"
    ></bbnw-resources>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "BbnwListing",
  data() {
    return {
      featuredResources: [],
    };
  },
  created() {
    // set index
    this.index = this.searchClient.initIndex("Resources");

    // Get Resource Group Facet
    this.index
      .searchForFacetValues({
        facetName: "resourceGroup",
        facetQuery: "",
        maxValuesPerFacet: 100,
      })
      .then((facetHits) => {
        this.resourceGroup = facetHits.facetHits;
      });

    // Get featured resources
    axios
      .get(this.instance.baseURL)
      .then((response) => {
        this.featuredResources = response.data.data[0];
        this.loadOtherResources();
      })
      .catch((error) => {
        console.log(error);
        this.loadOtherResources();
      });
  },
};
</script>

<style scoped></style>
