<template>
  <div id="dropdown" class="flex relative inline-block"
    :class="{'justify-center': !type}">
    <div class="flex items-center" v-if="type == 'breadcrumbs'">
      <a href="/">
        <font-awesome-icon
          class="text-blue-light text-base hover:text-orange-dark transition-color transition-500"
          :icon="['fas', 'home']"
        ></font-awesome-icon>
      </a>
      <span class="px-3 text-gray-muted">/</span>
    </div>
    <button
      @click="isOpen = true"
      class="inline-flex items-center py-2 rounded-lg focus:outline-none transition-all"
      :class="{
        'bg-white shadow-button hover:shadow-lg pl-6 pr-3 text-blue-dark font-semibold': !type,
        'text-gray-muted': type
      }"
    >
      <span
        :class="{
          'text-xs': type, 
          'text-sm uppercase font-semibold leading-loose tracking-wide': !type
        }"
        v-html="buttonLabel"
      >
      </span>
      <svg
        class=" h-6 w-6 text-blue-light"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"/>
      </svg>
    </button>

    <div
      v-show="isOpen"
      @click="isOpen = false"
      class="fixed inset-0"
      tabindex="-1"
    ></div>

    <transition
      enter-class="opacity-0 scale-90"
      enter-active-class="ease-out transition-fastest"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-active-class="ease-in transition-fastest"
      leave-to-class="opacity-0 scale-90"
    >
      <div v-show="isOpen" class="z-10 mt-2 dropdown origin-top-right" :style="dropdownPositioning">
        <div class="w-64 text-left bg-white rounded-lg shadow-lg">
          <ul class="bg-white py-1 sort-list uppercase text-sm font-semibold leading-loose tracking-wide"> 
            <template v-if="includeAllOption">
                <li
                    class="sort-link cursor-pointer border-b text-blue-dark hover:bg-gray-lightest"
                    :class="{ 'bg-gray-lightest': activeFilter == '' }"
                    @click="emitFilterEvent()"
                >
                    All
                </li>
            </template>
            <template v-if="options">
              <li
                class="sort-link cursor-pointer border-b text-blue-dark hover:bg-gray-lightest"
                v-for="option in options"
                :key="option.title"
                :class="{ 
                  'bg-gray-lightest': activeFilter == option.title,
                  'text-xs': type,
                }"
                @click="emitFilterEvent(option)"
              >
                {{ option.title }}
              </li>
            </template>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: ["includeAllOption", "options", "type"],
  data() {
    return {
      isOpen: false,
      activeFilter: ""
    };
  },
  filters: {
    truncate: function (value, limit) {
      if (value.length > limit) {
          value = value.substring(0, (limit - 3)) + '...';
      }
      return value
    }
  },
  computed: {
    buttonLabel: function() {
      if (this.type) {
        let lastItem = this.options.pop()
        return lastItem.title;
      } else {
        return this.includeAllOption && !this.activeFilter ? "All" : this.activeFilter; 
      }
    },
    dropdownPositioning: function() {
      return this.type == "breadcrumbs" ? 'top: 2rem' : 'top: 3rem';
    }
  },
  methods: {
    emitFilterEvent(option) {
      if (this.type && this.type == "breadcrumbs") {
        window.location = "/" + option.uri;
      } else if (option.uri) {
        window.location = "/" + option.uri;
      } else {
        this.$emit("filterEvent", option ? option.id : option);
        this.activeFilter = option ? option.title : "";
        this.isOpen = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sort-list {
  @apply p-0;
  .sort-link {
    @apply block px-6 py-3 leading-tight border-gray-200;
  }
}
.dropdown {
    position: absolute;
}
.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.transition-fastest {
  transition-duration: 75ms;
}

.transition-faster {
  transition-duration: 0.1s;
}

.transition-fast {
  transition-duration: 0.15s;
}

.transition-medium {
  transition-duration: 0.2s;
}

.transition-slow {
  transition-duration: 0.3s;
}

.transition-slower {
  transition-duration: 0.5s;
}

.transition-slowest {
  transition-duration: 0.7s;
}

.transition-all {
  transition-property: all;
}

.transition-opacity {
  transition-property: opacity;
}

.transition-transform {
  transition-property: transform;
}
.scale-90 {
  transform: scale(0.9);
}

.scale-100 {
  transform: scale(1);
}
</style>
